<template>
  <v-container fluid class="px-0 pb-0 white">
    <!-- Top Bar -->
    <v-container fluid class="py-0">
      <v-row justify="space-between" align="center">
        <v-col cols="auto" class="py-0">
          <v-row align="center">
            
            <v-col cols="auto">
              <v-btn depressed class="rounded-0"
                :to="`/materials/${$i18n.t('Cement').split(/\s+/).join('-')}/1`" text color="gray">{{
                    $t("Pricing")
                }}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn depressed class="rounded-0"
                :to="$store.state.isAuth == false ? '/login' : '/subscription-packages'" text color="gray">{{
                    $store.state.isAuth == false ? $t("Subscribe Now") : $t("subscriptions")
                }}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn depressed class="rounded-0" to="/society/contact-us" text color="gray">{{
                  $t("Contact us")
              }}</v-btn>
            </v-col>
            <v-col cols="auto" class="pa-0">
              <v-menu :close-on-content-click="true" :nudge-width="200" offset-y transition="slide-y-transition" bottom
                max-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small v-bind="attrs" v-on="on" class="rounded-pill mx-2" depressed>
                    {{ $t('recent searches') | capitalize }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-card elevation="0" relative style="overflow: hidden; z-index: 600;">
                  <v-list color="transparent" class="py-5" dense>
                    <v-list-item class="caption d-flex justify-space-between"
                      v-for="(search, i) in recent_search" :key="i" >
                      <div class="mx-1 cursor_pointer link" @click="showResult(search)">
                        <v-icon size="7" class="mx-1">mdi-circle</v-icon>
                        {{ search.search_text }}
                      </div>
                      <v-icon size="15" color="error" @click="removeSearch(search)"
                        class="d-block mi-start-auto">mdi-close</v-icon>
                    </v-list-item>
                    <v-list-item class="caption d-flex justify-space-between align-center cursor_pointer"
                      v-if="recent_search.length == 0">
                      <div class="ma-auto text-center">
                        {{ $t('no recent searches') | capitalize }}
                      </div>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
            
          </v-row>
        </v-col>
        <v-col cols="auto" class="py-0">
          <v-row align="center">
            <v-col cols="auto">
              <v-btn depressed class="rounded-0" @click="changeLanguage(otherLang)" text color="gray">{{ $t(otherLang) }}</v-btn>
            </v-col>
            <v-col cols="auto" v-if="$store.state.website_basic_data.facebook_link">
              <a :href="$store.state.website_basic_data.facebook_link" class="topBar-icons">
                <img src="../assets/svg/facebook.svg" alt="cement egypt" />
                <img src="../assets/svg/facebook-active.svg" alt="cement egypt" />
              </a>
            </v-col>
            <v-col cols="auto" v-if="$store.state.website_basic_data.twitter_link">
              <a :href="$store.state.website_basic_data.twitter_link" class="topBar-icons">
                <img src="../assets/svg/twitter.svg" alt="cement egypt" />
                <img src="../assets/svg/twitter-active.svg" alt="cement egypt" />
              </a>
            </v-col>
            <v-col cols="auto" v-if="$store.state.website_basic_data.youtube_link">
              <a :href="$store.state.website_basic_data.youtube_link" class="topBar-icons">
                <img src="../assets/svg/youtube.svg" alt="cement egypt" />
                <img src="../assets/svg/youtube-active.svg" alt="cement egypt" />
              </a>
            </v-col>
            <v-col cols="auto" v-if="$store.state.website_basic_data.linkedin_link">
              <a :href="$store.state.website_basic_data.linkedin_link" class="topBar-icons">
                <img src="../assets/svg/linkedin.svg" alt="cement egypt" />
                <img src="../assets/svg/linkedin-active.svg" alt="cement egypt" />
              </a>
            </v-col>
            <v-col cols="auto" class="pa-0" v-if="$store.state.isAuth == false">
              <v-btn depressed class="rounded-0" to="/login" text color="primary">{{
                  $t("Login in")
              }}</v-btn>
            </v-col>
            <v-col cols="auto" class="pa-0" v-if="$store.state.isAuth == false">
              <v-btn depressed class="rounded-0" to="/sign-up" color="primary">{{
                  $t("Sign Up")
              }}</v-btn>
            </v-col>
            <v-col cols="auto" class="pa-0" v-if="$store.state.isAuth == true">
              <p class="pa-0 ma-0">{{ $t("login as") }}</p>
            </v-col>
            <v-col cols="auto" class="pa-0" v-if="$store.state.isAuth == true">
              <v-menu :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition" bottom
                max-width="200" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small v-bind="attrs" v-on="on" class="rounded-pill mx-2" depressed>
                    {{ $store.state.userData.full_name }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-card elevation="0" style="overflow: hidden">
                  <v-list color="transparent" class="py-5">
                    <v-list-item>
                      <v-btn depressed block to="/settings">
                        <img src="../assets/svg/settings.svg" style="height: 25px" alt="cement egypt" />
                        {{ $t("Settings") }}
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn depressed color="error" text block @click="$api.LOGOUT(true)">
                        <v-icon>mdi-logout</v-icon>
                        {{ $t("Logout") }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="auto" class="pa-0"
              v-if="$store.state.isAuth == true && $store.state.userData.user_active !== 1">
              <v-btn depressed class="rounded-0" to="/activate-account" color="error">
                <v-icon left>mdi-alert</v-icon>
                {{
                    $t("activate your account")
                }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- <Middle Bar -->
    <v-container fluid class="mt-3 py-5">
      <v-row align="center" justify="space-between">
        <v-col cols="12" lg="4" sm="6">
          <v-row align="center" justify="space-between">
            <v-col cols="12">
              <ADImg :ad="adOne || Object" :maxHeight="150" :maxWidth="'auto'" :contain="true" :aspectRatio="1280 / 400"
                classes="rounded-lg" />
            </v-col>
            <v-col cols="12">
              <ADImg :ad="adTwo || Object" :maxHeight="150" :maxWidth="'auto'" :contain="true" :aspectRatio="1280 / 400"
                classes="rounded-lg" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" lg="4" sm="6" class="d-flex align-center">
          <img src="../assets/logo.png" @click="$router.push('/')" class="d-block ma-auto cursor_pointer"
            alt="cement egypt" height="150" />

          <v-menu :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition" bottom
            max-width="450" open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on" class="white mx-2 pa-0" depressed>
                <img src="../assets/svg/settings.svg" alt="cement egypt" />
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-card elevation="0" style="overflow: hidden">
              <v-list color="transparent" class="py-5">
                <!-- language -->
                <v-list-item class="d-block">
                  <v-col cols="12">
                    <p class="pa-0 ma-0 d-flex align-center">
                      <img src="../assets/svg/language.svg" alt="cement egypt" />
                      <span class="mx-1">{{ $t("language") }}</span>
                    </p>
                  </v-col>
                  <v-row>
                    <v-col cols="auto" v-for="(language, index) in languages" :key="index">
                      <v-btn :value="language" class="mx-1 rounded-pill" @click="changeLanguage(language)"
                        :color="$store.state.Settings.language == language ? 'primary' : ''" elevation="0">
                        <v-icon color="white" v-if="$store.state.Settings.language == language" left>mdi-check-circle
                        </v-icon>
                        {{ $t(language) }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- </div> -->
                </v-list-item>
                <!-- Country -->
                <v-list-item class="d-block">
                  <v-col cols="12">
                    <p class="pa-0 ma-0 d-flex align-center">
                      <img src="../assets/svg/country.svg" alt="cement egypt" />
                      <span class="mx-1">{{ $t("country") }}</span>
                    </p>
                  </v-col>
                  <v-row>
                    <v-col cols="auto" v-for="(country, index) in countries" :key="index">
                      <v-btn v-on:click="$store.state.country = country" :value="country"
                        :color="$store.state.country == country ? 'primary' : ''" class="mx-1 rounded-pill"
                        elevation="0">
                        <v-icon color="white" v-if="$store.state.country == country" left>mdi-check-circle</v-icon>
                        {{ $t(country) }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
        <v-col cols="12" lg="4" sm="6">
          <v-row align="center" justify="space-between">
            <v-col cols="12">
              <ADImg :ad="adThree || Object" :maxHeight="150" :maxWidth="'auto'" :contain="true"
                :aspectRatio="1280 / 400" classes="rounded-lg" />
            </v-col>
            <v-col cols="12">
              <ADImg :ad="adFour || Object" :maxHeight="150" :maxWidth="'auto'" :contain="true"
                :aspectRatio="1280 / 400" classes="rounded-lg" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- <Navigation Bar -->
    <NavigationBar />
  </v-container>
</template>

<script>
import ADImg from "./ADImg.vue";
import NavigationBar from "./NavigationBar.vue";
export default {
  name: "WebsiteHeader",
  components: {
    NavigationBar,
    ADImg
  },
  data: () => ({
    languages: ["en", "ar"],
    // adOne: null,
    // adTwo: null,
    // adThree: null,
    // adFour: null,
    // adFive: null,
    // adSix: null,
    countries: ["egypt", "saudi", "emirate", "kuwait", "qatar", "iraq", "oman"],
  }),
  watch: {
  
    // '$store.state.header_ads'() {
    //   if (this.$store.state.header_ads.length > 0) {
    //     this.adOne = this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-001')[0];
    //     this.adTwo = this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-002')[0];
    //     this.adThree = this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-003')[0];
    //     this.adFour = this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-004')[0];
    //     this.adFive = this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-005')[0];
    //     this.adSix = this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-006')[0];
    //   } 
    // }
  },
  computed: {
    otherLang(){
      if(localStorage.getItem('language') == 'ar'){
        return 'en';
      }else{
        return 'ar';
      }
    },
    adOne() {
      return this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-001')[0];
    },
    adTwo() {
      return this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-002')[0];
    },
    adThree() {
      return this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-003')[0];
    },
    adFour() {
      return this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-004')[0];
    },
    adFive() {
      return this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-005')[0];
    },
    adSix() {
      return this.$global.FilterArrayOfObject(this.$store.state.header_ads, 'ads_code', '01-006')[0];
    },
    recent_search() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.state.savedSearches.length > 0 ? this.$store.state.savedSearches.reverse() : []
    }
  },
  methods: {
    changeLanguage(language) {
      console.log(language);
      this.$store.dispatch("Settings/changeLanguage");
    },
    removeSearch(searchParam) {
      this.$store.dispatch("removeSearch", searchParam).then();
    },
    showResult(search) {
     this.$router.push(`/materials/${search.product_name.split(/\s+/).join('-')}/${search.product_id}?producer_id=${search.producer_id || ''}&product_type_id=${search.product_type_id || ''}&item_id=${search.item_id || ''}&gov_id=${search.gov_id || ''}&from_date=${search.from_date || ''}&to_date=${search.to_date || ''}`)
    },
  },
};
</script>

<style lang="scss" scoped>
.topBar-icons {
  img {
    height: 12px;
    transition: 0.3s;

    &:nth-of-type(2) {
      display: none;
    }
  }

  &:hover {
    img {
      height: 12px;

      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        display: block;
      }
    }
  }
}

.adv_img {
  min-height: 45px;
  max-height: 45px;
}
.v-image__image--cover{
  background-size: contain !important;
}
.link{
  transition: 0.3s;
  &:hover{
    color : #109cf1 !important;
  }
}
</style>
