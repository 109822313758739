<template>
  <v-container fluid>
    <Breadcrumb :data="breadcrumb" />
    <v-row class="mt-3" v-if="!isLoading">
      <!-- jobs List -->

      <v-col cols="12" lg="8" md="7" order="2" order-md="1">
        <v-col cols="12" class="pt-0" v-for="(job, index) in jobs" :key="index"
          :class="index == 0 ? 'mb-8 mt-3' : 'my-8'">
          <v-card class="shadow px-3">
            <v-row align="center" justify="space-between">
              <v-col cols="12" lg="4" md="12" sm="4">
                <v-avatar class="ma-auto d-block rounded-lg" size="180" tile>
                  <v-img :src="getImgUrl('defaultJob.svg')" :lazy-src="getImgUrl('defaultJob.svg')" :alt="job.title">
                  </v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" lg="8" md="12" sm="8">
                <v-row align="center" justify="space-between">
                  <v-col cols="auto">
                    <v-card-title class="text-h6 font-weight-bold">{{ job.title }}</v-card-title>
                  </v-col>
                  <v-col cols="auto" class="pa-5">
                    <div class="body-1 text-center font-weight-bold">
                      {{ new Date(job.attendance_date).toLocaleDateString("en-UK", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                      }}
                    </div>
                  </v-col>
                </v-row>

                <v-card-subtitle class="body-1 mt-1">{{ job.requirements }}</v-card-subtitle>

                <v-card-actions class="d-flex justify-end">
                  <v-btn class="mx-2 mt-3 primary--text px-8 shadow" color="#E6ECFF" @click="applyJob(job.id)">
                    {{ $store.state.isAuth ? $t("apply") : $t("login to apply") }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- Ads -->
        <v-col cols="12" sm="12">
          <ADImg :ad="fourthAd" :maxHeight="380" aspectRatio="auto" classes="mt-3 rounded-lg" />
        </v-col>
        <!-- Pagination -->
        <v-col cols="12" sm="12" v-if="!isLoading && jobs.length > 0">
          <Pagination :pagination="pagination" :limit="rowsNumber" :backValueMethod="changePage"
            :isLoading="isLoading" />
        </v-col>
      </v-col>
      <v-col cols="12" lg="4" md="5" order="1" order-md="2">
        <v-card class="pa-5 shadow" v-if="viewForm == false">
          <v-col cols="12" style="position: relative">
            <v-text-field :placeholder="$i18n.t('search')" v-model="filterByWord" clearable @keydown.enter="getData"
              @click:clear="() => { filterByWord = ''; getData() }" @click:append="getData" append-icon="mdi-magnify"
              hide-details outlined></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn block class="mt-3 primary--text px-8 shadow"
              @click="$store.state.isAuth ? viewForm = true : $router.push('/login')" color="#E6ECFF" large>
              {{ $store.state.isAuth ? $t("Add A Job") : $t("login to add job") }}
            </v-btn>
          </v-col>
        </v-card>
        <!-- form -->
        <v-card class="pa-5 py-10 shadow" v-if="viewForm == true">
          <v-form ref="form" v-model="valid" class="row">
            <v-col cols="12" class="py-0">
              <v-text-field :label="$i18n.t('Email To Received Request')" :rules="$store.state.Settings.emailRule"
                outlined v-model="form.email" type="email"></v-text-field>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field :label="$i18n.t('Position')" :rules="$store.state.Settings.requiredRule" outlined
                v-model="form.title" type="text"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field append-icon="mdi-calendar" v-model="form.attendance_date"
                    :placeholder="$i18n.t('attendance date')" readonly v-bind="attrs" v-on="on" outlined>
                  </v-text-field>
                </template>
                <v-date-picker :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                " v-model="form.attendance_date" @input="dateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea :label="$i18n.t('Description')" v-model="form.requirements"
                :rules="$store.state.Settings.requiredRule" outlined></v-textarea>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-btn block class="mt-3 primary--text px-8 shadow" color="#E6ECFF" :loading="isSaveLoading" large
                :disabled="!(valid)" @click="addJob">{{ $t("Add A Job") }}</v-btn>
            </v-col>
          </v-form>

        </v-card>
        <!-- ADS -->
        <v-card elevation="0" class="pa-5 mt-5 shadow">
          <!-- ADS  -->
          <v-row align="center">
            <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="firstAd" :maxHeight="135" aspectRatio="auto" classes="mt-3 rounded-lg" />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="secondAd" :maxHeight="135" aspectRatio="auto" classes="mt-3 rounded-lg" />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="thirdAd" :maxHeight="135" aspectRatio="auto" classes="mt-3 rounded-lg" />
            </v-col>
          </v-row>
          <!--  -->

        </v-card>
      </v-col>


      <!-- Dialog -->

      <v-dialog v-model="applyDialog" overlay-opacity="0.75" persistent :max-width="500">
        <v-card relative class="pa-7 py-10">
          <v-form v-model="applyFormValid" lazy-validation>

            <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
              color="grey lighten-1" text @click="applyDialog = false">
              <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-row align="center" class="mt-2" justify="center">

              <v-col cols="12">
                <div class="gray5--text font-weight-bold text-h6">
                  {{ $t('apply job') || capitalize }}
                </div>
              </v-col>
              <v-col cols="12" class="mt-5">
                <v-textarea persistent-placeholder name="comment" placeholder=" " :label="$i18n.t('comment')"
                  type="email" :rules="$store.state.Settings.requiredRule" outlined v-model="applyForm.comment">
                </v-textarea>
              </v-col>
              <v-col cols="12" class="mb-5">
                <v-file-input persistent-placeholder name="uploaded_file" placeholder=" "
                  :label="$i18n.t('upload file')" :rules="$store.state.Settings.requiredRule" outlined
                  v-model="applyForm.uploaded_file"></v-file-input>
              </v-col>
              <v-col cols="auto" class="d-flex justify-center">
                <v-btn class="mx-2" text depressed color="red4" @click="closeDialog" :height="37" :width="120">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn class="mx-2 white--text" :disabled="!(applyForm.uploaded_file && applyForm.comment)"
                  :loading="isApplyLoading" @click="applyJobRequest" depressed color="primary" :height="37"
                  :width="120">
                  {{ $t('ok') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>


      <!-- --------------------------------------------------------------------------------------------- -->
    </v-row>
    <v-row justify="center" v-if="isLoading">
      <v-col cols="auto" class="my-15">
        <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import ADImg from "@/components/ADImg.vue";
export default {
  name: "JobsList",
  components: {
    Breadcrumb,
    Pagination,
    ADImg
  },
  mounted() {
    this.$global.DELETE_META();
    this.$global.ADD_META(this.$i18n.t('Cement Egypt Jobs'), 'title');
    this.page = this.$route.params.page;
    this.getData();
  },

  data: () => ({
    breadcrumb: [],
    viewForm: false,
    valid: false,
    applyFormValid: false,
    isApplyLoading: false,
    applyDialog: false,
    dateMenu: false,
    isLoading: false,
    isSaveLoading: false,
    slider_data: [],
    firstAd: {},
    secondAd: {},
    thirdAd: {},
    fourthAd: {},
    page: 1,
    rowsNumber: 10,
    pagination: {},
    jobs: [],
    form: {
      title: null,
      title_en: null,
      title_ar: null,
      requirements: null,
      requirements_en: null,
      requirements_ar: null,
      attendance_date: null,
    },
    applyForm: {
      id: null,
      comment: null,
      uploaded_file: null,
    },
    filterByWord: null,
  }),
  watch: {
    // filterByWord(){
    //   this.getData();
    // },
    $route() {
      this.$global.DELETE_META();
      this.page = this.$route.params.page;
      this.$global.ADD_META(this.$i18n.t('Cement Egypt Jobs'), 'title');
      this.getData();
    },

  },
  computed: {},
  methods: {
    getData() {
      this.isLoading = true;
      this.breadcrumb = [
        {
          text: this.$i18n.t("cement egypt"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$route.params.meta_name.replaceAll('-', ' '),
          disabled: false,
          to: `/society/jobs/1/${this.$route.params.meta_name}`,
        },
      ];
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$api.GET_METHOD(`job?rows=${this.rowsNumber}&page=${this.page || 1}&word=${this.filterByWord || ''}`).then((response) => {
        this.isLoading = false
        if (response.check) {
          console.log(response);
          this.slider_data = response.data.all_sliders || [];
          this.firstAd = this.$global.FilterArrayOfObject(response.data.jobs_ads, 'ads_code', '06-001')[0];
          this.secondAd = this.$global.FilterArrayOfObject(response.data.jobs_ads, 'ads_code', '06-002')[0];
          this.thirdAd = this.$global.FilterArrayOfObject(response.data.jobs_ads, 'ads_code', '06-003')[0];
          this.fourthAd = this.$global.FilterArrayOfObject(response.data.jobs_ads, 'ads_code', '06-004')[0];

          this.jobs = response.data.items.data || [];
          this.page = response.data.items.current_page;
          this.pagination = {
            page: response.data.items.current_page,
            totalPages: response.data.items.last_page,
            per_page: response.data.items.per_page,
            totalRows: response.data.items.total,
          };


          this.jobs = response.data.items.data || [];

          response.data.items.data.forEach(deal => {
            this.$global.ADD_META(deal.title_en, 'keywords');
            this.$global.ADD_META(deal.title_ar, 'keywords');
            this.$global.ADD_META(deal.requirements_en, 'keywords');
            this.$global.ADD_META(deal.requirements_ar, 'keywords');
          });
          response.data.jobs_ads.forEach(ad => {
            this.$global.ADD_META(this.$store.state.endpointURL + ad.photo, 'image');
            this.$global.ADD_META(ad.title, 'keywords');
            this.$global.ADD_META(ad.link, 'url');
          });
        } else {
          this.jobs = [];
        }
      })
    },
    changePage(page) {
      this.$router.push(`/society/jobs/${page}/${this.$i18n.t('Cement Egypt Jobs').split(/\s+/).join('-')}`);
    },
    getImgUrl(img) {
      var images = require.context("../../assets/svg");
      console.log(images("./" + img));
      return images("./" + img);
    },
    applyJob(id) {
      if (this.$store.state.isAuth) {
        this.applyForm.id = id
        this.applyDialog = true
      } else {
        this.$router.push('/login')
      }
    },
    closeDialog() {
      this.applyDialog = false;
      this.applyForm = {
        id: null,
        comment: null,
        uploaded_file: null,
      }
    },
    applyJobRequest() {
      if (this.$store.state.isAuth) {

        this.isApplyLoading = true;
        this.$api.POST_METHOD(`apply_job`, this.applyForm).then((response) => {
          this.isApplyLoading = false;
          if (response.check) {
            this.applyDialog = false;
            this.applyForm = {
              id: null,
              comment: null,
              uploaded_file: null,
            }
            this.$global.ShowAlert(
              "success",
              this.$i18n.t("You are applied to this job successfully"),
              false,
              "",
              3000
            );
            // this.getData();
          }
        })

      } else {
        this.$router.push('/login')
      }
    },
    addJob() {
      this.isSaveLoading = true;
      this.form.title_en = this.form.title;
      this.form.title_ar = this.form.title;
      this.form.requirements_ar = this.form.requirements;
      this.form.requirements_en = this.form.requirements;
      this.$api.POST_METHOD(`job`, this.form).then((response) => {
        this.isSaveLoading = false;
        if (response.check) {
          this.$global.ShowAlert(
            "success",
            this.$i18n.t("Job added successfully"),
            false,
            "",
            3000
          );
          this.getData();
          this.viewForm = false;
          this.form = {
            title: null,
            title_en: null,
            title_ar: null,
            requirements: null,
            requirements_en: null,
            requirements_ar: null,
            attendance_date: null,
          };
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.job {
  border: 0.704431px solid #c4c4c4;
  border-radius: 12.6798px;
  // width: 92.98px;
  padding: 1rem;
  text-transform: capitalize;
  text-align: center;

  img {
    height: 61.99px;
    // transform: scale(1.4, 1.4);
  }
}

.input-search {
  background: #e8e8ea;
  border-radius: 25px;
  border: 0;
  width: 100%;
  height: 40px;
  padding: 0.2rem 0.8rem;
  color: #8e8c94;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: #8e8c94;
  }
}

.search-icon {
  position: absolute;
  right: 2rem;
  top: 1.65rem;
}
</style>
