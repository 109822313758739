<template>
   <v-card elevation="0" class="pa-5 rounded-lg  my-4 shadow" :class="{ 'isDialog': isDialog }">
      <div class="text-h6 font-weight-bold primary--text mb-5">{{ $t('material filter') | capitalize }}</div>
      <v-row justify="space-between">
         <v-col cols="12" md="6" class="d-flex align-center">
            <v-autocomplete dense :items="products" :no-data-text="$i18n.t('no selects') | capitalize"
               :placeholder="$i18n.t('product') | capitalize" v-model="filterByProduct" hide-details item-text="name"
               item-value="id" class="m-auto" outlined></v-autocomplete>
         </v-col>
         <v-col cols="12" md="6" class="d-flex align-center">
            <v-autocomplete dense :items="filtered_products_types" :no-data-text="$i18n.t('no selects') | capitalize"
               :placeholder="$i18n.t('product type') | capitalize" v-model="filterByProductType" hide-details
               item-text="name" item-value="id" class="m-auto" outlined>
            </v-autocomplete>
         </v-col>
         <v-col cols="12" class="px-10 opacity-05">
            <v-divider></v-divider>
         </v-col>

         <v-col cols="12" md="6" class="d-flex align-center">
            <v-autocomplete dense clearable :items="producers.length > 0 ? producers : all_producers"
               :loading="producerIsLoading" :no-data-text="$i18n.t('no selects') | capitalize"
               :placeholder="$i18n.t('Producer') | capitalize" v-model="filterByProducer" hide-details item-text="name"
               item-value="id" class="m-auto" outlined>
            </v-autocomplete>
            <!-- <v-divider vertical class="ma-0 d-sm-block d-none" style="height: 35px"></v-divider> -->
         </v-col>
         <v-col cols="12" md="6" class="d-flex align-center">
            <v-autocomplete dense clearable :items="filtered_items" :no-data-text="$i18n.t('no selects') | capitalize"
               :placeholder="$i18n.t('item') | capitalize" v-model="filterByItem" hide-details item-text="name"
               item-value="id" class="m-auto" outlined>
            </v-autocomplete>
         </v-col>
         <v-col cols="12" class="px-10 opacity-07">
            <v-divider></v-divider>
         </v-col>
         <v-col cols="12" md="6" class="d-flex align-center">
            <v-autocomplete dense clearable :items="filtered_governorates" v-model="filterByGovernorate"
               :no-data-text="$i18n.t('no selects') | capitalize" :placeholder="$i18n.t('governorate') | capitalize"
               hide-details item-text="name" item-value="id" class="m-auto" outlined></v-autocomplete>
         </v-col>
         <v-col cols="12" class="px-10 d-none d-md-block opacity-05">
            <v-divider></v-divider>
         </v-col>
         <v-col cols="12" md="6" class="d-flex align-center">
            <v-menu v-model="fromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
               offset-y min-width="auto">
               <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable v-model="from_date" :placeholder="$i18n.t('from')" readonly v-bind="attrs"
                     v-on="on" hide-details outlined dense></v-text-field>
               </template>
               <v-date-picker :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'" :max="to_date || new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
                  " v-model="from_date" @input="fromMenu = false"></v-date-picker>
            </v-menu>

         </v-col>
         <v-col cols="12" md="6" class="d-flex align-center">
            <v-menu v-model="toMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
               offset-y min-width="auto">
               <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable v-model="to_date" :placeholder="$i18n.t('to')" readonly v-bind="attrs" v-on="on"
                     hide-details outlined width="100" dense></v-text-field>
               </template>
               <v-date-picker :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'" :min="from_date" :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
                  " v-model="to_date" @input="toMenu = false"></v-date-picker>
            </v-menu>
         </v-col>
         <v-col cols="12" class="px-10 opacity-05">
            <v-divider></v-divider>
         </v-col>
         <v-col cols="12" class="d-flex justify-end align-center">
            <v-btn color="error" text class="mx-2" v-if="isDialog" depressed width="120" :loading="isLoading"
               @click="closeDialog">
               {{ $t('close') }}
            </v-btn>
            <!-- <v-btn color="success" class="mx-2" v-if="(prices.length > 0 && isFilter && checkFilter)" depressed
               width="120" :loading="isLoading" @click="saveSearch">
               {{ $t('save search') }}
            </v-btn> -->
            <v-btn color="primary" depressed width="120" :loading="isLoading" @click="filterBackMethod">
               {{ $t('search') }}
            </v-btn>
         </v-col>
      </v-row>

   </v-card>
</template>

<script>

export default {
   name: 'ProductFilter',
   props: {
      isLoading: { default: false },
      activeParam: { default: '' },
      prices: { default: [] },
      isFilter: { default: false },
      isDialog: { default: false },
      backValueMethod: { type: Function },
      closeDialog: { type: Function },
      all_producers: { default: [] },
      products: { default: [] },
      products_types: { default: [] },
      items: { default: [] },
      governorates: { default: [] },
   },
   data: () => ({
      fromMenu: false,
      producerIsLoading: false,
      toMenu: false,
      from_date: null,
      to_date: null,
      producers: [],
      filterByGovernorate: null,
      filterByItem: null,
      filterByProducer: null,
      filterByProductType: null,
      filterByProduct: null,
      filterByWord: null,
      turn_number: 1, // use it to remove duplicated route error
   }),
   watch: {
      $route() {
         this.filterByProduct = Number(this.$route.params.product_id);
      },
      filterByProduct() {
         var product_name = this.$global.FilterArrayOfObjectElement(this.products, 'id', this.filterByProduct, 'name');
         this.filterByProductType = 0;
         this.filterByProducer = null;
         this.filterByItem = null;
         this.filterByWord = null;
         this.$router.push(`/materials/${product_name.split(/\s+/).join("-")}/${this.filterByProduct}`);
      },
      filterByItem() {

      },
      filterByProducer() {
         this.filterByItem = null;
      },
      filterByProductType() {
         this.filterByItem = null;
         this.producerIsLoading = true;
         this.$api.POST_METHOD(`get_producer_by_product_type`, { product_type_id: this.filterByProductType || 0 }).then((response) => {
            this.producerIsLoading = false;
            if (response.check) {
               console.log(response);
               this.producers = response.data.producers || [];
            }
         })
      },
      'filtered_governorates.length'() {
         this.filterByGovernorate = this.filtered_governorates.length ? this.filtered_governorates.at(0) : null;
      }
   },
   computed: {
      filtered_governorates() {
         if (+this.filterByItem) {
            const itemGovs = this.$global.FilterArrayOfObjectElement(this.filtered_items, 'id', this.filterByItem, 'govs');
            const govIDs = itemGovs.split(',').map(Number);
            return this.governorates.filter(gov => govIDs.includes(gov.id));
         } else if (+this.filterByProducer && +this.filterByProductType) {
            const uniqueGovIDs = this.getUniqueGovIDs(this.filtered_items);
            return this.governorates.filter(gov => uniqueGovIDs.includes(gov.id));
         } else {
            return this.governorates;
         }
      },
      filtered_products_types() {
         if (Number(this.$route.params.product_id) != 0) {
            return this.products_types.filter((type) => type['product_id'] == Number(this.$route.params.product_id))
         } else {
            return []
         }
      },
      filtered_items() {
         if (Number(this.filterByProductType)) {
            return this.items.filter((item) =>
               item['product_type_id'] == Number(this.filterByProductType)
               && item["producer_id"] == Number(this.filterByProducer)
               && item["product_id"] == Number(this.$route.params.product_id)
            ) || []
         } else {
            return []
         }
      },
      checkFilter() {
         const param = `?product_id=${this.$route.params.product_id || ''}&producer_id=${this.filterByProducer || ''}&product_type_id=${this.filterByProductType || ''}&item_id=${this.filterByItem || ''}&gov_id=${this.filterByGovernorate || ''}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}&word=${this.filterByWord || ''}`
         return !this.$store.state.savedSearches.some((search) => search.param === param) && this.activeParam == param
      }
   },
   methods: {
      saveSearch() {
         let search_text = '';
         if (this.$route.params.product_id) {
            search_text = search_text + this.$global.FilterArrayOfObjectElement(this.products, 'id', +this.$route.params.product_id, 'name');
         }
         if (this.filterByProducer) {
            search_text = search_text + ' - ' + this.$global.FilterArrayOfObjectElement(this.producers, 'id', +this.filterByProducer, 'name');
         }
         if (this.filterByProductType) {
            search_text = search_text + ' - ' + this.$global.FilterArrayOfObjectElement(this.products_types, 'id', +this.filterByProductType, 'name');
         }
         if (this.filterByItem) {
            search_text = search_text + ' - ' + this.$global.FilterArrayOfObjectElement(this.items, 'id', +this.filterByItem, 'name');
         }
         if (this.gov_id) {
            search_text = search_text + ' - ' + this.$global.FilterArrayOfObjectElement(this.governorates, 'id', +this.gov_id, 'name');
         }
         if (this.from_date) {
            search_text = search_text + ' - ' + this.from_date || '';
         }
         if (this.to_date) {
            search_text = search_text + ' - ' + this.to_date || '';
         }
         const searchParam = {
            product_id: +this.$route.params.product_id,
            product_name: this.$route.params.meta_name,
            producer_id: this.filterByProducer,
            product_type_id: this.filterByProductType,
            item_id: this.filterByItem,
            gov_id: this.filterByGovernorate,
            from_date: this.from_date,
            to_date: this.to_date,
            word: this.filterByWord,
            search_text: search_text,
            param: `?product_id=${this.$route.params.product_id || ''}&producer_id=${this.filterByProducer || ''}&product_type_id=${this.filterByProductType || ''}&item_id=${this.filterByItem || ''}&gov_id=${this.filterByGovernorate || ''}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}&word=${this.filterByWord || ''}`
         }

         this.$store.dispatch("addSearch", searchParam).then();
         this.closeDialog()
      },
      filterBackMethod() {
         const filter_data = {
            product_id: +this.$route.params.product_id,
            product_name: this.$route.params.meta_name,
            producer_id: this.filterByProducer,
            product_type_id: this.filterByProductType,
            item_id: this.filterByItem,
            gov_id: this.filterByGovernorate,
            from_date: this.from_date,
            to_date: this.to_date,
            word: this.filterByWord,
            turn_number: this.turn_number++,
            param: `?product_id=${this.$route.params.product_id || ''}&producer_id=${this.filterByProducer || ''}&product_type_id=${this.filterByProductType || ''}&item_id=${this.filterByItem || ''}&gov_id=${this.filterByGovernorate || ''}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}&word=${this.filterByWord || ''}`
         }
         this.backValueMethod(filter_data)
      },
      getUniqueGovIDs(items) {
         const allGovsIDs = items.flatMap(item => item.govs.split(',').map(Number));
         return [...new Set(allGovsIDs)];
      }
   },
   mounted() {
      this.filterByProduct = Number(this.$route.params.product_id);
      this.from_date = this.$global.GetCurrentDate();
      this.to_date = this.$global.GetCurrentDate();
      this.filterByProduct = Number(this.$route.params.product_id);
   },

   components: {

   },
}
</script>

<style lang="scss" scoped>
.isDialog {
   position: absolute !important;
   bottom: -1rem;
   right: 0;
   left: 0;
}
</style>