import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";

export default {
  install(Vue) {
    Vue.prototype.$global = {
      ///////// Input Required Rules /////////
      requiredRule: [(v) => !!v || i18n.t("Field is required")],
      ///////// Input Email Required Rules /////////
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.$t("Please enter email address"),
      ],
      ///////// Input Mobile Required Rules /////////
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      ///////// Input Password Required Rules /////////
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],

      /////////  Array /////////
      FilterPermissions(Screen_Code) {
        if (store.state.permissions.length > 0) {
          var find = store.state.permissions.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.filter(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      applyFilter(event) {
        const filterValue = (event.target).value;
        return filterValue.trim().toLowerCase();
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(
        ARRAY,
        SearchIn,
        filterBy,
        ReturnElement = null
      ) {
        console.log('ARRAY', ARRAY);
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter.length > 0 ? filter[0][ReturnElement] : null;
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArrayOfObject(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      RemoveFromArray(ARRAY, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find == VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },
      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "SAR",
        });

        return formatter.format(NUMBER);
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      // Dates
      GetCurrentTime() {
        var time = new Date();
        return time.getHours() + ":" + time.getMinutes();
      },
      GetMonthName(DATE) {
        let date = DATE || this.GetFirstDayOfMonth();
        return i18n.t(new Date(`${date}`).toLocaleString('en-us', { month: 'long' }))
      },
      GetCurrentDate() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      },
      GetFirstDayOfMonth() {
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
        return firstDay.toISOString().split("T")[0];
      },
      SubtractDates(FIRST_DATE, SECOND_DATE) {
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
      },
      SubtractFromDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() - Number(VALUE));
        return date.toISOString().split("T")[0];
      },
      AddToDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() + Number(VALUE));
        return date.toISOString().split("T")[0];
      },
      AddMonthsToDate(DATE, VALUE = 1) {
        const date = new Date(DATE);
        var calculated = new Date(date.setMonth(date.getMonth() + VALUE));
        return calculated.toISOString().split("T")[0];
      },
      GetDaysBetween(FIRST_DATE, SECOND_DATE) {
        console.log(FIRST_DATE, SECOND_DATE);
        var getDaysArray = function (start, end) {
          for (
            var arr = [], dt = new Date(start);
            dt <= new Date(end);
            dt.setDate(dt.getDate() + 1)
          ) {
            arr.push(new Date(dt));
          }
          return arr;
        };
        var dayList = getDaysArray(new Date(FIRST_DATE), new Date(SECOND_DATE));
        var dates = dayList.map((v) => v.toISOString().slice(0, 10)).join(",");
        return dates.split(",").map((i) => i);
      },
      GetMonthsBetween(FIRST_DATE, SECOND_DATE) {
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var diff = (dateSecond.getTime() - dateFirst.getTime()) / 1000;
        diff /= 60 * 60 * 24 * 7 * 4;
        return Math.abs(Math.round(diff));
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt = Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );

        return textEncrypt;

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();
      },
      DECRYPT(KEY, ENCODED) {
        // 1
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(
          ENCODED
        ).toString(Vue.prototype.$CryptoJS.enc.Utf8);
        return textDecrypt;
        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(key);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var decrypted = Vue.prototype.$CryptoJS.AES.decrypt(encryptedString, x,{iv:iv});
        // return decrypted.toString();
      },
      GroupBy(ARRAY, KEY = 'category_name') {
        const groupedData = ARRAY.reduce((groups, Obj) => {
          if (!groups[Obj[KEY]]) {
            groups[Obj[KEY]] = [];
          }
          groups[Obj[KEY]].push(Obj);

          return groups;
        }, {});
        return groupedData
      },
      // Meta Tags
      ADD_META(CONTENT, TYPE = "description",) {
        var meta = document.createElement("meta");

        switch (TYPE) {
          case "keywords":
            meta.setAttribute("name", "keywords");
            meta.content = CONTENT;
            break;
          case "description":
            meta.setAttribute("name", "description");
            meta.content = CONTENT;
            break;
          case "image":
            meta.setAttribute("property", "og:image");
            meta.content = CONTENT;
            var metaWidth = document.createElement("meta");
            metaWidth.setAttribute("property", "og:image:width");
            metaWidth.content = 380;
            var metaHeight = document.createElement("meta");
            metaHeight.setAttribute("property", "og:image:height");
            metaHeight.content = 200;
            var metaType = document.createElement("meta");
            metaType.setAttribute("property", "og:image:type");
            metaType.content = 'jpg';
            break;
          case "title":
            meta.setAttribute("property", "og:title");
            meta.content = CONTENT;
            break;
          case "description_body":
            meta.setAttribute("property", "og:description");
            meta.content = CONTENT;
            break;
          case "url":
            meta.setAttribute("property", "og:url");
            meta.content = CONTENT;
            break;

          default:
            meta.setAttribute("name", "description");
            meta.content = CONTENT;
            break;
        }
        meta.setAttribute("dynamic_meta", true);
        document.getElementsByTagName("head")[0].appendChild(meta);
      },
      DELETE_META() {
        const metaTags = document.getElementsByTagName("meta");
        for (let i = 0; i < metaTags.length; i++) {
          if (metaTags[i].getAttribute("dynamic_meta")) {
            // console.log(metaTags[i]);
            if (Boolean(metaTags[i].getAttribute("dynamic_meta")) == true) {
              metaTags[i].remove();
            }
          }
        }
        // document.querySelector("meta[dynamic_meta='true']").remove()
      },

      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },
    };
  },
};
