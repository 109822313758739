<template>
   <v-card elevation="0" class="rounded-lg pa-4 mt-4">
      <div class="row align-center justify-space-between pa-4">
         <div class="text-h6 font-weight-medium">{{ $t(title) | capitalize }}</div>
         <div class="carousel-arrows  col-auto mi-start-auto">
            <v-row justify="space-around">
               <v-btn small fab class="mx-2 shadow" @click="model--">
                  <v-icon color="black">mdi-chevron-left</v-icon>
               </v-btn>
               <v-btn small fab class="mx-2 shadow" @click="model++">
                  <v-icon color="black">mdi-chevron-right</v-icon>
               </v-btn>
            </v-row>
         </div>
      </div>
      <!-- <v-row class="mt-3">
         <v-col cols="6" md="4" lg="3" xl="2" v-for="(country, index) in data" :key="index">
            <div class="country">
               <img :src="getImgUrl(country.image)" alt="cement egypt" />
            </div>
            <div class="subtitle-1 font-weight-medium text-center">{{ $t(country.country) }}</div>
         </v-col>
      </v-row> -->

      <v-carousel height="200" class="carousel mt-5" hide-delimiters :show-arrows="false" v-model="model" cycle>
         <template v-for="(country, index) in data">
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
               <v-row justify="center" align="center" style="height:100%">
                  <template v-for="(n, i) in columns">
                     <template v-if="(+index + i) < data.length">
                        <v-col cols="6" md="4" lg="3" xl="2" :key="i">
                           <div class="country">
                              <img :src="getImgUrl(data[+index + i].image)" alt="cement egypt" />
                           </div>
                           <div class="subtitle-1 font-weight-medium text-center">{{ $t(data[+index + i].country) }}</div>
                        </v-col>
                     </template>
                  </template>
               </v-row>
            </v-carousel-item>
         </template>
      </v-carousel>

   </v-card>
</template>

<script>

export default {
   name: 'MoveAroundServices',
   components: {

   },
   props: {
      // backValueMethod: { type: Function },
      isLoading: { default: false },
      data: { default: [] },
      title: { default: '' }
   },
   data: () => ({
      model: 0,
   }),
   computed: {
      columns() {
         if (this.$vuetify.breakpoint.xl) {
            return 4;
         }

         if (this.$vuetify.breakpoint.lg) {
            return 4;
         }

         if (this.$vuetify.breakpoint.md) {
            return 3;
         }

         return 1;
      }
   },
   methods: {
      getImgUrl(img) {
         var images = require.context('../assets/svg');
         return images('./' + img)
      }
   }
}
</script>

<style lang="scss" scoped>
.country {
   border: 0.704431px solid #c4c4c4;
  border-radius: 12.6798px;
   // width: 92.98px;
   padding: 1rem;
   text-transform: capitalize;
   text-align: center;
   img {
      width: 58.47px;
      height: 54.24px;
      transform:  scale(1.4, 1.4);
   }
}
</style>