<template>
  <v-container>
    <Breadcrumb :data="breadcrumb" />
    <section class="my-8">
      <v-row align-md="center" align-lg="end">
        <v-col cols="12" md="4" class="d-none d-md-block pa-0 relative">
          <div class="triangle"></div>
          <v-img :aspect-ratio="9 / 16" src="@/assets/png/cement-bg2.png" lazy-src="@/assets/png/cement-bg2.png"
            max-height="665"></v-img>
        </v-col>
        <v-col cols="12" md="8" class="pa-0">
          <v-card class="pa-6 shadow rounded-0">
            <h5 class="text-h5 font-weight-bold text--black my-2">
              {{ $t("Contact Us") }}
            </h5>
            <v-card class="pa-5 shadow">
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field :label="$i18n.t('name')"  :rules="$store.state.Settings.requiredRule" hide-details dense outlined v-model="form.name">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :label="$i18n.t('email')"  :rules="$store.state.Settings.emailRule" type="email" hide-details dense outlined
                      v-model="form.email"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-combobox :items="['+20', '+96', '+95']"  :rules="$store.state.Settings.requiredRule" :label="$i18n.t('country code')" v-model="form.code"
                      hide-details dense outlined></v-combobox>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field :label="$i18n.t('phone')"  :rules="$store.state.Settings.mobileRule" type="number" v-model="form.phoneWithoutCode" hide-details
                      dense outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea :label="$i18n.t('message')"  :rules="$store.state.Settings.requiredRule" hide-details dense outlined v-model="form.message">
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" lg="4" md="6">
                    <v-btn depressed block color="primary" :disabled="!valid" @click="send" :loading="isLoading" large>{{ $t("Send") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
            <v-col cols="12" lg="12" sm="6">
              <v-row class="align-center justify-center mt-3">
                <v-col cols="auto">
                  <div class="subtitle-1 font-weight-bold">{{ $t("CONTACT") }}</div>
                </v-col>
                <v-col cols="auto">
                  <div class="subtitle-2 font-weight-medium">
                    <v-icon class="mx-2" color="primary">mdi-phone</v-icon>
                          {{$store.state.website_basic_data.phone || '-'}}
                  </div>
                </v-col>
                <v-col cols="auto">
                  <div class="subtitle-2 font-weight-medium">
                    <v-icon class="mx-2" color="primary">mdi-whatsapp</v-icon>
                    {{$store.state.website_basic_data.whatsapp || '-'}}
                  </div>
                </v-col>
                <v-col cols="auto">
                  <div class="subtitle-2 font-weight-medium">
                    <v-icon class="mx-2" color="primary">mdi-email-outline</v-icon>
                     {{$store.state.website_basic_data.email || '-'}}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="align-center justify-end">
                <v-col cols="auto" class="pa-0">
                <a :href="$store.state.website_basic_data.facebook_link" v-if="$store.state.website_basic_data.facebook_link">
                  <img src="@/assets/svg/facebook.svg" style="height: 15px;" class="mx-2" alt="cement egypt" />
                </a>
                </v-col>
                <v-col cols="auto" class="pa-0">
                 <a :href="$store.state.website_basic_data.twitter_link" v-if="$store.state.website_basic_data.twitter_link">
                  <img src="@/assets/svg/twitter.svg" style="height: 15px;" class="mx-2" alt="cement egypt" />
                 </a>
                </v-col>
                <v-col cols="auto" class="pa-0">
                 <a :href="$store.state.website_basic_data.youtube_link" v-if="$store.state.website_basic_data.youtube_link">
                  <img src="@/assets/svg/youtube.svg" style="height: 15px;" class="mx-2" alt="cement egypt" />
                 </a>
                </v-col>
                <v-col cols="auto" class="pa-0">
                 <a :href="$store.state.website_basic_data.linkedin_link" v-if="$store.state.website_basic_data.linkedin_link">
                  <img src="@/assets/svg/linkedin.svg" style="height: 15px;" class="mx-2" alt="cement egypt" />
                 </a>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "ContactUs",
  components: {
    Breadcrumb,
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getData();
  },

  data: () => ({
    breadcrumb: [],
    valid: false,
    isLoading: false,
    form: {
      name: null,
      email: null,
      code: null,
      phoneWithoutCode: null,
      phone: null,
      message: null,
    }
  }),
  computed: {},
  watch: {
    'form.phoneWithoutCode'() {
      this.form.phone = String(this.form.code) + String(this.form.phoneWithoutCode)
    }
  },
  methods: {
    getData() {
      this.breadcrumb = [
        {
          text: this.$i18n.t("cement egypt"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$i18n.t("cement egypt society"),
          disabled: false,
          to: "/society",
        },
        {
          text: this.$i18n.t("Contact Us"),
          disabled: false,
          to: "/society/contact-us",
        },
      ];
    },
    send() {
      this.isLoading = true;
      delete this.form.phoneWithoutCode;
      this.$api.POST_METHOD(`contact_us`, this.form).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.$global.ShowAlert(
            "success",
            this.$i18n.t("thank you for contact us"),
            false,
            "",
            3000
          );
          this.form = {
            name: null,
            email: null,
            code: null,
            phoneWithoutCode: null,
            phone: null,
            message: null,
          };
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.triangle {
  width: 70.1%;
  height: 100%;
  position: absolute;
  top: 0;
  right: -70%;
  background: #1e1e20;
  -webkit-clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
  clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
}

.v-application--is-rtl {
  .triangle {
    left: -70%;
    right: unset;
    -webkit-clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
  }
}
</style>
