<template>
   <v-breadcrumbs :items="data" class="pa-0">
      <template v-slot:divider>
         <v-icon v-if="$vuetify.rtl == false">mdi-chevron-right</v-icon>
         <v-icon v-if="$vuetify.rtl == true">mdi-chevron-left</v-icon>
      </template>
   </v-breadcrumbs>
</template>

<script>

export default {
   name: 'Breadcrumb',
   components: {

   },
   props: {
      data: { default: [] },
   },
   data: () => ({

   }),
   computed: {

   },
   methods: {

   }
}
</script>

<style lang="scss" scoped>
</style>