<template>
  <v-container fluid class="">
    <v-row align="stretch">
      <v-col cols="12" md="6" sm="5" order="2" order-sm="1" class="pa-0 d-none d-sm-block">
        <v-img src="@/assets/svg/cementBg.svg" lazy-src="@/assets/svg/cementBg.svg" height="102vh"
          class="d-flex align-center justify-center">
          <img src="@/assets/svg/white_logo.svg" class="d-block ma-auto" alt="cement egypt" style="max-height: 80px" />
        </v-img>
      </v-col>
      <v-col cols="12" md="6" sm="7" order="1" order-sm="2" class="white d-flex align-center" style="height: 102vh">
        <v-card elevation="0" class="pa-5 white" style="width: 100%">
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- <img src="@/assets/logo.png" class="d-block d-sm-none ma-auto" alt="cement egypt"
              style="max-height: 60px" /> -->
            <h1 class="font-weight-black my-8">
              {{ $t("Login") }}
            </h1>
            <v-row justify="center">
              <v-col cols="11" lg="8" md="10" class="pa-0">
                <v-text-field persistent-placeholder name="username" placeholder=" " :label="$i18n.t('username')" type="text"
                  :rules="$store.state.Settings.usernameRule" outlined v-model="form.username" @keydown.enter="login()"></v-text-field>
              </v-col>
              <v-col cols="11" lg="8" md="10" class="pa-0">
                <v-text-field persistent-placeholder placeholder=" " name="password" :label="$i18n.t('password')"
                  outlined required :rules="$store.state.Settings.passwordRule" v-model="form.password" @keydown.enter="login()"
                  :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
                  @click:append="show = !show"></v-text-field>
              </v-col>      
              <v-col cols="11" lg="8" md="10">
                <v-btn outlined block color="primary" x-large :loading="loading" :disabled="!valid" @click="login">
                  {{ $t("Login") }}
                </v-btn>
              </v-col>
              <v-col cols="11" class="ma-0 d-flex pa-0">
                <v-btn text :disabled="loading" class="d-block ma-auto text-decoration-underline"
                  @click="$router.push('/sign-up')" color="primary">
                  {{ $t("Create an account") }}
                </v-btn>
                <v-btn text :disabled="loading" class="d-block ma-auto text-decoration-underline"
                  @click="$router.push('/forget-password')" color="primary">
                  {{ $t("Forget Password?") }}
                </v-btn>
              </v-col>
              <v-col cols="11" class="relative">
                <v-divider class="ma-5"></v-divider>
                <p class="or white">
                  {{ $t("or") }}
                </p>
              </v-col>
              <v-snackbar multi-line v-model="snackbar" :timeout="2000">
                <div class="body-1" v-for="(error, index) in errors" :key="index">{{ error }}</div>
                <template v-slot:action="{ attrs }">
                  <v-btn color="error" text v-bind="attrs" @click="snackbar = false">
                    {{ $t('hide') }}
                  </v-btn>
                </template>
              </v-snackbar>
              <v-col cols="11">
                <v-btn text class="google rounded-pill d-block ma-auto" x-large @click="login">
                  <img src="@/assets/svg/Google.svg" class="mx-2" alt="cement egypt" />
                  {{ $t("Login by Google") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoginPage",
  components: {},
  data: () => ({
    valid: false,
    show: false,
    loading: false,
    snackbar: false,
    errors: [],
    form: {
      username: null,
      password: null,
    },
  }),
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.$refs.form.validate();
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version);
  },
  methods: {
    login() {
      this.errors = [];
      this.snackbar = false;
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true) {

        this.$api.POST_METHOD('login', this.form, false).then((response) => {
          this.loading = false;
          if (response.check == true) {
            this.$api.SET_AUTH(response.data)
            this.$router.push("/");
          } else {
            if (response.data) {
              Object.keys(response.data).forEach(errorKey => {
                this.errors.push(response.data[errorKey][0])
              });
              this.snackbar = true;
            } else {
              this.errors.push(response.msg || 'error')
              this.snackbar = true
            }
          }
        })
      }
      else {
        this.loading = false;
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.or {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 1.2rem;
  width: 40px;
  text-align: center;
  font-weight: bold;
}

.google {
  border: 1px solid #d2d1d4;
  text-transform: initial;

  img {
    height: 30px;
  }
}
</style>
