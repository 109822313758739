<template>
  <v-card class="shadow rounded-lg recipeChart my-5 py-5 overflow-hidden" :key="chartKey">
    <v-row justify="center">
      <v-col cols="12" class="py-0 d-flex justify-center" id="chart">

        <apexchart type="area" height="320" :width="width" :options="chartOptions" :series="series"></apexchart>
      </v-col>
      <v-col cols="12"> 
        <v-row justify="center">
          <v-col cols="auto" v-for="(item, index) in comparisonItems.items" :key="index">
            <v-row>
              <v-col cols="auto">
                <div class="d-block pa-2 rounded-pill" :style="'background :' + chartOptions.colors[index]"></div>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">{{ item.item_name }}</h5>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "AnalysisChart",
  computed: {},
  props: {
    items: { default: [] },
    dates: { default: [] },
    comparisonItems: {
      default: {
        from_date: null,
        to_date: null,
        items: []
      }
    },
  },
  mounted() {
    this.onResize();
    console.log(this.dates);
    this.series = this.items;
    this.chartOptions.xaxis.categories = this.dates;
    this.width = document.getElementById("chart").offsetWidth - 75;
  },

  watch: {
    items() {
      this.chartOptions.xaxis.categories = this.dates;
      this.series = this.items;
      this.chartKey = this.chartKey + 1
    },
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
  data: () => ({
    windowSize: {},
    width: 0,
    chartKey: 1,
    series: [],
    chartOptions: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#109cf1", "#f7685b", "#2ed47a", "#ffb946", "#192a3e", "#0030CC", "#885af8"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: " ",
        align: "left",
      },
      grid: {
        borderColor: "#f0f4f8",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.1,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [],
        title: {
          text: " ",
        },
      },
      yaxis: {
        title: {
          text: " ",
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  }),
};
</script>
