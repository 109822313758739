<template>
  <v-app>
    <SplashLoader v-if="$store.state.splashScreen == true" />
    <router-view />
  </v-app>
</template>

<script>
import SplashLoader from './components/SplashLoader.vue';
export default {
  name: "App",

  data: () => ({
    isLoading: false,
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  mounted() {
     document.title = this.$i18n.t("Cement Egypt");
    this.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
    // this.$store.dispatch("checkRoute");
    document.addEventListener('swUpdated', this.swUpdated, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
    document.addEventListener('swReady', this.swReady, { once: true });
  },
  watch: {
    $route() {
      // this.$store.dispatch("checkRoute");
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth",
      // });
    }
  },
  components: { SplashLoader },
  created() {
    document.addEventListener('swUpdateFound', this.swUpdateFound, { once: true });
  },
  methods: {
    swUpdated(event) {
      console.log('event', event);
      this.registration = event.detail
      this.refreshApp()
    },
    swUpdateFound(event) {
      console.log('swUpdateFound', event);
    },
    swOffline(event) {
      console.log('swOffline', event);
      this.$store.state.networkConnection = false;
      this.$global.ShowAlert('info', this.$i18n.t('No Internet Connection'));
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      // this.$router.push('/')
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    swReady() {
      // if (this.$route.fullPath == '/system_update') {
      //   this.$router.push('/')
      // }
    }
  },
};
</script>

<style lang="scss">
@import "./scss/core.scss";
@import "./scss/rtl.scss";
@import "./scss/variables.scss";
</style>
