<template>
   <v-card elevation="0" class="rounded-lg pa-4 mt-4">
      <div class="subtitle-1 font-weight-medium">{{ $t(title) }}</div>
      <div class="subtitle-2 font-weight-medium ">
         {{
            $t('price that appeared is the change of the price between today and day back')
         }}
      </div>
      <v-row class="mt-3">
         <v-col cols="6" md="4" lg="3" xl="2" v-for="(statistic, index) in data" :key="index">
            <div class="statistic">
               <div class="subtitle-1 font-weight-medium green--text"  v-if="statistic.status == 'up'">{{ statistic.gov }}</div>
               <div class="subtitle-1 font-weight-medium black--text"  v-if="statistic.status == 'normal'">{{ statistic.gov }}</div>
               <div class="subtitle-1 font-weight-medium red--text"  v-if="statistic.status == 'down'">{{ statistic.gov }}</div>
               <div
                  class="d-flex align-center justify-center green--text"
                  v-if="statistic.status == 'up'"
               >
                  <!-- <img src="../assets/svg/up.svg" class="mx-2" alt="cement egypt" /> -->
                  <v-icon size="28" color="green">mdi-menu-up</v-icon>
                  <span>{{ statistic.percentage }}</span>
               </div>
               <div
                  class="d-flex align-center justify-center black--text"
                  v-if="statistic.status == 'normal'"
               >
                  <!-- <img src="../assets/svg/normal.svg" class="mx-2" alt="cement egypt" /> -->
                  <v-icon size="28" color="black">mdi-minus</v-icon>
                  <span>{{ statistic.percentage }}</span>
               </div>
               <div
                  class="d-flex align-center justify-center red--text"
                  v-if="statistic.status == 'down'"
               >
                  <!-- <img src="../assets/svg/down.svg" class="mx-2" alt="cement egypt" /> -->
                  <v-icon size="28" color="red">mdi-menu-down</v-icon>
                  <span>{{ statistic.percentage }}</span>
               </div>
            </div>
         </v-col>
      </v-row>
   </v-card>
</template>

<script>

export default {
   name: 'Statistics',
   components: {

   },
   props: {
      // backValueMethod: { type: Function },
      isLoading: { default: false },
      data: { default: [] },
      title: { default: '' }
   },
   data: () => ({

   }),
   computed: {

   },
   methods: {

   }
}
</script>

<style lang="scss" scoped>
.statistic {
   border: 0.704431px solid #c4c4c4;
   border-radius: 12.6798px;
   // width: 92.98px;
   padding: 1rem;
   text-transform: capitalize;
   text-align: center;
}
</style>