<template>
  <div>
    <v-app-bar color="white" class="shadow" height="90">
      <v-container>
        <v-row align="center" justify="space-between">
          <v-col cols="3">
            <v-btn icon depressed @click="$store.state.sidebarIsOpen = true">
              <img v-if="$vuetify.rtl == false" src="../assets/svg/menu-ltr.svg" alt="cement egypt" />
              <img v-if="$vuetify.rtl == true" src="../assets/svg/menu-rtl.svg" alt="cement egypt" />
            </v-btn>
          </v-col>
          <v-col cols="6"><img src="../assets/logo.png" @click="$router.push('/')"
              class="d-block ma-auto cursor_pointer" style="max-height: 45px" alt="cement egypt" /></v-col>
          <v-col cols="3">
            <v-menu :close-on-content-click="false" :nudge-width="200" offset-y transition="slide-y-transition" bottom
              max-width="450">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="white d-block ma-auto pa-0" depressed>
                  <img src="../assets/svg/settings-active.svg" alt="cement egypt" />
                </v-btn>
              </template>

              <v-card elevation="0" style="overflow: hidden">
                <v-list color="transparent" class="py-5">
                  <!-- language -->
                  <v-list-item class="d-block">
                    <v-col cols="12">
                      <p class="pa-0 ma-0 d-flex align-center">
                        <img src="../assets/svg/language.svg" alt="cement egypt" />
                        <span class="mx-1">{{ $t("language") }}</span>
                      </p>
                    </v-col>
                    <v-row>
                      <v-col cols="auto" v-for="(language, index) in languages" :key="index">
                        <v-btn :value="language" class="mx-1 rounded-lg" @click="changeLanguage(language)"
                          :color="$store.state.Settings.language == language ? 'primary' : ''" elevation="0">
                          <v-icon color="white" v-if="$store.state.Settings.language == language" left>mdi-check-circle
                          </v-icon>
                          {{ $t(language) }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <!-- Country -->
                  <v-list-item class="d-block">
                    <v-col cols="12">
                      <p class="pa-0 ma-0 d-flex align-center">
                        <img src="../assets/svg/country.svg" alt="cement egypt" />
                        <span class="mx-1">{{ $t("country") }}</span>
                      </p>
                    </v-col>
                    <v-row>
                      <v-col cols="auto" v-for="(country, index) in countries" :key="index">
                        <v-btn v-on:click="$store.state.country = country" :value="country"
                          :color="$store.state.country == country ? 'primary' : ''" class="mx-1 rounded-lg"
                          elevation="0">
                          <v-icon color="white" v-if="$store.state.country == country" left>mdi-check-circle</v-icon>
                          {{ $t(country) }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <!-- <v-list-item class="d-flex align-center justify-end">
                    <v-btn large class="mx-2 rounded-lg" text color="error" depressed>{{$t('close')}}</v-btn>
                    <v-btn large class="mx-2 rounded-lg" depressed color="primary">{{$t('ok')}}</v-btn>
                  </v-list-item> -->
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-col cols="12">
      <v-row align="center" justify="space-between">
        <v-col cols="6" md="3">
          <ADImg :ad="adOne || Object" :maxHeight="72"  :contain="false" :aspectRatio="1280/400" classes="rounded-lg" />
        </v-col>
        <v-col cols="6" md="3">
          <ADImg :ad="adTwo || Object" :maxHeight="72"  :contain="false" :aspectRatio="1280/400" classes="rounded-lg" />
        </v-col>
        <v-col cols="6" md="3">
          <ADImg :ad="adThree || Object" :maxHeight="72"  :contain="false" :aspectRatio="1280/400" classes="rounded-lg" />
        </v-col>
        <v-col cols="6" md="3">
          <ADImg :ad="adFour || Object" :maxHeight="72"  :contain="false" :aspectRatio="1280/400" classes="rounded-lg" />
        </v-col>
        <!-- <v-col cols="6" sm="4">
          <v-img v-if="adFive" :lazy-src="$store.state.endpointURL + ($vuetify.rtl ? adFive.photo_ar : adFive.photo_en)"
            contain :src="$store.state.endpointURL + adFive.photo_ar" :aspect-ratio="'auto'" max-height="120"
            :alt="adFive.title">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col cols="6" sm="4">
          <v-img v-if="adSix" :lazy-src="$store.state.endpointURL + ($vuetify.rtl ? adSix.photo_ar : adSix.photo_en)"
            contain :src="$store.state.endpointURL + adSix.photo_ar" :aspect-ratio="'auto'" max-height="120"
            :alt="adSix.title">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col> -->
      </v-row>
    </v-col>
  </div>
</template>

<script>
import ADImg from './ADImg.vue';

export default {
    name: "Appbar",
    data: () => ({
        languages: ["en", "ar"],
        countries: ["egypt", "saudi", "emirate", "kuwait", "qatar", "iraq", "oman"],
    }),
    methods: {
        changeLanguage(language) {
            console.log(language);
            this.$store.dispatch("Settings/changeLanguage");
        },
    },
    computed: {
        adOne() {
            return this.$global.FilterArrayOfObject(this.$store.state.header_ads, "ads_code", "01-001")[0];
        },
        adTwo() {
            return this.$global.FilterArrayOfObject(this.$store.state.header_ads, "ads_code", "01-002")[0];
        },
        adThree() {
            return this.$global.FilterArrayOfObject(this.$store.state.header_ads, "ads_code", "01-003")[0];
        },
        adFour() {
            return this.$global.FilterArrayOfObject(this.$store.state.header_ads, "ads_code", "01-004")[0];
        },
        adFive() {
            return this.$global.FilterArrayOfObject(this.$store.state.header_ads, "ads_code", "01-005")[0];
        },
        adSix() {
            return this.$global.FilterArrayOfObject(this.$store.state.header_ads, "ads_code", "01-006")[0];
        },
    },
    components: { ADImg }
};
</script>

<style lang="scss" scoped>
</style>
