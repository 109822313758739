<template>
  <v-container fluid>
    <Breadcrumb :data="breadcrumb" />
    <v-row class="mt-3" v-if="!isLoading" justify="end">
      <v-col cols="12" lg="4" sm="6" order="1" order-lg="2">
        <v-card class="pa-3 shadow" v-if="viewForm == false">
          <v-btn block class="primary--text px-8 shadow" color="#E6ECFF"
            @click="$store.state.isAuth ? viewForm = true : $router.push('/login')" large>
            {{ $store.state.isAuth ? $t("Add A deal") : $t("login to add deal") }}</v-btn>
        </v-card>
        <v-card class="pa-5 py-8 shadow" v-if="viewForm == true">
          <v-form ref="form" v-model="valid" class="row">
            <v-col cols="12" class="py-0">
              <v-autocomplete :rules="$store.state.Settings.requiredRule" :items="producers"
                :no-data-text="$i18n.t('no selects')" :label="$i18n.t('producer')"
                :placeholder="$i18n.t('Choose From List')" item-text="name" v-model="form.producer_id" item-value="id"
                outlined></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete :rules="$store.state.Settings.requiredRule" :items="products"
                :no-data-text="$i18n.t('no selects')" :label="$i18n.t('Product')"
                :placeholder="$i18n.t('Choose From List')" v-model="form.product_id" item-text="name" item-value="id"
                outlined></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete :rules="$store.state.Settings.requiredRule" :items="filtered_products_types"
                :no-data-text="$i18n.t('no selects')" :disabled="!(form.product_id)"
                :label="!form.product_id ? $i18n.t('product type (select product first)') : $i18n.t('Product kind')"
                :placeholder="$i18n.t('Choose From List')" item-text="name" v-model="form.product_type_id"
                item-value="id" outlined></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete :rules="$store.state.Settings.requiredRule"
                :disabled="!(form.product_id && form.product_type_id && form.producer_id)" :items="filtered_items"
                :no-data-text="$i18n.t('no selects')"
                :label="!(form.product_id && form.product_type_id && form.producer_id) ? $i18n.t('item (select product - type - producer first)') : $i18n.t('item') | capitalize"
                :placeholder="$i18n.t('Choose From List')" item-text="name" v-model="form.item_id" item-value="id"
                outlined></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete :rules="$store.state.Settings.requiredRule" :items="governorates"
                :no-data-text="$i18n.t('no selects')" :label="$i18n.t('governorate') | capitalize"
                :placeholder="$i18n.t('Choose From List')" item-text="name" v-model="form.governorate_id"
                item-value="id" outlined></v-autocomplete>
            </v-col>
            <v-col cols="7" class="py-0">
              <v-text-field :rules="$store.state.Settings.requiredRule" :label="$i18n.t('Quantity') | capitalize"
                outlined v-model="form.quantity" type="number"></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-autocomplete :rules="$store.state.Settings.requiredRule" :items="units"
                :no-data-text="$i18n.t('no selects')" :placeholder="$i18n.t('unit') | capitalize" item-text="name"
                v-model="form.unit_id" item-value="id" outlined></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-btn block class="primary--text px-8 shadow" color="#E6ECFF" large :loading="isSaveLoading"
                :disabled="valid == false" @click="addDeal">{{ $t("Add A deal") }}</v-btn>
            </v-col>
          </v-form>
        </v-card>
        <!-- ADS -->
        <v-card elevation="0" class="pa-5 mt-5 shadow">
          <!-- ADS  -->
          <v-row align="center">
            <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="firstAd" :maxWidth="'auto'"  :contain="false" :aspectRatio="1280/400" classes="mt-3 rounded-lg" />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="secondAd" :maxWidth="'auto'"  :contain="false" :aspectRatio="1280/400" classes="mt-3 rounded-lg" />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="6" class="px-0 cursor_pointer">
              <ADImg :ad="thirdAd" :maxWidth="'auto'"  :contain="false" :aspectRatio="1280/400" classes="mt-3 rounded-lg" />
            </v-col>
          </v-row>
          <!--  -->

        </v-card>
      </v-col>

      <v-col cols="12" lg="8" order="2" order-lg="1">
        <!-- search -->
        <v-card class="pa-6 shadow rounded-md">
          <v-row justify="space-between">
            <v-col cols="6" md="auto" sm="3" lg="3" class="d-flex align-center pa-sm-0">
              <v-autocomplete clearable :items="products" :no-data-text="$i18n.t('no selects')"
                :placeholder="$i18n.t('product') | capitalize" v-model="filterByProduct" hide-details item-text="name"
                item-value="id" class="m-auto" flat solo></v-autocomplete>
              <v-divider vertical class="ma-0" style="height: 35px"></v-divider>
            </v-col>
            <v-col cols="6" md="auto" sm="3" lg="3" class="d-flex align-center pa-sm-0">
              <v-autocomplete clearable :items="producers" :no-data-text="$i18n.t('no selects')"
                :placeholder="$i18n.t('Producer') | capitalize" v-model="filterByProducer" hide-details item-text="name"
                item-value="id" class="m-auto" flat solo></v-autocomplete>
              <v-divider vertical class="ma-0 d-sm-block d-none" style="height: 35px"></v-divider>
            </v-col>
            <v-col cols="6" md="auto" sm="3" lg="3" class="d-flex align-center pa-sm-0">
              <v-autocomplete clearable :items="items" v-model="filterByItem" :no-data-text="$i18n.t('no selects')"
                :placeholder="$i18n.t('item') | capitalize" hide-details item-text="name" item-value="id" class="m-auto"
                flat solo>
              </v-autocomplete>
              <v-divider vertical class="ma-0" style="height: 35px"></v-divider>
            </v-col>
            <v-col cols="6" md="auto" sm="3" lg="3" class="d-flex align-center pa-sm-0">
              <v-autocomplete clearable :items="governorates" v-model="filterByGovernorate"
                :no-data-text="$i18n.t('no selects')" :placeholder="$i18n.t('governorate') | capitalize" hide-details
                item-text="name" item-value="id" class="m-auto" flat solo></v-autocomplete>
            </v-col>
          </v-row>
        </v-card>

        <!-- deals List -->
        <v-col cols="12" class="pa-0 mt-7">
          <v-col cols="12" class="pt-0 pa-0" v-for="(deal, index) in deals" :key="index"
            :class="index == 0 ? 'mb-8 mt-3' : 'my-8'">
            <v-card class="shadow px-3">
              <v-row justify="space-between">
                <v-col cols="12" md="4" sm="4" class="d-flex">
                  <v-avatar class="ma-auto d-block rounded-lg " size="180" tile>
                    <v-img src="@/assets/svg/defaultDeal.svg" lazy-src="@/assets/svg/defaultDeal.svg"
                      alt="cement egypt"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="4" sm="8">
                  <v-card-subtitle class="body-1 gray5--text py-2">
                    {{ $t("product") }} :
                    <strong class="font-weight-bold">{{ deal.product_name }}</strong>
                  </v-card-subtitle>
                  <v-card-subtitle class="body-1 gray5--text py-2">
                    {{ $t("type") }} :
                    <strong class="font-weight-bold">{{ deal.product_type_name }}</strong>
                  </v-card-subtitle>
                  <v-card-subtitle class="body-1 gray5--text py-2">
                    {{ $t("item") }} :
                    <strong class="font-weight-bold">{{ deal.item_name }}</strong>
                  </v-card-subtitle>
                  <v-card-subtitle class="body-1 gray5--text py-2">
                    {{ $t("governorate") }} :
                    <strong class="font-weight-bold">{{ deal.governorate_name }}</strong>
                  </v-card-subtitle>
                  <v-card-subtitle class="body-1 gray5--text py-2">
                    {{ $t("quantity") }} :
                    <strong class="font-weight-bold">{{ deal.quantity }} {{ deal.unit_name }}</strong>
                  </v-card-subtitle>
                  <v-card-subtitle class="body-1 gray5--text py-2">
                    {{ $t("producer") }} :
                    <strong class="font-weight-bold">{{ deal.producer_name }}</strong>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="row pa-10 justify-end" style="width: 100% ;">
                  <div class="body-1 gray5--text text-end">
                    {{ $t("added by") }} :
                    <strong class="font-weight-bold">{{ deal.added_by }}</strong>
                  </div>

                  <v-card-actions>
                    <v-btn class="mx-2 mt-auto primary--text px-8 shadow" color="#E6ECFF" :loading="isApplyLoading" @click="applyDeal(deal.id)">
                      {{ $store.state.isAuth ? $t("Request Deal") : $t("login to request deal") }}</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- Ads -->
          <v-col cols="12" sm="12" class="pa-0">
            <ADImg :ad="fourthAd" :maxWidth="'100%'"  :contain="false" :aspectRatio="1280/400" classes="mt-3 rounded-lg" />
          </v-col>
          <!-- Pagination -->
          <v-col cols="12" sm="12" v-if="!isLoading && deals.length > 0">
            <Pagination :pagination="pagination" :limit="rowsNumber" :backValueMethod="changePage"
              :isLoading="isLoading" />
          </v-col>
        </v-col>
      </v-col>

      <!-- --------------------------------------------------------------------------------------------- -->
    </v-row>

    <v-row justify="center" v-if="isLoading">
      <v-col cols="auto" class="my-15">
        <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import ADImg from "@/components/ADImg.vue";
export default {
  name: "DealsList",
  components: {
    Breadcrumb,
    Pagination,
    ADImg
  },
  mounted() {
    this.$global.DELETE_META();
    this.$global.ADD_META(this.$i18n.t('Cement Egypt Deals'), 'title');
    this.page = this.$route.params.page;
    this.getData();
  },

  data: () => ({
    breadcrumb: [],
    viewForm: false,
    valid: false,
    isApplyLoading: false,
    isLoading: false,
    isSaveLoading: false,
    slider_data: [],
    firstAd: {},
    secondAd: {},
    thirdAd: {},
    fourthAd: {},
    page: 1,
    rowsNumber: 10,
    pagination: {},
    items: [],
    products: [],
    products_types: [],
    producers: [],
    governorates: [],
    units: [],
    form: {
      item_id: null,
      product_id: null,
      product_type_id: null,
      producer_id: null,
      governorate_id: null,
      unit_id: null,
      quantity: null,
    },
    deals: [],
    filterByWord: null,
    filterByProduct: null,
    filterByProducer: null,
    filterByGovernorate: null,
    filterByItem: null,
  }),
  watch: {
    filterByProduct() {
      this.getData();
    },
    filterByProducer() {
      this.getData();
    },
    filterByItem() {
      this.getData();
    },
    filterByGovernorate() {
      this.getData();
    },
    $route() {
      this.$global.DELETE_META();
      this.page = this.$route.params.page;
      this.$global.ADD_META(this.$i18n.t('Cement Egypt Deals'), 'title');
      this.getData();
    },
    "form.product_id"() {
      this.form.product_type_id = null;
    },
    "form.product_type_id"() {
      this.form.item_id = null;
    },
    "form.producer_id"() {
      this.form.item_id = null;
    },
  },
  computed: {
    filtered_items() {
      console.log('form', this.form);
      return this.items.filter((item) =>
        item['product_id'] == this.form.product_id
        && item['product_type_id'] == this.form.product_type_id
        && item['producer_id'] == this.form.producer_id
      )
    },
    filtered_products_types() {
      return this.products_types.filter((type) => type['product_id'] == this.form.product_id)
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.breadcrumb = [
        {
          text: this.$i18n.t("cement egypt"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$route.params.meta_name.replaceAll('-', ' '),
          disabled: false,
          to: "/society/deals",
        },
      ];
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$api.GET_METHOD(`deal?rows=${this.rowsNumber}&page=${this.page || 1}&producer=${this.filterByProducer || ''}&governorate=${this.filterByGovernorate || ''}&product=${this.filterByProduct || ''}&item=${this.filterByItem || ''}`).then((response) => {
        this.isLoading = false
        if (response.check) {
          console.log(response);
          this.slider_data = response.data.all_sliders || [];
          this.firstAd = this.$global.FilterArrayOfObject(response.data.deals_ads, 'ads_code', '03-001')[0];
          this.secondAd = this.$global.FilterArrayOfObject(response.data.deals_ads, 'ads_code', '03-002')[0];
          this.thirdAd = this.$global.FilterArrayOfObject(response.data.deals_ads, 'ads_code', '03-003')[0];
          this.fourthAd = this.$global.FilterArrayOfObject(response.data.deals_ads, 'ads_code', '03-004')[0];

          this.deals = response.data.deals.data || [];
          this.page = response.data.deals.current_page;
          this.pagination = {
            page: response.data.deals.current_page,
            totalPages: response.data.deals.last_page,
            per_page: response.data.deals.per_page,
            totalRows: response.data.deals.total,
          };


          this.items = response.data.items || [];
          this.products = response.data.products || [];
          this.products_types = response.data.products_types || [];
          this.producers = response.data.producers || [];
          this.governorates = response.data.governorates || [];
          this.units = response.data.units || [];

          response.data.deals.data.forEach(deal => {
            this.$global.ADD_META(deal.item_name, 'keywords');
            this.$global.ADD_META(deal.product_name, 'keywords');
            this.$global.ADD_META(deal.producer_name, 'keywords');
            this.$global.ADD_META(deal.product_type_name, 'keywords');
            this.$global.ADD_META(deal.governorate_name, 'keywords');
            this.$global.ADD_META(deal.unit_name, 'keywords');
          });
          response.data.deals_ads.forEach(ad => {
            this.$global.ADD_META(this.$store.state.endpointURL + ad.photo, 'image');
            this.$global.ADD_META(ad.title, 'keywords');
            this.$global.ADD_META(ad.link, 'url');
          });
        } else {
          this.news = [];
          this.slider_data = [];
        }
      })
    },
    changePage(page) {
      this.$router.push(`/society/deals/${page}/${this.$i18n.t('Cement Egypt Deals').split(/\s+/).join('-')}`);
    },
    applyDeal(id) {
      if (this.$store.state.isAuth) {
        this.isApplyLoading = true;
        this.$api.POST_METHOD(`apply_deal`, {deal_id : id}).then((response) => {
          this.isApplyLoading = false;
          if (response.check) {
            this.$global.ShowAlert(
              "success",
              this.$i18n.t("You are applied to this deal successfully"),
              false,
              "",
              3000
            );
            // this.getData();
          }
        })

      } else {
        this.$router.push('/login')
      }
    },
    addDeal() {
      this.isSaveLoading = true;
      this.$api.POST_METHOD(`deal`, this.form).then((response) => {
        this.isSaveLoading = false;
        if (response.check) {
          this.$global.ShowAlert(
            "success",
            this.$i18n.t("deal added successfully"),
            false,
            "",
            3000
          );
          this.getData();
          this.viewForm = false;
          this.form = {
            item_id: null,
            product_id: null,
            product_type_id: null,
            producer_id: null,
            governorate_id: null,
            unit_id: null,
            quantity: null,
          };
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.job {
  border: 0.704431px solid #c4c4c4;
  border-radius: 12.6798px;
  // width: 92.98px;
  padding: 1rem;
  text-transform: capitalize;
  text-align: center;

  img {
    height: 61.99px;
    // transform: scale(1.4, 1.4);
  }
}

.input-search {
  background: #e8e8ea;
  border-radius: 25px;
  border: 0;
  width: 100%;
  height: 40px;
  padding: 0.2rem 0.8rem;
  color: #8e8c94;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: #8e8c94;
  }
}

.search-icon {
  position: absolute;
  right: 2rem;
  top: 1.65rem;
}
</style>
