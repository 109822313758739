<template>
  <v-card class="shadow-none  d-block ma-auto my-8 pa-5" id="payment-card">
    <v-row justify-center>
      <v-col cols="12">
        <img v-if="paymentStatus === 'success'" class="d-block ma-auto" src="@/assets/png/happy.png" alt="cement egypt">
        <img v-else src="@/assets/png/sad.png" class="d-block ma-auto" alt="cement egypt">
      </v-col>
      <v-col cols="12">
        <div class="text-h5 text-center font-weight-bold text-capitalize"
          :class="paymentStatus === 'success' ? 'success--text' : 'error--text'">
          {{ paymentStatus === 'success' ? $t('payment is successfully done') : $t('payment failed') }} !
        </div>
      </v-col>
      <v-col cols="12" v-if="paymentStatus === 'failed'">
        <div class="subtitle-1 text-center gray--text font-weight-bold text-capitalize">
          {{ $t('your payment was not successfully processed. please contact our customer support') }}
        </div>
      </v-col>
      <v-col cols="12" v-if="invoiceNumber || paymentStatus === 'failed'" class="px-16">
        <v-divider />
      </v-col>
      <v-col v-if="invoiceNumber" cols="12">
        <div class="subtitle-1  text-capitalize" :class="'gray--text'">
          {{ $t('transaction number') }} : <span class="font-weight-bold" dir="ltr">#{{ invoiceNumber }}</span>
        </div>
        <div v-if="totalPrice" class="subtitle-1  text-capitalize" :class="'gray--text'">
          {{ $t('amount paid') }} : <span class="font-weight-bold">{{ +totalPrice | float }} {{ $t('le') }}</span>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12" lg="12" sm="6"
      v-if="$store.state.website_basic_data.phone && $store.state.website_basic_data.whatsapp && $store.state.website_basic_data.email">

      <v-row class="align-center justify-center" v-if="paymentStatus === 'failed'">
        <v-col cols="auto" v-if="$store.state.website_basic_data.phone">
          <div class="subtitle-2 font-weight-medium">
            <v-icon class="mx-2" color="primary">mdi-phone</v-icon>
            {{ $store.state.website_basic_data.phone }}
          </div>
        </v-col>
        <v-col cols="auto" v-if="$store.state.website_basic_data.whatsapp">
          <div class="subtitle-2 font-weight-medium">
            <v-icon class="mx-2" color="primary">mdi-whatsapp</v-icon>
            {{ $store.state.website_basic_data.whatsapp }}
          </div>
        </v-col>
        <v-col cols="auto" v-if="$store.state.website_basic_data.email">
          <div class="subtitle-2 font-weight-medium">
            <v-icon class="mx-2" color="primary">mdi-email-outline</v-icon>
            {{ $store.state.website_basic_data.email }}
          </div>
        </v-col>
      </v-row>
    </v-col>

  </v-card>
</template>

<script>
export default {
  name: "PaymentStatus",
  components: {},
  computed: {
    paymentStatus() {
      return this.$router.currentRoute.query['status'];
    },
    invoiceNumber() {
      return this.$router.currentRoute.query['invoice']
    },
    totalPrice() {
      return this.$router.currentRoute.query['price'] || 0
    },
  },
  data: () => ({}),
};
</script>
<style scoped lang="scss">
#payment-card {
  width: fit-content;
  max-width: 500px;

  img {
    height: 150px;
  }
}
</style>
