<template>
  <span>
    <WebsiteHeader class="d-none d-lg-block" />
    <Appbar class="d-lg-none" />
    <NavigationDrawer class="d-lg-none" />

    <v-main>
      <transition
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <router-view appear :key="path"></router-view>
      </transition>
    </v-main>

    <PageFooter />
  </span>
</template>

<script>
import Appbar from "../components/Appbar.vue";
import NavigationDrawer from "../components/NavigationDrawer.vue";
import WebsiteHeader from "../components/WebsiteHeader.vue";
import PageFooter from "../components/Footer.vue";
export default {
  name: "MainModule",
  components: {
    WebsiteHeader,
    NavigationDrawer,
    Appbar,
    PageFooter,
  },
  data: () => ({}),
};
</script>
