import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/custom-axios";
import i18n from "./plugins/i18n";
import VueCryptojs from "./plugins/cryptojs";
import VueLazyload from "./plugins/lazyload";
import VueSweetalert2 from "./plugins/sweetalert";
import vueMeta from "./plugins/vueMeta";
import otp from "./plugins/otp";
import recaptcha from "./plugins/recaptcha";
import "./plugins/apexcharts";
// import VueCryptojs from "./plugins/cryptojs";
import global from "./plugins/global";
import apiPlugin from "./plugins/api";
import './registerServiceWorker'
import filters from "./filters/filters";
import VueAnalytics from 'vue-analytics';

// import VueHead from 'vue-head'

// Vue.use(VueHead, {
//   separator: '-',
//   complement: 'CementEgypt',
// })


Vue.prototype.$http = axios;



Vue.use(VueAnalytics, {
  id: 'UA-80639064-1' ,
  router
});


Vue.use(global);
Vue.use(apiPlugin);
Vue.filter(filters);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  i18n,
  vueMeta,
  VueLazyload,
  VueCryptojs,
  otp,
  recaptcha,
  VueSweetalert2,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
