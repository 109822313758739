<template>
  <section class="white" style="">
    <v-container class="py-3">


      <!-- Type 1 -->
      <v-row v-if="!isLoading" align="stretch" justify="center" class="mt-5">
        <v-col cols="12">
          <h1 class="primary--text  font-weight-black">
            {{ packageTitle1 || '-' | capitalize }} :
          </h1>
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4" sm="6" v-for="(package_data, i) in packagesType1" :key="i">
          <v-card class="pricingCard pa-5">

            <v-col cols="12" class="pa-0">
              <h3 class="darkBlue--text text-center font-weight-black">
                {{ package_data.subscription_title || '-' | capitalize }}
              </h3>

              <h2 class="primary--text text-center font-weight-thin mt-3">
                <span class="font-weight-black">{{ package_data.price }}</span>
                <small>{{ $t("le") }}</small>
                <small>/{{ package_data.duration || 0 }}{{ $t("month") }}</small>
              </h2>
              <v-tooltip open-delay="750" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="mt-2 pricingDetails  body-1 darkBlue--text text-center">
                    {{ package_data.subscription_description || '-' | capitalize }}
                  </div>
                </template>
                <span> {{ package_data.subscription_description || '-' | capitalize }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-btn block class="mt-3 primary--text px-8 shadow rounded-lg" @click="selectPackage(package_data)"
                color="#E6ECFF" large>
                {{ $t("subscribe now") }}
              </v-btn>
            </v-col>

            <!-- <div class="d-flex mt-2 align-center body-1 darkBlue--text">
              <v-icon small class="mx-2" color="darkBlue">mdi-check</v-icon>
              Upload Video up to 720p Resolution
            </div>
            <div class="d-flex mt-2 text--disabled align-center body-1 gray--text">
              <v-icon small class="mx-2" disabled>mdi-close</v-icon>
              Attachment & Post Scheduling
            </div> -->
          </v-card>
        </v-col>
      </v-row>

      <!-- Type 2 -->
      <v-row v-if="!isLoading" align="stretch" justify="center" class="mt-5">
        <v-col cols="12">
          <h1 class="primary--text  font-weight-black">
            {{ packageTitle2 || '-' | capitalize }} :
          </h1>
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4" sm="6" v-for="(package_data, i) in packagesType2" :key="i">
          <v-card class="pricingCard pa-5">

            <v-col cols="12" class="pa-0">
              <h3 class="darkBlue--text text-center font-weight-black">
                {{ package_data.subscription_title || '-' | capitalize }}
              </h3>

              <h2 class="primary--text text-center font-weight-thin mt-3">
                <span class="font-weight-black">{{ package_data.price }}</span>
                <small>{{ $t("le") }}</small>
                <small>/{{ package_data.duration || 0 }}{{ $t("month") }}</small>
              </h2>
              <v-tooltip open-delay="750" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="mt-2 pricingDetails  body-1 darkBlue--text text-center">
                    {{ package_data.subscription_description || '-' | capitalize }}
                  </div>
                </template>
                <span> {{ package_data.subscription_description || '-' | capitalize }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-btn block class="mt-3 primary--text px-8 shadow rounded-lg" @click="selectPackage(package_data)"
                color="#E6ECFF" large>
                {{ $t("subscribe now") }}
              </v-btn>
            </v-col>

            <!-- <div class="d-flex mt-2 align-center body-1 darkBlue--text">
              <v-icon small class="mx-2" color="darkBlue">mdi-check</v-icon>
              Upload Video up to 720p Resolution
            </div>
            <div class="d-flex mt-2 text--disabled align-center body-1 gray--text">
              <v-icon small class="mx-2" disabled>mdi-close</v-icon>
              Attachment & Post Scheduling
            </div> -->
          </v-card>
        </v-col>
      </v-row>

      <!-- Type 3 -->
      <v-row v-if="!isLoading" align="stretch" justify="center" class="mt-5">
        <v-col cols="12">
          <h1 class="primary--text  font-weight-black">
            {{ packageTitle3 || '-' | capitalize }} :
          </h1>
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="4" sm="6" v-for="(package_data, i) in packagesType3" :key="i">
          <v-card class="pricingCard pa-5">

            <v-col cols="12" class="pa-0">
              <h3 class="darkBlue--text text-center font-weight-black">
                {{ package_data.subscription_title || '-' | capitalize }}
              </h3>

              <h2 class="primary--text text-center font-weight-thin mt-3">
                <span class="font-weight-black">{{ package_data.price }}</span>
                <small>{{ $t("le") }}</small>
                <small>/{{ package_data.duration || 0 }}{{ $t("month") }}</small>
              </h2>
              <v-tooltip open-delay="750" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="mt-2 pricingDetails  body-1 darkBlue--text text-center">
                    {{ package_data.subscription_description || '-' | capitalize }}
                  </div>
                </template>
                <span> {{ package_data.subscription_description || '-' | capitalize }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-btn block class="mt-3 primary--text px-8 shadow rounded-lg" @click="selectPackage(package_data)"
                color="#E6ECFF" large>
                {{ $t("subscribe now") }}
              </v-btn>
            </v-col>

            <!-- <div class="d-flex mt-2 align-center body-1 darkBlue--text">
              <v-icon small class="mx-2" color="darkBlue">mdi-check</v-icon>
              Upload Video up to 720p Resolution
            </div>
            <div class="d-flex mt-2 text--disabled align-center body-1 gray--text">
              <v-icon small class="mx-2" disabled>mdi-close</v-icon>
              Attachment & Post Scheduling
            </div> -->
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="isLoading">
        <v-col cols="auto" class="my-15">
          <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
          </v-progress-circular>
        </v-col>
      </v-row>


      <!-- Dialog -->

      <v-dialog v-model="confirmationDialog" overlay-opacity="0.75" persistent :max-width="500">
        <v-card relative class="pa-7 py-10">
          <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="confirmationDialog = false">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
          <v-row align="center" class="mt-2" justify="center">

            <v-col cols="12">
              <div class="gray5--text font-weight-bold text-h6">
                {{ $t('you will be redirect to my fatora') || capitalize }}
              </div>
            </v-col>
            <!-- <v-col cols="12">
              <div class=" font-weight-bold  subtitle-1">
                {{ $t('your subscription will be in') || capitalize }} :
              </div>
            </v-col> -->
            <v-col cols="12" v-for="(select_package, index) in selectedPackages" :key="index">
              <div class="subtitle-1 d-flex justify-space-between ">
                <div class="gray5--text">
                  {{ select_package.subscription_type_name || '' }} :
                  {{ select_package.subscription_title || '' }}
                </div>
                <div class="font-weight-bold">
                  {{ select_package.price || 0 | float}} {{ $t('le') }}
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="subtitle-1 d-flex justify-space-between">
                <div class="gray5--text">
                  {{ $t('vat') || capitalize }} :
                </div>
                <div class="font-weight-bold">
                  {{ 0 | float }} {{ $t('le') }}
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="subtitle-1 d-flex justify-space-between">
                <div class="gray5--text">
                  {{ $t('total subscription price') || capitalize }} :
                </div>
                <div class="font-weight-bold">
                  {{ totalPrice || 0 | float}} {{ $t('le') }}
                </div>
              </div>
            </v-col>
            <v-col cols="auto" class="d-flex justify-center">
              <v-btn class="mx-2" text depressed color="red4" @click="closeDialog" :height="37" :width="120">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn class="mx-2 white--text" :loading="saveIsLoading" @click="saveSubscription" depressed
                color="primary" :height="37" :width="120">
                {{ $t('ok') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>

export default {
  name: "ChoosePlanPage",
  components: {},
  data: () => ({
    step: 1,
    type: false,
    active: 0,
    isLoading: false,
    saveIsLoading: false,
    confirmationDialog: false,
    userPlan: 4,
    subscriptions_types: null,
    packagesType1: [],
    packagesType2: [],
    packagesType3: [],
    packageTitle1: null,
    packageTitle2: null,
    packageTitle3: null,
    selectedPackages: []
  }),
  mounted() {
    this.$global.DELETE_META();
    this.$global.ADD_META(this.$i18n.t('choose your plan'), 'title');
    this.getData();
  },
  computed: {

    totalPrice() {
      return this.selectedPackages.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.price);
      }, 0);
    },
  },
  methods: {
    isSubscribe(package_data) {
      const isSub = (obj) => obj.subscription_id == package_data.subscription_id;
      if (this.$store.state.isAuth) {
        return this.$store.state.userData.subscriptions.some(isSub);
      } else {
        return false;
      }
    },
    getData() {
      this.isLoading = true;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$api.GET_METHOD(`subscription`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          console.log(response);
          this.subscriptions_types = response.data.subscriptions_types;
          Object.keys(response.data.subscriptions_types['1'].subscriptions).forEach(key => {
            this.packageTitle1 = response.data.subscriptions_types['1'].name
            this.packagesType1.push(...response.data.subscriptions_types['1'].subscriptions[key].items)
          });
          Object.keys(response.data.subscriptions_types['2'].subscriptions).forEach(key => {
            this.packageTitle2 = response.data.subscriptions_types['2'].name
            this.packagesType2.push(...response.data.subscriptions_types['2'].subscriptions[key].items)
          });
          Object.keys(response.data.subscriptions_types['3'].subscriptions).forEach(key => {
            this.packageTitle3 = response.data.subscriptions_types['3'].name
            this.packagesType3.push(...response.data.subscriptions_types['3'].subscriptions[key].items)
          });
          console.log(this.packageTitle1, this.packagesType1);
          console.log(this.packageTitle2, this.packagesType2);
          console.log(this.packageTitle3, this.packagesType3);
        }
      })
    },
    selectPackage(selectedPackage) {
      if (selectedPackage !== null) {
        this.selectedPackages.push(selectedPackage);
      }
      this.confirmationDialog = true
    },
    saveSubscription() {
      this.saveIsLoading = true;

      this.$api.POST_METHOD(`subscribe`, { subscriptions: this.selectedPackages }).then((response) => {
        this.saveIsLoading = false;
        if (response.check) {
          window.open(response.data, "_self")
        }
      })
    },
    closeDialog() {
      this.selectedPackages = [];
      this.confirmationDialog = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.pricingCard {
  border-radius: 0;
  transition: 0.3s;
  box-shadow: 0px 0.6px 15px rgba(0, 0, 0, 0.06) !important;
  overflow: hidden;
  border-radius: 15px;
  display: grid;
  align-content: space-between;
  justify-content: center;
  height: 260px;

  .pricingDetails {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 220px;
  }

  &:hover {
    box-shadow: 0px 4px 12px rgba(204, 95, 255, 0.25) !important;
    background-color: #FDFDFD;

  }
}
</style>
