<template>
  <v-container fluid>
    <Breadcrumb :data="breadcrumb" />
    <section class="my-5">
      <v-row v-if="!isLoading">
        <!-- <v-col cols="12" class="relative" order="1">
          <v-img lazy-src="@/assets/svg/ad_rectangle.svg" src="@/assets/svg/ad_rectangle.svg" :aspect-ratio="16 / 4"
            alt="cement egypt" class="mt-3 rounded-lg">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col> -->

        <!-- Filter -->
        <v-col cols="12" order="2">

          <v-card class="pa-6 shadow rounded-md" :disabled="comparisonItems.items.length == 4">
            <v-row justify="space-between" align="center">
              <v-col cols="6" md="3" sm="6" class="d-flex align-center">
                <v-autocomplete dense :items="products" :disabled="(comparison.length > 0)"
                  :no-data-text="$i18n.t('no selects') | capitalize" :placeholder="$i18n.t('product') | capitalize"
                  v-model="filterByProduct" hide-details item-text="name" item-value="id" class="m-auto" outlined>
                </v-autocomplete>
                <!-- <v-divider vertical class="ma-0 d-sm-block d-none" style="height: 35px"></v-divider> -->
              </v-col>
              <v-col cols="6" md="3" sm="6" class="d-flex align-center">
                <v-autocomplete dense :items="filtered_products_types" :loading="producerIsLoading"
                  :no-data-text="$i18n.t('no selects') | capitalize" :placeholder="$i18n.t('product type') | capitalize"
                  v-model="filterByProductType" hide-details item-text="name" item-value="id" class="m-auto" outlined>
                </v-autocomplete>
                <!-- <v-divider vertical class="ma-0 d-md-block d-none" style="height: 35px"></v-divider> -->
              </v-col>
              <v-col cols="6" md="3" sm="6" class="d-flex align-center">
                <v-autocomplete dense :items="producers" :loading="producerIsLoading"
                  :no-data-text="$i18n.t('no selects') | capitalize" :placeholder="$i18n.t('Producer') | capitalize"
                  v-model="filterByProducer" hide-details item-text="name" item-value="id" class="m-auto" outlined>
                </v-autocomplete>
                <!-- <v-divider vertical class="ma-0 d-sm-block d-none" style="height: 35px"></v-divider> -->
              </v-col>
              <v-col cols="6" md="3" sm="6" class="d-flex align-center">
                <v-autocomplete dense :items="filtered_items" :loading="producerIsLoading"
                  :no-data-text="$i18n.t('no selects') | capitalize" :placeholder="$i18n.t('item') | capitalize"
                  v-model="filterByItem" hide-details item-text="name" item-value="id" class="m-auto" outlined>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" md="3" sm="6" class="d-flex align-center">
                <v-autocomplete dense :items="filtered_governorates" :loading="producerIsLoading"
                  v-model="filterByGovernorate" :no-data-text="$i18n.t('no selects') | capitalize"
                  :placeholder="$i18n.t('governorate') | capitalize" hide-details item-text="name" item-value="id"
                  class="m-auto" outlined></v-autocomplete>
              </v-col>

              <v-col cols="12" md="9" sm="12">
                <v-card class="px-7 py-6 shadow rounded-md" color="#D3E4F4">
                  <v-row justify="space-between">
                    <v-col cols="4" md="4" sm="4" class="d-flex align-center justify-center pa-0">
                      <v-menu v-model="fromMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :disabled="(comparison.length > 0)" append-icon="mdi-calendar"
                            v-model="from_date" :placeholder="$i18n.t('from')" readonly v-bind="attrs" v-on="on"
                            hide-details flat solo dense></v-text-field>
                        </template>
                        <v-date-picker :max="
                          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                            .toISOString()
                            .substr(0, 10)
                        " v-model="from_date" @input="fromMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" md="1" sm="1" class="d-none d-sm-flex align-center justify-center pa-0">
                      <v-icon class="ma-auto d-block" v-if="$vuetify.rtl == true">mdi-arrow-left-thin</v-icon>
                      <v-icon class="ma-auto d-block" v-else>mdi-arrow-right-thin</v-icon>
                    </v-col>
                    <v-col cols="4" md="4" sm="4" class="d-flex align-center justify-center pa-0">
                      <v-menu v-model="toMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :disabled="(comparison.length > 0)" append-icon="mdi-calendar" v-model="to_date"
                            :placeholder="$i18n.t('to')" readonly v-bind="attrs" v-on="on" hide-details flat solo
                            width="100" dense></v-text-field>
                        </template>
                        <v-date-picker :max="
                          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                            .toISOString()
                            .substr(0, 10)
                        " :min="from_date" v-model="to_date" @input="toMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center justify-center pa-0">
                      <v-btn depressed color="white" @click="addToComparison" :disabled="!(valid)"
                        :loading="getPricesLoading" class="d-block mi-start-auto primary--text">
                        <img src="../../assets/svg/compare.svg" class="mx-2" alt="cement egypt" />
                        <span class="d-none d-lg-block">{{ $t('add to comparison') }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

            </v-row>
          </v-card>

          <!-- {{ comparison }} -->
        </v-col>

        <!-- --------------------------------------------------------------------------------------------- -->
        <!-- Materials prices Tables -->
        <v-col cols="12" order="4" v-if="comparison.length > 0">
          <MaterialComparisonTable :data="comparison" :comparisonItems="comparisonItems" :removeProduct="removeProduct"
            :isLoading="false" />
        </v-col>
        <v-col cols="12" order="4" v-if="getPricesLoading">
          <v-row justify="center">
            <v-col cols="auto" class="my-15">
              <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
                indeterminate>
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-col>

        <!-- <v-col cols="12" lg="2" sm="12" order="3" order-lg="6">
          <v-card elevation="0" class="rounded-lg pa-5 py-6 mt-4">
            <v-row>
              <v-col cols="12" lg="12" sm="6" v-for="(statistic, index) in statistics" :key="index">
                <v-card elevation="0" :color="statistic.status == 'up' ? '#FEEAEA' : '#E9FDF8'" class="statistic">
                  <div class="subtitle-1 font-weight-medium red--text" v-if="statistic.status == 'up'">
                    {{ $t(statistic.gov) | capitalize }}
                  </div>
                  <div class="subtitle-1 font-weight-medium black--text" v-if="statistic.status == 'normal'">
                    {{ $t(statistic.gov) | capitalize }}
                  </div>
                  <div class="subtitle-1 font-weight-medium green--text" v-if="statistic.status == 'down'">
                    {{ $t(statistic.gov) | capitalize }}
                  </div>
                  <div class="d-flex align-center justify-center red--text" v-if="statistic.status == 'up'">
                    <img src="@/assets/svg/up.svg" class="mx-2" alt="cement egypt" />
                    <span>{{ statistic.percentage }} {{ $t("LE") }}</span>
                  </div>
                  <div class="d-flex align-center justify-center black--text" v-if="statistic.status == 'normal'">
                    <img src="@/assets/svg/normal.svg" class="mx-2" alt="cement egypt" />
                    <span>{{ statistic.percentage }} {{ $t("LE") }}</span>
                  </div>
                  <div class="d-flex align-center justify-center green--text" v-if="statistic.status == 'down'">
                    <img src="@/assets/svg/down.svg" class="mx-2" alt="cement egypt" />
                    <span>{{ statistic.percentage }} {{ $t("LE") }}</span>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col> -->
        <!-- Ads -->
        <v-col cols="12" sm="12" order="6">
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" lg="4">
              <ADImg :ad="firstAd" :maxWidth="'auto'" :contain="true" :aspectRatio="1280 / 400"
                classes="mt-3 rounded-lg" />
              <ADImg :ad="secondAd" :maxWidth="'auto'" :contain="true" :aspectRatio="1280 / 400"
                classes="mt-3 rounded-lg" />
              <ADImg :ad="thirdAd" :maxWidth="'auto'" :contain="true" :aspectRatio="1280 / 400"
                classes="mt-3 rounded-lg" />
            </v-col>
            <v-col cols="12" md="6" lg="8">
              <ADImg :ad="fourthAd" :maxHeight="480" :maxWidth="'auto'" :contain="true" :aspectRatio="16 / 9"
                classes="mt-3 rounded-lg" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" order="7" order-lg="7" v-if="comparison.length > 0">
          <AnalysisChart :items="analysisItems" :comparisonItems="comparisonItems" :dates="analysisDates" />
        </v-col>
        <!-- End Materials prices Tables -->
      </v-row>
      <v-row justify="center" v-if="isLoading">
        <v-col cols="auto" class="my-15">
          <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
          </v-progress-circular>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import MaterialComparisonTable from "@/components/MaterialComparisonTable.vue";
// import MoveAroundServices from "@/components/MoveAroundServices.vue";
import AnalysisChart from "@/components/AnalysisChart.vue";
import ADImg from "@/components/ADImg.vue";
export default {
  name: "MaterialComparison",
  components: {
    Breadcrumb,
    MaterialComparisonTable,
    // MoveAroundServices,
    AnalysisChart,
    ADImg
  },
  mounted() {
    this.$global.ADD_META(this.$i18n.t('material comparison'), 'title');
    this.$global.ADD_META(this.$route.params.meta_name, 'title');
    this.getData();
  },

  data: () => ({
    breadcrumb: [],
    analysisItems: [],
    comparisonItems: {
      from_date: null,
      to_date: null,
      items: []
    },
    comparison: [],
    fromMenu: false,
    toMenu: false,
    from_date: null,
    to_date: null,
    isLoading: false,
    producerIsLoading: false,
    getPricesLoading: false,
    firstAd: {},
    secondAd: {},
    thirdAd: {},
    fourthAd: {},
    page: null,
    rowsNumber: null,
    pagination: {},
    items: null,
    products_types: [],
    products: [],
    producers: [],
    governorates: [],
    filtered_governorates: [],
    filterByWord: null,
    filterByProduct: null,
    filterByProductType: null,
    filterByProducer: null,
    filterByGovernorate: null,
    filterByItem: null,
    analysisChart: [],
    statistics: [
      {
        id: 1,
        gov: "lowest price",
        status: "up",
        percentage: "45",
      },
      {
        id: 2,
        gov: "highest price",
        status: "down",
        percentage: "86",
      },
    ],

  }),
  watch: {

    filterByProduct() {
      this.filterByProductType = 0;
      this.filterByProducer = null;
      this.filterByItem = null;
      this.filterByWord = null;
      this.filterByGovernorate = null;
    },
    filterByItem() {
      this.filtered_governorates = []
      if (Number(this.filterByItem)) {
        var itemGovs = this.$global.FilterArrayOfObjectElement(this.filtered_items, 'id', this.filterByItem, 'govs');
        itemGovs = itemGovs.split(',').map(i => Number(i))
        itemGovs.forEach(gov => {
          var filtered = this.$global.FilterArrayOfObject(this.governorates, 'id', gov)
          this.filtered_governorates.push(filtered[0]);
        });
      } else {
        this.filtered_governorates = this.governorates
      }
    },
    filterByProducer() {
      this.filterByItem = null;
    },
    filterByProductType() {
      this.filterByItem = null;

      this.producerIsLoading = true;
      this.$api.POST_METHOD(`get_producer_by_product_type`, { product_type_id: this.filterByProductType || 0 }).then((response) => {
        this.producerIsLoading = false;
        if (response.check) {
          console.log(response);
          this.producers = response.data.producers || [];
        }
      })
    },

    $route() {
      this.$global.DELETE_META();
      this.$global.ADD_META(this.$i18n.t('material comparison'), 'title');
      this.$global.ADD_META(this.$route.params.meta_name, 'title');
      this.getData();
    },
  },
  computed: {
    analysisDates() {
      if (this.from_date && this.to_date) {
        var getDaysArray = function (start, end) {
          for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
          }
          return arr;
        };
        var dayList = getDaysArray(new Date(this.from_date), new Date(this.to_date));
        var dates = dayList.map((v) => v.toISOString().slice(0, 10)).join(",");
        return dates.split(',').map(i => i);
      } else {
        return [];
      }
    },
    valid() {
      if (this.filterByProduct && this.filterByProductType && this.filterByProducer && this.filterByItem && this.filterByGovernorate && this.from_date && this.to_date) {
        return true
      }
      else {
        return false
      }
    },
    filtered_products_types() {
      return this.products_types.filter((type) => type['product_id'] == Number(this.filterByProduct))
    },
    filtered_items() {
      if (Number(this.filterByProductType)) {
        return this.items.filter((item) =>
          item['product_type_id'] == Number(this.filterByProductType)
          && item["producer_id"] == Number(this.filterByProducer)
          && item["product_id"] == Number(this.filterByProduct)
        )
      } else {
        return []
      }
    },

  },
  methods: {
    getData() {
      this.isLoading = true;
      this.breadcrumb = [
        {
          text: this.$i18n.t("cement egypt"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$route.params.meta_name.replaceAll('-', ' '),
          disabled: false,
          to: `/materials/${this.$route.params.product_id}/${this.$route.params.meta_name}`,
        },
      ];
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$api.GET_METHOD(
        `price_comparison`
      ).then((response) => {
        this.isLoading = false
        if (response.check) {
          console.log(response);

          this.products = response.data.products || [];
          this.products_types = response.data.products_types || [];
          this.producers = response.data.producers || [];
          this.items = response.data.items || [];
          this.governorates = response.data.governorates || [];

          response.data.items.forEach(item => {
            this.$global.ADD_META(item.item_name, 'keywords');
            this.$global.ADD_META(item.product_name, 'keywords');
            this.$global.ADD_META(item.producer_name, 'keywords');
            this.$global.ADD_META(item.product_type_name, 'keywords');
          });
          this.firstAd = this.$global.FilterArrayOfObject(response.data.prices_comparison_ads, 'ads_code', '05-001')[0];
          this.secondAd = this.$global.FilterArrayOfObject(response.data.prices_comparison_ads, 'ads_code', '05-002')[0];
          this.thirdAd = this.$global.FilterArrayOfObject(response.data.prices_comparison_ads, 'ads_code', '05-003')[0];
          this.fourthAd = this.$global.FilterArrayOfObject(response.data.prices_comparison_ads, 'ads_code', '05-004')[0];
          response.data.items.forEach(items => {
            this.$global.ADD_META(items.name, 'keywords');
            this.$global.ADD_META(items.producer_name, 'keywords');
            this.$global.ADD_META(items.product_name, 'keywords');
            this.$global.ADD_META(items.product_type_name, 'keywords');
          });
          response.data.items.forEach(gov => {
            this.$global.ADD_META(gov.name, 'keywords');
          });
          response.data.prices_comparison_ads.forEach(ad => {
            this.$global.ADD_META(this.$store.state.endpointURL + ad.photo, 'image');
            this.$global.ADD_META(ad.title, 'keywords');
            this.$global.ADD_META(ad.link, 'url');
          });
        } else {
          this.news = [];
          this.slider_data = [];
        }
      })
    },
    addToComparison() {
      const item = {
        item_id: this.filterByItem,
        item_name: this.$global.FilterArrayOfObjectElement(this.filtered_items, 'id', this.filterByItem, 'name'),
        gov_id: this.filterByGovernorate,
        gov_name: this.$global.FilterArrayOfObjectElement(this.governorates, 'id', this.filterByGovernorate, 'name'),
      }
      this.comparisonItems['from_date'] = this.from_date;
      this.comparisonItems['to_date'] = this.to_date;
      this.comparisonItems.items.push(item)

      this.getPricesLoading = true;
      this.getPrices();
    },
    getPrices() {
      this.getPricesLoading = true;
      this.$api.POST_METHOD(`price_comparison`, this.comparisonItems).then((response) => {
        this.getPricesLoading = false;
        if (response.check) {
          this.comparison = response.data;
          this.filterByProducer = null;
          this.filterByItem = null;
          this.filterByGovernorate = null;
          var itemName1 = this.comparisonItems.items[0] ? this.comparisonItems.items[0].item_name : ' ';
          var itemName2 = this.comparisonItems.items[1] ? this.comparisonItems.items[1].item_name : ' ';
          var itemName3 = this.comparisonItems.items[2] ? this.comparisonItems.items[2].item_name : ' ';
          var itemName4 = this.comparisonItems.items[3] ? this.comparisonItems.items[3].item_name : ' ';
          var item1 = [];
          var item2 = [];
          var item3 = [];
          var item4 = [];
          this.analysisItems = [];
          response.data.forEach((data, index) => {
            console.log(data, index);
            itemName1 = data.item1 ? data.item1.name : '';
            itemName2 = data.item2 ? data.item1.name : '';
            itemName3 = data.item3 ? data.item1.name : '';
            itemName4 = data.item4 ? data.item1.name : '';

            item1.push(data.item1 ? data.item1.mid : 0 || 0)
            item2.push(data.item2 ? data.item2.mid : 0 || 0)
            item3.push(data.item3 ? data.item3.mid : 0 || 0)
            item4.push(data.item4 ? data.item4.mid : 0 || 0)
          });
          this.analysisItems.push(
            {
              name: itemName1,
              data: item1 || [],
            },
            {
              name: itemName2,
              data: item2 || [],
            },
            {
              name: itemName3,
              data: item3 || [],
            },
            {
              name: itemName4,
              data: item4 || [],
            },
          )
          console.log('analysisItems', this.analysisItems);


          // this.analysisDates.forEach((date, index) => {
          //   console.log(date, index);
          //   if (response.data.item_price[index]) {
          //     changePrice.push(response.data.item_price[index].max_price)
          //   } else {
          //     changePrice.push(0)
          //   }
          // });



        }
      })
    },
    removeProduct(product, index) {
      if (this.comparisonItems.items.length <= 1) {
        this.comparisonItems.items = {
          from_date: null,
          to_date: null,
          items: []
        };
        this.comparison = [];
      }
      else {
        this.comparisonItems.items.splice(index, 1);
        this.comparison = [];
        this.getPrices();
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.statistic {
  // border: 0.704431px solid #c4c4c4;
  border-radius: 0;
  // width: 92.98px;
  padding: 0.5rem 0;
  text-transform: capitalize;
  text-align: center;
}
</style>
