<template>
   <v-container fluid>
      <Breadcrumb :data="breadcrumb" />
      <v-row v-if="!isLoading">
         <v-col cols="12" lg="8" md="7" sm="12">
            <v-card elevation="0" class="pa-5 shadow overflow-hidden">
               <div class="display-1 font-weight-bold gray5--text">{{ topic.title | capitalize }}</div>
               <v-img :lazy-src="$store.state.endpointURL + topic.photo" :src="$store.state.endpointURL + topic.photo"
                  :aspect-ratio="1.7" :alt="topic.title" max-height="450" contain class="my-4">
                  <template v-slot:placeholder>
                     <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                     </v-row>
                  </template>
               </v-img>
               <div class="news-description">
                  <v-row>
                     <v-col cols="auto" class="d-flex align-center">
                        <v-icon color="black" small>mdi-clock-time-eight-outline</v-icon>
                        <div class="mx-1 body-2 black--text">{{ topic.add_date }}</div>
                     </v-col>
                     <!-- <v-col cols="auto" class="d-flex align-center">
                        <v-icon color="black" small>mdi-google-maps</v-icon>
                        <div class="mx-1 body-2 black--text">Egypt - Cairo</div>
                     </v-col> -->
                     <v-col cols="auto" class="d-flex align-center">
                        <v-icon color="black" small>mdi-newspaper-variant-outline</v-icon>
                        <div class="mx-1 body-2 black--text">{{ topic.category_name | capitalize }}</div>
                     </v-col>
                     <v-col cols="auto" class="d-flex align-center">
                        <v-icon color="black" small>mdi-newspaper-variant-outline</v-icon>
                        <div class="mx-1 body-2 black--text">{{ topic.source | capitalize }}</div>
                     </v-col>
                     <v-col cols="auto" class="d-flex align-center">
                        <v-icon color="black" small>mdi-comment-text-outline</v-icon>
                        <div class="mx-1 body-2 black--text">0 {{ $t('comments') | capitalize }}</div>
                     </v-col>
                  </v-row>
                  <v-col cols="12" class="pa-0 my-3">
                     <div class="text-h5 font-weight-bold gray5--text">{{ topic.title | capitalize }}</div>
                     <v-col cols="12" sm="12">
                        <!-- <ADImg :ad="fourthAd" :maxHeight="400" :maxWidth="'auto'"  :contain="false" :aspectRatio="1280/400" classes="mt-3 rounded-lg" /> -->
                     </v-col>

                     <div class="body-1 font-weight-normal gray5--text my-3" id="topicDescription"
                        v-html="topic.description"></div>
                  </v-col>
               </div>
            </v-card>
         </v-col>
         <v-col cols="12" lg="4" md="5" sm="12">
            <!-- related topics -->
            <v-card elevation="0" class="pa-5 shadow">
               <div class="text-h5 font-weight-bold gray5--text">{{ $t('related topics') | capitalize }}</div>
               <!-- Topics -->
               <v-col cols="12" lg="12" md="12" sm="12" class="px-0 cursor_pointer"
                  v-for="(topic, index) in related_topics"
                  @click="$router.push('/news/topic/' + topic.id + '/' + topic.category_id + '/' + topic.category_name.split(/\s+/).join('-') + '/' + topic.title.split(/\s+/).join('-'))"
                  :key="index">
                  <v-row align="center">
                     <v-col cols="5">
                        <v-img :lazy-src="$store.state.endpointURL + topic.photo"
                           :src="$store.state.endpointURL + topic.photo" :aspect-ratio="1.7" :alt="topic.title"
                           class="my-4 rounded-lg" max-height="120">
                           <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                 <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                           </template>
                        </v-img>
                     </v-col>
                     <v-col cols="7">
                        <div class="mx-1 body-2 black--text">{{ topic.title | capitalize }}</div>
                        <v-col cols="auto" class="d-flex align-center pa-0">
                           <v-icon color="gray" small>mdi-clock-time-eight-outline</v-icon>
                           <div class="mx-1 body-2 gray--text">{{ topic.add_date }}</div>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center pa-0">
                           <v-icon color="gray" small>mdi-newspaper-variant-outline</v-icon>
                           <div class="mx-1 body-2 gray--text">{{ topic.source | capitalize }}</div>
                        </v-col>
                     </v-col>
                  </v-row>
               </v-col>
               <!--  -->

            </v-card>
            <!-- ADS -->
            <v-card elevation="0" class="pa-5 mt-5 shadow">
               <!-- ADS  -->
               <v-row align="center">
                  <v-col cols="12" lg="12" md="12" sm="6" class="cursor_pointer">
                     <ADImg :ad="firstAd" :maxHeight="135" :maxWidth="'auto'"  :contain="false" :aspectRatio="1280/400" classes="mt-3 rounded-lg" />
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="6" class="cursor_pointer">
                     <ADImg :ad="secondAd" :maxHeight="135" :maxWidth="'auto'"  :contain="false" :aspectRatio="1280/400" classes="mt-3 rounded-lg" />
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="6" class="cursor_pointer">
                     <ADImg :ad="thirdAd" :maxHeight="135" :maxWidth="'auto'"  :contain="false" :aspectRatio="1280/400" classes="mt-3 rounded-lg" />
                  </v-col>
               </v-row>
               <!--  -->

            </v-card>
         </v-col>
      </v-row>
      <v-row justify="center" v-if="isLoading">
         <v-col cols="auto" class="my-15">
            <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
               indeterminate>
            </v-progress-circular>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue"
// import ADImg from "@/components/ADImg.vue";
export default {
   name: 'NewsView',
   components: {
      Breadcrumb
      
   },
   mounted() {
      this.$global.DELETE_META();
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      this.getData();
   },
   watch: {
      $route() {
         this.$global.DELETE_META();
         this.getData()
      }
   },
   data: () => ({
      breadcrumb: [],
      isLoading: false,
      firstAd: {},
      secondAd: {},
      thirdAd: {},
      fourthAd: {},
      topic: {},
      related_topics: [],
   }),
   computed: {

   },
   methods: {
      getData() {
         this.isLoading = true
         this.breadcrumb = [
            {
               text: this.$i18n.t('cement egypt'),
               disabled: false,
               to: '/',
            },
            {
               text: this.$i18n.t('news'),
               disabled: false,
               to: `/news/0/1/${this.$i18n.t('all news')}`,
            },
            {
               text: this.$route.params.category_name.replaceAll('-', ' '),
               disabled: false,
               to: `/news/${this.$route.params.category_id}/1/${this.$route.params.category_name}`,
            },
            {
               text: this.$route.params.title.replaceAll('-', ' '),
               disabled: false,
               to: `/news/topic/${this.$route.params.id}/${this.$route.params.category_id}/${this.$route.params.category_name}/${this.$route.params.title}`,
            },
         ];

         window.scrollTo({
            top: 0,
            behavior: "smooth",
         });
         this.$api.GET_METHOD(`news/${this.$route.params.id}/${this.$route.params.category_id}`).then((response) => {
            this.isLoading = false
            if (response.check) {
               console.log(response);

               this.firstAd = this.$global.FilterArrayOfObject(response.data.news_ads, 'ads_code', '02-001')[0];
               this.secondAd = this.$global.FilterArrayOfObject(response.data.news_ads, 'ads_code', '02-002')[0];
               this.thirdAd = this.$global.FilterArrayOfObject(response.data.news_ads, 'ads_code', '02-003')[0];
               this.fourthAd = this.$global.FilterArrayOfObject(response.data.news_ads, 'ads_code', '02-004')[0];

               this.topic = response.data.item;
               this.related_topics = response.data.related_topics || [];

               this.$global.ADD_META(response.data.item.title, 'title');
               this.$global.ADD_META([response.data.item.title, response.data.item.category_name, response.data.item.source], 'keywords');
               this.$global.ADD_META(this.$store.state.endpointURL + response.data.item.photo, 'image');


            } else {
               this.topic = null;
               this.related_topics = [];
               this.firstAd = null;
               this.secondAd = null;
               this.thirdAd = null;
               this.fourthAd = null;
            }
         })
      }
   }
}
</script>

<style lang="scss" scoped>
.news-description {}
</style>