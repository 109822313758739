<template>
  <a :href="ad.link" target="_blank">
    <v-img :contain="contain" :lazy-src="$store.state.endpointURL + ad.photo"
      :src="$store.state.endpointURL + ad.photo" :alt="ad.title" :aspect-ratio="aspectRatio" :max-height="maxHeight" :maxWidth="maxWidth"
      :class="classes">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </a>
</template>


<script>
export default {
  name: "ADImg",
  props: {
    maxHeight: { default: 300 },
    maxWidth: { default: 500 },
    contain: { default: false },
    aspectRatio: { default: 'auto' },
    classes: { default: 'mt-3 rounded-lg' },
    ad: { default: {} },
  },
  data: () => ({

  }),
  computed: {

  },
  watch: {},
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss">

</style>
