import Vue from "vue";
import vuetify from "vuetify/lib/framework";
import ar from "../locale/ar.json";
import en from "../locale/en.json";
import '@mdi/font/css/materialdesignicons.css'; 
Vue.use(vuetify);

export default new vuetify({
  rtl: localStorage.getItem("language") == "en" ? false : true,
  lang: {
    locales: { en, ar },
    current: localStorage.getItem("language") == "en" ? en : ar,
    //  current: "ar",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: localStorage.getItem("darkTheme") == "true" ? true : false,
    customProperties: true,
    themes: {
      light: {
        primary: "#109cf1",
        secondary: "#336cfb",
        accent: "#334d6e",
        error: "#ff0000",
        info: "#192a3e",
        success: "#2ed47a",
        warning: "#ffb946",
        backgroundD: "#1e1e1e",
        backgroundW: "#fff",
        light: "#f5f6f8",
        offWhite: "#f0ebe5",
        // prototype colors:
        lightBlue: "#eaf2f9",
        babyBlue: "#D3E4F4",
        accentBlue: "#109cf1",
        midDarkBlue: "#0a58a0",
        yellow: "#ffb946",
        red: "#f7685b",
        green: "#2ed47a",
        purple: "#885af8",
        black: "#192a3e",
        tableBlack: "#323c47",
        tableGray: "#707683",
        bgGray: "#f5f6f8",
        darkBlue: "#334d6e",
        gray: "#90a0b7",
        gray5: "#615E69",
        iconGray: "#c2cfe0",
        blue: "#0030CC",
      },
      dark: {
        primary: "#0A6ADA",
        secondary: "#616161",
        accent: "#C79903",
        error: "#FF4141",
        info: "#2196F3",
        success: "#047804",
        warning: "#FB8C00",
        backgroundD: "#fff",
        backgroundW: "#1e1e1e",

        // prototype colors:
        accentBlue: "#109cf1",
        midDarkBlue: "#0a58a0",
        yellow: "#ffb946",
        red: "#f7685b",
        green: "#2ed47a",
        purple: "#885af8",
        black: "#192a3e",
        tableBlack: "#323c47",
        tableGray: "#707683",
        bgGray: "#f5f6f8",
        darkBlue: "#334d6e",
        gray: "#90a0b7",
        gray5: "#615E69",
        iconGray: "#c2cfe0",
        blue: "#0030CC",
      },
    },
  },
});
