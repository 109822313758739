<template>
  <v-container fluid class="pa-0 white" style="height: 100vh">
    <v-container class="pt-0">
      <v-col cols="11" md="6" class="d-block ma-auto py-0">
        <v-alert
          dense
          dismissible
          type="info"
          text
          icon="mdi-information-outline"
          class="rounded-0"
        >
          <div class="body-1 gray5--text font-weight-bold">
            {{ $t("Please Cheaking your mail Active Your Account") }}
          </div>
          <small class="gray5--text">
            {{ $t("If you have not have received any massage click on") }}
            <v-btn text class="text-decoration-underline pa-0" color="primary" small>{{
              $t("resend it")
            }}</v-btn></small
          >
        </v-alert>
      </v-col>

      <v-row align="center">
        <v-col cols="12" lg="3" md="auto" sm="4">
          <h1 class="font-weight-black text-center text-sm-start">
            {{ $t("Register") }}
          </h1>
        </v-col>
        <v-col cols="12" lg="6" md="8" sm="8">
          <v-stepper v-model="steps" color="gray" elevation="0" alt-labels>
            <v-stepper-header>
              <v-stepper-step complete step="1">{{ $t("Register") }}</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2" class="gray5--text">{{
                $t("Add Payment Method")
              }}</v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-col>
      </v-row>
      <v-col cols="11" md="6" class="d-block ma-auto py-0">
        <p class="ma-0 pa-0 text-center font-weight-black body-2">
          {{ $t("You Can Cancel It any time") }}
        </p>
      </v-col>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="7">
          <v-card elevation="0" class="mt-5 py-5" style="width: 100%">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="11" lg="8" md="10" class="py-0">
                  <v-text-field
                    :label="$i18n.t('first name')"
                    type="text"
                    outlined
                    @click="activeInput = 1"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="11" lg="8" md="10" class="py-0">
                  <v-text-field
                    :label="$i18n.t('last Name')"
                    @click="activeInput = 1"
                    type="text"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="11" lg="8" md="10" class="py-0">
                  <v-text-field
                    :label="$i18n.t('Card number')"
                    type="number"
                    @click="activeInput = 2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="11" lg="8" md="10" class="py-0">
                  <v-text-field
                    :label="$i18n.t('Expiration date (MM/YY)')"
                    type="number"
                    @click="activeInput = 3"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="11" lg="8" md="10" class="py-0">
                  <v-text-field
                    :label="$i18n.t('Security code (CVV)')"
                    type="number"
                    @click="activeInput = 4"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="11" lg="8" md="10" class="py-0">
                  <v-btn
                    depressed
                    color="primary"
                    block
                    @click="done"
                    >{{ $t("Add And Continue") }}</v-btn
                  >
                </v-col>
                <v-col cols="11" lg="8" md="10" class="relative">
                  <v-divider class="ma-5 mx-0"></v-divider>
                  <p class="or white">
                    {{ $t("or") }}
                  </p>
                </v-col>
                <v-col cols="11" lg="8" md="10" class="py-0">
                  <v-btn text color="primary" block @click="$router.push('/')">{{
                    $t("Continue with free account")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" sm="7">
          <img
            src="../../assets/svg/credit_card1.svg"
            class="d-block ma-auto"
            alt="cement egypt"
            v-if="activeInput == 1"
          />
          <img
            src="../../assets/svg/credit_card2.svg"
            class="d-block ma-auto"
            alt="cement egypt"
            v-if="activeInput == 2"
          />
          <img
            src="../../assets/svg/credit_card3.svg"
            class="d-block ma-auto"
            alt="cement egypt"
            v-if="activeInput == 3"
          />
          <img
            src="../../assets/svg/credit_card4.svg"
            class="d-block ma-auto"
            style="max-height: 450px"
            alt="cement egypt"
            v-if="activeInput == 4"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "RegisterPageStep2",
  components: {},
  data: () => ({
    loading: false,
    steps: 2,
    activeInput: 1,
  }),
  methods: {
    done() {
      this.$global.ShowAlert(
        "success",
        this.$i18n.t("subscription done"),
        false,
        "",
        3000
      );
      this.$router.push('/')
    },
  },
};
</script>

<style lang="scss" scoped>
.or {
  position: absolute;
  left: 46%;
  right: 46%;
  top: 1.2rem;
  width: 40px;
  text-align: center;
  font-weight: bold;
}
</style>
