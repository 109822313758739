<template>
   <v-card elevation="0" class="rounded-lg mt-2 pa-5">
      <div class="row align-center justify-space-between pa-4">
         <a class="text-h6 font-weight-medium text-black" :href="link" >
            {{ $t(title) | capitalize }}
         </a>
         <div class="carousel-arrows  col-auto mi-start-auto">
            <v-row justify="space-around">
               <v-btn small fab class="mx-2 shadow" @click="model--">
                  <v-icon color="black">mdi-chevron-left</v-icon>
               </v-btn>
               <v-btn small fab class="mx-2 shadow" @click="model++">
                  <v-icon color="black">mdi-chevron-right</v-icon>
               </v-btn>
            </v-row>
         </div>
      </div>

      <v-carousel height="200" class="carousel mt-5" hide-delimiters :show-arrows="false" v-model="model" cycle :reverse="reverse" :interval="15000">
         <template v-for="(topic, index) in data">
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
               <v-row justify="start" style="height:100%">
                  <template v-for="(n, i) in columns">
                     <template v-if="(+index + i) < data.length">
                        <v-col :key="i">
                           <v-sheet v-if="(+index + i) < data.length" height="100%" max-width="355" class="ma-auto">
                              <div
                                 @click="$router.push('/news/topic/' + data[+index + i].id + '/' + data[+index + i].category_id + '/' + data[+index + i].category_name.split(/\s+/).join('-') + '/' + data[+index + i].title.split(/\s+/).join('-'))"
                                 class="cursor_pointer relative">
                                 <v-img :lazy-src="$store.state.endpointURL + data[+index + i].photo" contain
                                    :src="$store.state.endpointURL + data[+index + i].photo" :aspect-ratio="'auto'"
                                    v-bind:alt="data[+index + i].title" :max-width="355" class="d-block ma-auto">
                                    <template v-slot:placeholder>
                                       <v-row class="fill-height ma-0" align="center" justify="center">
                                          <v-progress-circular indeterminate color="grey lighten-5">
                                          </v-progress-circular>
                                       </v-row>
                                    </template>
                                 </v-img>
                                 <div class="carousel-topic-img-filter"></div>
                                 <div class="carousel-topic">
                                    <div class="subtitle-2 font-weight-bold carousel-topic-title">{{ data[+index +
                                          i].title
                                    }}</div>
                                 </div>
                              </div>
                           </v-sheet>
                        </v-col>
                     </template>
                  </template>
               </v-row>
            </v-carousel-item>
         </template>
      </v-carousel>
   </v-card>
</template>

<script>

export default {
   name: 'NewsRow',
   props: {
      // backValueMethod: { type: Function },
      isLoading: { default: false },
      reverse: { default: true },
      data: { default: [] },
      title: { default: '' },
      link: { default: '' },
   },
   components: {

   },
   data: () => ({
      model: 0,

   }),
   computed: {
      columns() {
         if (this.$vuetify.breakpoint.xl) {
            return 4;
         }

         if (this.$vuetify.breakpoint.lg) {
            return 4;
         }

         if (this.$vuetify.breakpoint.md) {
            return 2;
         }

         return 1;
      }
   },
   methods: {

   }
}
</script>

<style lang="scss" scoped>
.text-black{
   color: black !important;
}
.carousel {
   position: relative;

   .carousel-topic-img-filter {
      background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%);
      height: 100%;
      width: 355px;
      position: absolute;
      z-index: 0;
      top: 0;
      transition: 0.35s;
   }

   &:hover {
      // .carousel-topic-img-filter {
      //    background: linear-gradient(
      //       to bottom,
      //       rgba(0, 0, 0, 0) 0%,
      //       rgba(0, 0, 0, 0.65) 100%
      //    );
      // }
   }
   
   .carousel-topic {
      position: absolute;
      top: 7.5rem;
      color: #fff;
      padding: 1rem;

      .carousel-topic-title {
         display: -webkit-box;
         overflow: hidden;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
         overflow: hidden;
      }
   }
}
</style>