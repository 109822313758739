<template>
  <v-container fluid style="height: 100vh" class="d-flex  pa-16 pt-5 bgError">
    <div>
      <v-row align="center" justify="space-between">
        <!-- <v-col cols="6" class="position-relative my-15" style="z-index: 5;">
            <AnimatedLogo />
        </v-col> -->
        <v-col cols="12" class="position-relative" style="z-index: 5;">
          <div class="text-h1 font-weight-bold gray--text mt-15" style="opacity:0.25">{{ $t("error 404") | capitalize }}
          </div>
        </v-col>
        <v-col cols="12" class="position-relative" style="z-index: 5;">
          <div class="text-h3 font-weight-bold midDarkBlue--text mb-5">
            {{ $t("oops , This page doesn't exist") }}</div>
          <v-btn color="primary" @click="$router.go(-1)" class="mt-2 rounded-xl" elevation="0">
            {{ $t('back previous page') }}
          </v-btn>
        </v-col>

        <img src="@/assets/svg/errorBg.svg" :style="$vuetify.rtl == true? 'transform: scaleX(-1)' : ''" id="errorImg" :alt="$i18n.t('cement egypt')" />

      </v-row>
    </div>
  </v-container>
</template>

<script>
// import AnimatedLogo from '@/components/AnimatedLogo.vue';
export default {
    name: "Error404",
    computed: {},
    components: {
      //  AnimatedLogo 
       }
};
</script>

<style scoped lang="scss">
//.bgError {
//  background-image: url("../../assets/svg/errorBg.svg");
//  background-attachment: inherit;
//  background-repeat: no-repeat;
//  background-position: 50%;
//  background-size: cover;
//  img {
//    margin: auto;
//    display: block;
//    max-height: 200px;
//  }
//}
#errorImg {
  position: absolute;
  bottom: -50px;
  //bottom: -70px;
  left: 0;
  right: 0;
  width: 100%;
 // width: 90%;
  margin-inline-start: auto;
  //max-height: 400px;
}
</style>