<template>
   <v-container fluid style="min-height: 100vh;">
      <Breadcrumb :data="breadcrumb" />
      <v-row class="mt-3" v-if="!isLoading">
         <!-- partners List -->

         <v-col cols="12" xl="3" lg="4" sm="6" v-for="(partner, index) in partners" :key="index">
            <div class="partner cursor_pointer white shadow"
               @click="$router.push('/partners/'  + $route.params.meta_name + '/'+ $route.params.id +'/' + partner.name.split(/\s+/).join('-') +'/' +  partner.id)" >
               <!-- <img :src="$store.state.endpointURL + partner.photo" :alt="partner.name" /> -->
               <v-img :src="$store.state.endpointURL + partner.photo" lazy-src="@/assets/icon.png"
               :alt="partner.name" aspect-ratio="auto" height="100" contain />
               <div class="subtitle-1 font-weight-black text-center">{{ $t(partner.name) }}</div>
            </div>
         </v-col>

         <!-- --------------------------------------------------------------------------------------------- -->
      </v-row>

      <v-row justify="center" v-if="isLoading">
         <v-col cols="auto" class="my-15">
            <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
               indeterminate>
            </v-progress-circular>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
   name: 'PartnersList',
   components: {
      Breadcrumb,
   },
   mounted() {
      this.$global.DELETE_META();
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      this.getData();
   },
   watch: {
      $route() {
         this.$global.DELETE_META();
         this.page = this.$route.params.page;
         this.$global.ADD_META(this.$route.params.meta_name, 'title');
         this.getData();
      }
   },
   data: () => ({
      breadcrumb: [],
      partners: [],
      isLoading: false
   }),
   computed: {

   },
   methods: {
      getData() {
         this.isLoading = true
         this.breadcrumb = [
            {
               text: this.$i18n.t('cement egypt'),
               disabled: false,
               to: '/',
            },
            {
               text: this.$route.params.meta_name.replaceAll('-',' '),
               disabled: true,
               to: `/partners/${this.$route.params.meta_name}/${this.$route.params.id}`,
            },
         ];
         this.$api.GET_METHOD(`profile?profile_type_id=${Number(this.$route.params.id) || ''}`).then((response) => {
            this.isLoading = false
            if (response.check) {
               console.log(response);
               this.partners = response.data.items || [];
               this.partners.forEach(item => {
                  this.$global.ADD_META(item.name_en);
                  this.$global.ADD_META(item.name_ar);
                  this.$global.ADD_META(item.description_en);
                  this.$global.ADD_META(item.description_ar);
                  this.$global.ADD_META(item.profile_type_name , 'keywords');
                  this.$global.ADD_META(this.$store.state.endpointURL + item.photo , 'image');
               });
               // response.data.profile_ads.forEach(ad => {
                  //  this.$global.ADD_META(this.$store.state.endpointURL + ad.photo, 'image');
               //    this.$global.ADD_META(ad.title, 'keywords');
               //    this.$global.ADD_META(ad.link, 'url');
               // });
               // this.firstAd = this.$global.FilterArrayOfObject(response.data.home_ads, 'ads_code', '01-007')[0];
            } else {
               this.partners = [];
            }
         })
      },
   }
}
</script>

<style lang="scss" scoped>
.partner {
   border: 0.704431px solid #c4c4c4;
   border-radius: 12.6798px;
   // width: 92.98px;
   padding: 1rem;
   text-transform: capitalize;
   text-align: center;

   img {
      height: 61.99px;
      // transform: scale(1.4, 1.4);
   }
}
</style>