<template>
   <v-container fluid>
      <Breadcrumb :data="breadcrumb" />
      <v-row v-if="!isLoading" align="center">
         <!-- News Carousel -->

         <!-- End Material prices -->
         <!-- --------------------------------------------------------------------------------------------- -->

         <!-- List of news -->
         <v-col v-for="(topic, index) in news" :key="index" cols="12" :lg="sizes[index].lg" :md="sizes[index].md"
            :sm="sizes[index].sm">
            <div
               @click="$router.push('/news/topic/' + topic.id + '/' + topic.category_id + '/' + topic.category_name.split(/\s+/).join('-') + '/' + topic.title.split(/\s+/).join('-'))"
               class="topic_card cursor_pointer relative">
               <v-img :lazy-src="$store.state.endpointURL + topic.photo" :src="$store.state.endpointURL + topic.photo"
                  :aspect-ratio="1.6" v-bind:alt="topic.title" :height="'auto'" max-height="300"
                  class="topic-img d-block ma-auto">
                  <template v-slot:placeholder>
                     <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                     </v-row>
                  </template>
               </v-img>
               <div class="topic-img-filter"></div>
               <div class="topic">
                  <div class="subtitle-2 font-weight-bold topic-title">{{ topic.title }}</div>
               </div>
            </div>
         </v-col>
         <!-- End News Carousel -->
         <!-- --------------------------------------------------------------------------------------------- -->

         
         <v-col cols="12" sm="12" v-if="!isLoading && news.length > 0">
            <Pagination :pagination="pagination" :limit="rowsNumber" :backValueMethod="changePage"
               :isLoading="isLoading" />
         </v-col>

      </v-row>


      <v-row justify="center" v-if="isLoading">
         <v-col cols="auto" class="my-15">
            <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
               indeterminate>
            </v-progress-circular>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
export default {
   name: 'NewsList',
   components: {
      Breadcrumb,
      Pagination
   },
   mounted() {
      this.$global.DELETE_META();
      this.$global.ADD_META(this.$route.params.category_name, 'title');
      this.page = this.$route.params.page;
      this.getData();
   },

   data: () => ({
      breadcrumb: [],
      news: [],
      sizes: [
         {
            lg: 5,
            md: 7,
            sm: 6,
         },
         {
            lg: 7,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 7,
            sm: 6,
         },
         {
            lg: 4,
            md: 7,
            sm: 6,
         },
         {
            lg: 7,
            md: 5,
            sm: 6,
         },
         {
            lg: 5,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 7,
            sm: 6,
         },
         {
            lg: 4,
            md: 7,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         // 
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
         {
            lg: 4,
            md: 5,
            sm: 6,
         },
      ],
      isLoading: false,
      slider_data: [],
      firstAd: {},
      secondAd: {},
      thirdAd: {},
      fourthAd: {},
      page: 1,
      rowsNumber: 22,
      pagination: {},
   }),
   computed: {

   },
   watch: {
      $route() {
         this.$global.DELETE_META();
         this.page = this.$route.params.page;
         this.$global.ADD_META(this.$route.params.category_name.replaceAll('-', ' '), 'title');
         this.getData();
      }
   },
   methods: {
      getData() {
         this.isLoading = true;
         this.breadcrumb = [
            {
               text: this.$i18n.t('cement egypt'),
               disabled: false,
               to: '/',
            },
            {
               text: this.$i18n.t('news'),
               disabled: false,
               to: `/news/0/1/${this.$i18n.t('all news')}`,
            },
            {
               text: this.$route.params.category_name.replaceAll('-', ' '),
               disabled: false,
               to: `/news/${this.$route.params.category_id}/${this.page}/${this.$route.params.category_name}`,
            },
         ];
         window.scrollTo({
            top: 0,
            behavior: "smooth",
         });
         this.$api.GET_METHOD(`news?rows=${this.rowsNumber}&page=${this.page || 1}&category=${Number(this.$route.params.category_id) || ''}`).then((response) => {
            this.isLoading = false
            if (response.check) {
               console.log(response);
               this.slider_data = response.data.all_sliders || [];
               this.firstAd = this.$global.FilterArrayOfObject(response.data.news_ads, 'ads_code', '02-001')[0];
               this.secondAd = this.$global.FilterArrayOfObject(response.data.news_ads, 'ads_code', '02-002')[0];
               this.thirdAd = this.$global.FilterArrayOfObject(response.data.news_ads, 'ads_code', '02-003')[0];
               this.fourthAd = this.$global.FilterArrayOfObject(response.data.news_ads, 'ads_code', '02-004')[0];

               this.news = response.data.items.data || [];
               this.page = response.data.items.current_page;
               this.pagination = {
                  page: response.data.items.current_page,
                  totalPages: response.data.items.last_page,
                  per_page: response.data.items.per_page,
                  totalRows: response.data.items.total,
               };
               this.$global.ADD_META([this.$route.params.category_name.replaceAll('-', ' '),this.$i18n.t('Cement Egypt News')], 'keywords');
            } else {
               this.news = [];
               this.slider_data = [];
            }
         })
      },
      changePage(page) {
         this.$router.push(`/news/${this.$route.params.category_id}/${page}/${this.$route.params.category_name}`);
      },
   }
}
</script>

<style lang="scss" scoped>
.topic_card {
   position: relative;
   overflow: hidden;

   .topic-img {
      transition: 0.45s;
   }

   .topic-img-filter {
      background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%);
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      transition: 0.35s;
   }

   &:hover {
      .topic-img-filter {
         background: linear-gradient(to bottom,
               rgba(0, 0, 0, 0) 0%,
               rgba(0, 0, 0, 0.65) 100%);
      }

      .topic-img {
         transform: scale(1.05, 1.05);
      }
   }

   .topic {
      position: absolute;
      bottom: 2.5rem;
      color: #fff;
      padding: 1rem;

      .topic-title {
         display: -webkit-box;
         overflow: hidden;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
         overflow: hidden;
      }
   }
}
</style>