import Vue from "vue";
import VueRouter from "vue-router";
import AccountSettingsPageVue from "@/views/Auth/AccountSettingsPage.vue";
// import BackupPaymentVue from "@/views/Auth/BackupPayment.vue";
// import CancelSubscriptionVue from "@/views/Auth/CancelSubscription.vue";
// import ChangePlanPageVue from "@/views/Auth/ChangePlanPage.vue";
import ChoosePlanPageVue from "@/views/Auth/ChoosePlanPage.vue";
import LoginPageVue from "@/views/Auth/LoginPage.vue";
import RegisterPageVue from "@/views/Auth/RegisterPage.vue";
import RegisterPageStep2Vue from "@/views/Auth/RegisterPageStep2.vue";
import PaymentStatus from "@/views/Auth/PaymentStatus.vue";
// import ChooseSubscriptionPackage from "@/views/Auth/ChooseSubscriptionPackage.vue";
import Error404Vue from "@/views/Errors/Error404.vue";
import Error500Vue from "@/views/Errors/Error500.vue";

import Home from "@/views/Home.vue";
import MainModuleVue from "@/views/MainModule.vue";
import MaterialComparisonVue from "@/views/Material/MaterialComparison.vue";
import MaterialModuleVue from "@/views/Material/MaterialModule.vue";
import MaterialPricesVue from "@/views/Material/MaterialPrices.vue";
import NewsListVue from "@/views/News/NewsList.vue";
import NewsModuleVue from "@/views/News/NewsModule.vue";
import NewsViewVue from "@/views/News/NewsView.vue";

import PartnersModuleVue from "@/views/Partners/PartnersModule.vue";

import ProducersListVue from "@/views/Producers/ProducersList.vue";
import ProducersModuleVue from "@/views/Producers/ProducersModule.vue";
import ProducerViewVue from "@/views/Producers/ProducerView.vue";
import AboutUsVue from "@/views/Society/AboutUs.vue";
import DisclaimerVue from "@/views/Society/Disclaimer.vue";
import ContactUsVue from "@/views/Society/ContactUs.vue";

import DealsListVue from "@/views/Society/DealsList.vue";
import JobsListVue from "@/views/Society/JobsList.vue";
import SocietyModuleVue from "@/views/Society/SocietyModule.vue";
import store from "@/store";
import PartnerViewVue from "@/views/Partners/PartnerView.vue";
import PartnersListVue from "@/views/Partners/PartnersList.vue";
import VueMeta from 'vue-meta'
import ForgetPasswordPageVue from "../views/Auth/ForgetPasswordPage.vue";
import ActivateAccount from "../views/Auth/ActivateAccount.vue";
Vue.use(VueRouter);
Vue.use(VueMeta)
const routes = [
  // Main Module
  {
    path: "/",
    name: "MainModule",
    component: MainModuleVue,
    redirect: "/",
    beforeEnter: (to, from, next) => {
      store.dispatch("checkAppVersion").then();
      store.dispatch("checkAuth").then();
      console.log("from path", from.path);
      console.log("to path", to.path);
      console.log("before enter page and checkAuth", store.state.isAuth);
      store.dispatch("getDefaultData").then();
      if (store.state.isAuth === true) {
        store.dispatch("getUserData").then();
        next();
      } else {
        next();
      }
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      // Partners
      {
        path: "/partners",
        name: "PartnersModuleVue",
        component: PartnersModuleVue,
        children: [
          {
            path: "/partners/:id/:meta_name",
            name: "ProducersList",
            component: PartnersListVue,
          },
          {
            path: "/partners/:meta_name/:type_id/:name/:id",
            name: "PartnerView",
            component: PartnerViewVue,
          },
        ],
      },
      // Producers & Deals
      {
        path: "/producers",
        name: "ProducersModule",
        component: ProducersModuleVue,
        redirect: "/producers",
        children: [
       
          {
            path: "/producers/:id/:meta_name",
            name: "ProducersList",
            component: ProducersListVue,
          },
          {
            path: "/producer/:id/:meta_name/:producer_id/:name",
            name: "ProducerViewVue",
            component: ProducerViewVue,
          },
        ],
      },
      // News
      {
        path: "/news",
        name: "NewsModule",
        component: NewsModuleVue,
        redirect: "/news/prices",
        children: [
          {
            path: "/news/:category_id/:page/:category_name",
            name: "NewsListVue",
            component: NewsListVue,
          },
          {
            path: "/news/topic/:id/:category_id/:category_name/:title",
            name: "NewsView",
            component: NewsViewVue,
          },
        ],
      },
      //  Material Prices
      {
        path: "/materials",
        name: "MaterialModuleVue",
        component: MaterialModuleVue,
        // redirect: "/materials/:meta_name/:product_id",
        children: [
          {
            path: "/materials/:meta_name/:product_id",
            name: "MaterialPrices",
            component: MaterialPricesVue,
          },
          {
            path: "/comparison/:meta_name",
            name: "MaterialComparison",
            component: MaterialComparisonVue,
          },
        ],
      },
      //  Society
      {
        path: "/society",
        name: "SocietyModule",
        component: SocietyModuleVue,
        redirect: "/society/about",
        children: [
          {
            path: "/society/about",
            name: "AboutUs",
            component: AboutUsVue,
          },
          {
            path: "/society/disclaimer",
            name: "Disclaimer",
            component: DisclaimerVue,
          },
          {
            path: "/society/contact-us",
            name: "ContactUs",
            component: ContactUsVue,
          },
          {
            path: "/society/jobs/:page/:meta_name",
            name: "JobsList",
            component: JobsListVue,
          },
          {
            path: "/society/deals/:page/:meta_name",
            name: "DealsList",
            component: DealsListVue,
          },

          {
            path: "*",
            name: "Error404Vue",
            component: Error404Vue,
          },
          {
            path: "/404",
            name: "Error404Vue",
            component: Error404Vue,
          },
          {
            path: "/500",
            name: "Error500",
            component: Error500Vue,
          },
        ],
      },
      //
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPageVue,
      },
      {
        path: "/forget-password",
        name: "ForgetPasswordPage",
        component: ForgetPasswordPageVue,
      },
      {
        path: "/activate-account",
        name: "ActivateAccount",
        component: ActivateAccount,
      },
      {
        path: "/sign-up",
        name: "RegisterPageVue",
        component: RegisterPageVue,
      },
      {
        path: "/sign-up/step2",
        name: "RegisterPageStep2",
        component: RegisterPageStep2Vue,
      },
      {
        path: "/subscription-packages",
        name: "ChooseSubscriptionPackage",
        component: ChoosePlanPageVue,
      },
      {
        path: "/choose-plan",
        name: "ChoosePlanPageVue",
        component: ChoosePlanPageVue,
      },
      {
        path: "/payment",
        name: "PaymentStatus",
        component: PaymentStatus,
      },
      // {
      //   path: "/subscription-packages",
      //   name: "ChangePlanPage",
      //   component: ChangePlanPageVue,
      // },
      // {
      //   path: "/add-payment",
      //   name: "BackupPayment",
      //   component: BackupPaymentVue,
      // },
      // {
      //   path: "/cancel-subscription",
      //   name: "CancelSubscription",
      //   component: CancelSubscriptionVue,
      // },
      {
        path: "/settings",
        name: "AccountSettingsPage",
        component: AccountSettingsPageVue,
      },
      // errors
      {
        path: "*",
        name: "Error404Vue",
        component: Error404Vue,
      },
      {
        path: "/404",
        name: "Error404Vue",
        component: Error404Vue,
      },
      {
        path: "/500",
        name: "Error500",
        component: Error500Vue,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
