<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center">
    <div>
      <h1 style="text-align: center; font-size:72px;">500</h1>
      <h1 style="text-align: center">Server Error</h1>
      <v-btn block to="/" class="mt-5" style="text-align: center" elevation="0" >Back Home</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Error500",
  computed: {},
};
</script>
