<template>
  <div @mouseleave="leave">
    <v-container fluid class="navigation-bar black mt-3">
      <v-container fluid class="pa-0 px-6">
        <v-row align="center" justify="space-between">
          <v-col cols="auto" class="pa-3">
            <v-row align="center">
              <v-col cols="auto">
                <v-btn depressed class="mx-2" text active-class="active-btn" to="/" @mouseover="over(0)"
                  @mousewheel="leave">
                  {{ $t("Home") }}
                  <div class="triangle"></div>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn depressed class="mx-2" text active-class="active-btn"  @mouseover="over('materials')" @mousewheel="leave">
                  {{ $t("Prices") }}
                  <div class="triangle"></div>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn depressed class="mx-2" text active-class="active-btn" :to="`/news/0/1/${$i18n.t('all news').split(/\s+/).join('-')}`"
                  @mouseover="over('news')" @mousewheel="leave">
                  {{ $t("News") }}
                  <div class="triangle"></div>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn depressed class="mx-2" text active-class="active-btn" 
                  @mouseover="over('producers')" @mousewheel="leave">
                  {{ $t("Producers") }}
                  <div class="triangle"></div>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn depressed class="mx-2" text active-class="active-btn"
                  @mouseover="over('partners')" @mousewheel="leave">
                  {{ $t("Industry Partners") }}
                  <div class="triangle"></div>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn depressed class="mx-2" text active-class="active-btn" to="/society" @mouseover="over('society')"
                  @mousewheel="leave">
                  {{ $t("Cement Egypt Society") }}
                  <div class="triangle"></div>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="auto" style="position: relative">
            <input type="text" :placeholder="$i18n.t('search')" class="navigation-search" />
            <img src="../assets/svg/search.svg" class="search-icon" alt="cement egypt" />
          </v-col> -->
        </v-row>
      </v-container>

      <!-- sub menu -->
      <div class="sub-menu" v-if="activeSubMenu !== 0" @mousewheel="leave" @mouseleave="leave">
        <v-container class="pa-0" v-if="activeSubMenu !== 0">
          <v-row align="center" justify="space-between">
            <v-col cols="auto" class="pa-3">
              <v-row align="center">
                <v-col cols="auto" v-for="(route, index) in routes[activeSubMenu]" :key="index">
                  <v-btn depressed class="submenu-links mx-2" 
                    :active-class="index == 0 ? '' : 'active-btn'" color="transparent" text :to="`/${route.link}`">{{
                        $t(route.name)
                    }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <!-- <img src="../assets/png/ads/medcom-aswan.jpg" style="max-height: 100px;" class="d-block m-auto" alt="cement egypt" /> -->
            </v-col>
          </v-row>
        </v-container>
      </div>

    </v-container>


  </div>
</template>

<script>
export default {
  name: "NavigationBar",

  data: () => ({
    activeSubMenu: 0,
    routes: {
      materials: [],
      news: [],
      producers: [],
      partners: [],
      society: [
        {
          name: "Cement Egypt Society",
          module: "society",
          link: "society",
        },
        {
          name: "Cement Egypt Jobs",
          module: "society",
          link: "society/jobs",
        },
        {
          name: "Cement Egypt Deals",
          module: "society",
          link: "society/deals",
        },
        {
          name: "About us",
          module: "society",
          link: "society/about",
        },{
          name: "Disclaimer",
          module: "society",
          link: "society/disclaimer",
        },
      ],
    },
    hover: false,
  }),
  watch: {
    '$store.state.profile_types'() {
      this.routes.partners = this.$store.state.profile_types;
    },
    '$store.state.product_types'() {
      this.routes.materials = this.$store.state.product_types;
    },
    '$store.state.producer_types'() {
      this.routes.producers = this.$store.state.producer_types;
    },
  },
  mounted() {
    this.routes.news = [
      {
        name: "all news",
        module: "news",
        link: `news/0/1/${this.$i18n.t('all news').split(/\s+/).join('-')}`,
      },
      {
        name: "general news",
        module: "news",
        link: `news/1/1/${this.$i18n.t('general news').split(/\s+/).join('-')}`,
      },
      {
        name: "cement news",
        module: "news",
        link: `news/2/1/${this.$i18n.t('cement news').split(/\s+/).join('-')}`,
      },
      {
        name: "cement news around world",
        module: "news",
        link: `news/3/1/${this.$i18n.t('cement news around world').split(/\s+/).join('-')}`,
      },
    ];

    this.routes.society = [
      // {
      //   name: "Cement Egypt Society",
      //   module: "society",
      //   link: "society",
      // },
      {
        name: "Cement Egypt Jobs",
        module: "society",
        link: `society/jobs/1/${this.$i18n.t('Cement Egypt Jobs').split(/\s+/).join('-')}`,
      },
      {
        name: "Cement Egypt Deals",
        module: "society",
        link: `society/deals/1/${this.$i18n.t('Cement Egypt Deals').split(/\s+/).join('-')}`,
      },
      {
        name: "About us",
        module: "society",
        link: `society/about`,
      },
      {
        name: "Disclaimer",
        module: "society",
        link: `society/disclaimer`,
      },
    ];
  },
  methods: {
    over(active) {
      // console.log("over");
      this.activeSubMenu = active;
    },
    leave() {
      // console.log("leave");
      this.activeSubMenu = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-bar {
  height: 48.61px;
  padding: 0;

  .col {
    padding: 0;

    .v-btn {
      height: 48.61px;
      border-radius: 0;
      color: #fff;
    }

    .active-btn {
      background: linear-gradient(249.8deg,
          #1a91cd -7.49%,
          rgba(26, 145, 205, 0.53) 138.01%);
      position: relative;

      &::after {
        background: linear-gradient(249.8deg,
            #1a91cd -7.49%,
            rgba(26, 145, 205, 0.53) 138.01%);
        content: "";
        position: absolute;
        top: -7px;
        height: 7px;
        width: 100%;
      }

      .triangle {
        width: 13px;
        height: 7px;
        position: absolute;
        top: -21px;
        right: -29px;
        background: #0e4b84;
        -webkit-clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
        clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
        opacity: 0.8;
      }
    }
  }

  .navigation-search {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    border: 0;
    width: 200px;
    padding: 0.2rem 0.8rem;
    color: #8e8c94;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: #8e8c94;
    }
  }

  .search-icon {
    position: absolute;
    right: 1rem;
    top: 0.55rem;
  }
}

.sub-menu {
  transition: 0.5s;
  display: block;
  position: relative;
  // position: fixed;
  z-index: 555;
  width: 100vw;
  min-height: 50px;
  background: #213855;
  padding: 0.73rem 0;
  top: 0.78rem;

  .submenu-links {
    color: #fff !important;
  }

  .submenu-links-active {
    color: #109cf1 !important;
  }

  .active-btn {

    &::after {
      display: none;
    }

  }
}

.v-application--is-rtl {
  .navigation-bar {
    .search-icon {
      left: 1rem;
      right: unset;
    }
  }
}
</style>
