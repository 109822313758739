<template>
  <v-container fluid>
    <Breadcrumb :data="breadcrumb" />
    <section v-if="!isLoading" class="my-5">
      <v-card class="producer shadow">
        <v-img class="white--text align-end backImg" height="350px" :src="$store.state.endpointURL + producer.photo"
          :alt="producer.name" :lazy-src="$store.state.endpointURL + producer.photo">
          <div class="backImgBlur"></div>
        </v-img>
        <v-row align="center" justify="center" style="z-index: 555" class="relative my-16">
          <v-col cols="10" lg="5" sm="5">
            <v-img class="logo" :aspect-ratio="4 / 3" height="200" :src="$store.state.endpointURL + producer.photo"
              :alt="producer.name" :lazy-src="$store.state.endpointURL + producer.photo">
            </v-img>
          </v-col>
          <v-col cols="12" lg="5">
            <v-card-title class="text-h5 white--text font-weight-bold py-2"><span class="gray--text mx-2">{{ $t("Name")
            }}: </span> {{ producer.name | capitalize }}</v-card-title>
            <!-- <v-card-title class="text-h5 white--text font-weight-bold py-2"><span class="gray--text mx-2">{{ $t("Since")
            }}: </span> 1996</v-card-title> -->
          </v-col>
        </v-row>

        <div class="mt-20 pa-5">
          <div class="text-h6 ma-0">{{ $t("About") }}</div>
          <div class="body-1" v-html="producer.description"></div>
        </div>
      </v-card>
    </section>
    <v-row justify="center" v-if="isLoading">
      <v-col cols="auto" class="my-15">
        <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "ProducerView",
  components: {
    Breadcrumb,
  },
  mounted() {
    this.$global.DELETE_META();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getData();
  },

  data: () => ({
    breadcrumb: [],
    isLoading: false,
    producer: null,
  }),
  computed: {},
  methods: {
    getData() {
      this.isLoading = true
      this.breadcrumb = [
        {
          text: this.$i18n.t("cement egypt"),
          disabled: false,
          to: "/",
        },
        {
          text: this.$route.params.meta_name,
          disabled: false,
          to: `/producers/${this.$route.params.id}/${this.$route.params.meta_name}`,
        },
        {
          text: this.$route.params.name.replaceAll('-',' '),
          disabled: true,
        },
      ];

      this.$api.GET_METHOD(`producer/${this.$route.params.producer_id}`).then((response) => {
        this.isLoading = false
        if (response.check) {
          console.log(response);
          this.producer = response.data.item || null;
          this.$global.ADD_META(this.$store.state.endpointURL +  response.data.item.photo, 'image');
          this.$global.ADD_META(response.data.item.name, 'title');
          this.$global.ADD_META(response.data.item.description, 'description_body');
          this.$global.ADD_META([response.data.item.name_ar,response.data.item.name_en] , 'keywords');
          this.$global.ADD_META(response.data.item.description_ar);
          // this.firstAd = this.$global.FilterArrayOfObject(response.data.home_ads, 'ads_code', '01-007')[0];
        }
      })
    },

  },
};
</script>

<style lang="scss" scoped>
.producer {
  position: relative;
  overflow: hidden;

  .backImg {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    position: absolute;
    width: 100%;

  }

  .logo {
    @media screen and (max-width: 1268px) {
      max-height: 120px;
    }
  }

  .backImgBlur {
    background: rgba(0, 0, 0, 0.75);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    transition: 0.35s;
  }

  &:hover {
    // .backImg {
    //    background: linear-gradient(
    //       to bottom,
    //       rgba(0, 0, 0, 0) 0%,
    //       rgba(0, 0, 0, 0.65) 100%
    //    );
    // }
  }
}
</style>
