<template>
   <v-card elevation="0" class="rounded-lg pa-2">
      <div class="d-flex align-center justify-space-between">
         <div class="subtitle-1 font-weight-medium">{{ $t('Materials prices') }}</div>
         <!-- <div class="subtitle-2 primary--text text-decoration-underline cursor_pointer">{{ $t('see more') }}</div> -->
      </div>
      <table class="col-12 py-1">
         <thead>
            <tr class="text-center">
               <th>{{ $t('Item') }}</th>
               <th>{{ $t('Top price') }}</th>
               <th>{{ $t('Under price') }}</th>
               <th>{{ $t('normal') }}</th>
            </tr>
         </thead>
         <tbody>
            <!-- cement -->
            <tr class="text-center">
               <td>{{ product_prices.cement_price.title }}</td>
               <td>{{ product_prices.cement_price.max_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
               <td>{{ product_prices.cement_price.min_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
               <td>{{ product_prices.cement_price.mid_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
            </tr>

            <tr class="text-center">
               <td>{{ product_prices.cement_price2.title }}</td>
               <td>{{ product_prices.cement_price2.max_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
               <td>{{ product_prices.cement_price2.min_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
               <td>{{ product_prices.cement_price2.mid_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
            </tr>
            <!-- metal -->
            <tr class="text-center">
               <td>{{ product_prices.iron_price.title }}</td>
               <td>{{ product_prices.iron_price.max_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
               <td>{{ product_prices.iron_price.min_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
               <td>{{ product_prices.iron_price.mid_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
            </tr>
            <!-- Gypsum -->
            <tr class="text-center">
               <td>{{ product_prices.gypsum_prices.title }}</td>
               <td>{{ product_prices.gypsum_prices.max_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
               <td>{{ product_prices.gypsum_prices.min_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
               <td>{{ product_prices.gypsum_prices.mid_price || 0 | floatTo(0) }} {{ $t('LE') }}</td>
            </tr>
            <!-- Transport -->
            
         </tbody>
      </table>
   </v-card>
</template>

<script>

export default {
   name: 'MaterialsPrices',
   props: {
      isLoading: { default: false },
      product_prices: {
         default:
         {
            cement_price: { title : "" ,max_price: 0, min_price: 0, cement_kilo: 0 },
            cement_price2: { title : "" ,max_price: 0, min_price: 0, cement_kilo: 0 },
            iron_price: { title : "" ,max_price: 0, min_price: 0, iron_kilo: 0 },
            gypsum_prices: { title : "" ,max_price: 0, min_price: 0, gypsum_kilo: 0 },
         }
      },
   },
   components: {

   },
   data: () => ({

   }),
   methods: {

   }
}
</script>

<style lang="scss" scoped>
</style>