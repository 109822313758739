<template>
  <v-navigation-drawer v-model="$store.state.sidebarIsOpen" width="300" absolute height="100vh" temporary
    :right="$vuetify.rtl == true">
    <v-list>
      <v-list-item-content color="primary">
        <v-list-item-title class="text-capitalize font-weight-bold">
          <v-btn icon @click="$store.state.sidebarIsOpen = false" depressed class="mx-2">
            <img src="../assets/svg/close.svg" alt="cement egypt" style="max-height: 30px" />
          </v-btn>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item link exact-path :to="'/'"
        :class="$store.state.Settings.isRTL == true ? 'my-1 rounded-br-pill rounded-tr-pill font-weight-bold overflow-hidden mr-2' : 'my-1 rounded-bl-pill rounded-tl-pill font-weight-bold overflow-hidden ml-2'"
        active-class="activeLink">
        <v-list-item-content>
          <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      

      <v-list-group v-for="(item ,keysIndex) in Object.keys(routes)" :key="keysIndex" color="primary">
        <template v-slot:activator>
          <v-list-item-content color="primary" v-if="routes[item][0]">
            <v-list-item-title class="text-capitalize font-weight-bold" >{{ $t(menuList[keysIndex]) }}</v-list-item-title>
            <!-- <v-list-item-title class="text-capitalize font-weight-bold" v-else>{{ $t(routes[item][0].name) }}</v-list-item-title> -->
          </v-list-item-content>
        </template>

        <v-list-item v-for="(child ,index) in routes[item]" :key="index" link exact-path :to="'/' + child.link"
          :class="$store.state.Settings.isRTL == true ? 'my-1 rounded-br-pill rounded-tr-pill  overflow-hidden mr-2' : 'my-1 rounded-bl-pill rounded-tl-pill  overflow-hidden ml-2'"
          active-class="activeLink">
          <v-list-item-content>
            <v-list-item-title>{{ $t(child.name) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item link exact-path :to="$store.state.isAuth == false ? '/login' : '/subscription-packages'"
        :class="$store.state.Settings.isRTL == true ? 'my-1 rounded-br-pill rounded-tr-pill font-weight-bold overflow-hidden mr-2' : 'my-1 rounded-bl-pill rounded-tl-pill font-weight-bold overflow-hidden ml-2'"
        active-class="activeLink">
        <v-list-item-content>
          <v-list-item-title>{{ $t("Subscription") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link exact-path :to="'/contact-us'"
        :class="$store.state.Settings.isRTL == true ? 'my-1 rounded-br-pill rounded-tr-pill font-weight-bold overflow-hidden mr-2' : 'my-1 rounded-bl-pill rounded-tl-pill font-weight-bold overflow-hidden ml-2'"
        active-class="activeLink">
        <v-list-item-content>
          <v-list-item-title>{{ $t("Contact us") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:prepend>
      <div class="shadow" v-if="$store.state.isAuth == true">
        <v-list-item>
          <v-list-item-avatar size="45">
            <v-img v-if="$store.state.userData.image" :alt="`cement egypt`"
              :src="$store.state.endpointURL + $store.state.userData.image"></v-img>
            <v-img v-else :alt="`cement egypt`" src="../assets/svg/user.svg"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="darkBlue--text font-weight-black">
              {{ $store.state.userData.username }}
            </v-list-item-title>

            <div class="d-flex align-center mt-2">
              <p class="black--text pa-0 ma-0">{{ $t("Your plan") }}</p>
              <!-- <v-badge color="yellow" overlap inline :content="$i18n.t('Free')">
              </v-badge>-->

              <v-btn depressed class="mx-1 yellow--text rounded-pill" color="#FFFCEE" to="/sign-up" small>{{ $t("Free")
              }}</v-btn>
            </div>
          </v-list-item-content>

          <v-list-item-icon>
            <img src="../assets/svg/editAccount.svg" @click="$router.push('/settings')" to="/settings" height="27"
              alt="cement egypt" />
          </v-list-item-icon>
        </v-list-item>
      </div>
      <div class="shadow" v-if="$store.state.isAuth == false">
        <v-list-item>
          <!-- <v-list-item-avatar size="45">
            <v-img :alt="`cement egypt`" src="../assets/svg/user.svg"></v-img>
          </v-list-item-avatar> -->

          <v-list-item-content>
            <!-- <v-list-item-title class="darkBlue--text font-weight-black">{{$t('User Account')}}</v-list-item-title> -->

            <div class="d-flex mt-2">
              <v-btn depressed class="mx-1 primary--text rounded-pill" color="#E6ECFF" to="/sign-up">{{ $t("Register")
              }}</v-btn>
              <v-btn depressed class="mx-1 primary--text rounded-pill text-decoration-underline" color="white"
                to="/login">{{ $t("Login") }}</v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavigationDrawer",

  data: () => ({
    menuList: ["Prices", "News", "Producers", "Industry Partners", "Cement Egypt Society"],
    routes: {
      materials: [],
      news: [],
      producers: [
        {
          name: "Producers",
          module: "producers",
          link: "producers",
        },
      ],
      partners: [],
      society: [],
    },
  }),
  methods: {},
    watch: {
    '$store.state.profile_types'() {
      this.routes.partners = this.$store.state.profile_types;
    },
    '$store.state.product_types'() {
      this.routes.materials = this.$store.state.product_types;
    },

    '$store.state.producer_types'() {
      this.routes.producers = this.$store.state.producer_types;
    },
  },
  mounted() {
    this.routes.news = [
      {
        name: "all news",
        module: "news",
        link: `news/0/1/${this.$i18n.t('all news').split(/\s+/).join('-')}`,
      },
      {
        name: "general news",
        module: "news",
        link: `news/1/1/${this.$i18n.t('general news').split(/\s+/).join('-')}`,
      },
      {
        name: "cement news",
        module: "news",
        link: `news/2/1/${this.$i18n.t('cement news').split(/\s+/).join('-')}`,
      },
      {
        name: "cement news around world",
        module: "news",
        link: `news/3/1/${this.$i18n.t('cement news around world').split(/\s+/).join('-')}`,
      },
    ];
    this.routes.society = [
      {
        name: "Cement Egypt Society",
        module: "society",
        link: "society",
      },
      {
        name: "Cement Egypt Jobs",
        module: "society",
        link: `society/jobs/1/${this.$i18n.t('Cement Egypt Jobs').split(/\s+/).join('-')}`,
      },
      {
        name: "Cement Egypt Deals",
        module: "society",
        link: `society/deals/1/${this.$i18n.t('Cement Egypt Deals').split(/\s+/).join('-')}`,
      },
      {
        name: "About us",
        module: "society",
        link: `society/about`,
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
.activeLink {
  font-weight: bold;
  background: #e6ecff !important;
  color: #0030cc !important;
  // background: red;
}
</style>
