<template>
  <v-container fluid class="">
    <v-row align="stretch">
      <v-col cols="12" md="6" sm="5" order="2" order-sm="1" class="pa-0 d-none d-sm-block">
        <v-img src="@/assets/svg/cementBg.svg" lazy-src="@/assets/svg/cementBg.svg" max-height="80vh"
          class="d-flex align-center justify-center">
          <img src="@/assets/svg/white_logo.svg" class="d-block ma-auto" alt="cement egypt" style="max-height: 80px" />
        </v-img>
      </v-col>
      <v-col cols="12" md="6" sm="7" order="1" order-sm="2" class="white d-flex align-center" style="max-height: 100vh">
        <v-card elevation="0" class="pa-5 white" style="width: 100%">
          <v-form ref="form" v-model="valid" lazy-validation>

            <h1 class="font-weight-black my-8" v-if="activeStep == 1">{{ $t("Forget Password") | capitalize }}</h1>
            <h1 class="font-weight-black my-8" v-if="activeStep == 2">{{ $t("Enter confirmation code") | capitalize }}
            </h1>
            <h1 class="font-weight-black my-8" v-if="activeStep == 3">{{ $t("enter new password") | capitalize }}</h1>

            <v-row justify="center">

              <v-col cols="11" lg="8" md="10" class="py-5" v-if="activeStep == 1">
                <v-text-field persistent-placeholder name="email" placeholder=" " :label="$i18n.t('email')" type="email"
                  :rules="$store.state.Settings.emailRule" outlined v-model="form.email"></v-text-field>
              </v-col>


              <v-col cols="11" lg="8" md="10" v-if="activeStep == 1">
                <v-btn outlined block color="primary" x-large :loading="loading" :disabled="!(form.email)"
                  @click="sendEmail">
                  {{ $t("next") }}
                </v-btn>
              </v-col>


              <v-col cols="11" lg="8" md="10" class="py-5" v-if="activeStep == 2">
                <v-otp-input ref="otpInput" length="5" input-classes="otp-input" separator=" " v-model="otp"
                  :num-inputs="5" :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange"
                  @on-complete="handleOnComplete" />
              </v-col>

              <v-col cols="11" lg="8" md="10" v-if="activeStep == 2">
                <v-btn outlined block color="primary" x-large :loading="loading" :disabled="!(otp.length == 5)"
                  @click="handleOnComplete">
                  {{ $t("next") }}
                </v-btn>
              </v-col>

              <v-col cols="11" lg="8" md="10" class="py-5" v-if="activeStep == 3">
                <v-text-field persistent-placeholder placeholder=" " name="password" :label="$i18n.t('new password')"
                  outlined required :rules="$store.state.Settings.passwordRule" v-model="form.new_password"
                  :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
                  @click:append="show = !show"></v-text-field>
              </v-col>
              <v-col cols="11" lg="8" md="10" class="py-5" v-if="activeStep == 3">
                <v-text-field persistent-placeholder placeholder=" " name="confirm_password"
                  :label="$i18n.t('confirm password')" outlined required :rules="confirmPasswordRule"
                  v-model="form.confirm_password"
                  :append-icon="showConfPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="showConfPass ? 'text' : 'password'" @click:append="showConfPass = !showConfPass">
                </v-text-field>
              </v-col>

              <v-col cols="11" lg="8" md="10" v-if="activeStep == 3">
                <v-btn outlined block color="primary" x-large :loading="loading"
                  :disabled="!(form.confirm_password == form.new_password && valid)" @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-col>


              <v-snackbar multi-line v-model="snackbar" :timeout="2000">
                <div class="body-1" v-for="(error, index) in errors" :key="index">{{ error }}</div>
                <template v-slot:action="{ attrs }">
                  <v-btn color="error" text v-bind="attrs" @click="snackbar = false">
                    {{ $t('hide') }}
                  </v-btn>
                </template>
              </v-snackbar>

            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ForgetPasswordPage",
  components: {},
  data: () => ({
    valid: false,
    show: false,
    loading: false,
    snackbar: false,
    errors: [],
    activeStep: 1,
    otp: "",
    showNewPass: false,
    showConfPass: false,
    showCrrPass: false,
    passwordRule: [],
    confirmPasswordRule: [],
    form: {
      email: null,
      password: null,
      new_password: null,
      confirm_password: null,
    },
  }),
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.$refs.form.validate();
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version);
    this.passwordRule = [
      (v) => !!v || this.$t("Password is required"),
      (v) =>
        (v && v.length >= 5) || this.$t("Password must be greater than 5 characters"),
    ];
    this.confirmPasswordRule = [
      (v) => !!v || this.$t("Password is required"),
      (v) => (v && v == this.form.new_password) || this.$t("Password not match"),
    ];
  },
  methods: {
    sendEmail() {
      this.loading = true;
      const requestBody = {
        email: this.form.email,
      }
      this.$api.POST_METHOD('forget_password', requestBody).then((response) => {
        this.loading = false;
        if (response.check) {
          this.activeStep = 2;
        } else {
          if (response.data) {
            Object.keys(response.data).forEach(errorKey => {
              this.errors.push(response.data[errorKey][0])
            });
            this.snackbar = true;
          } else {
            this.errors.push(response.msg || 'error')
            this.snackbar = true
          }
        }
      })
    },

    handleOnComplete(value) {
      console.log(value);
      this.activeStep = 3;
      // this.loading = true;
      // const requestBody = {
      //   email: this.form.email,
      //   otp: value,
      // }
      // this.$api.POST_METHOD('check_otp', requestBody).then((response) => {
      //   this.loading = false;
      //   if (response.check) {
      //     this.activeStep = 3;
      //   }
      // })
    },
    handleOnChange(value) {
      this.otp = value;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    save() {
      this.loading = true;
      const requestBody = {
        email: this.form.email,
        otp_token: this.otp,
        new_password: this.form.new_password,
        confirm_password: this.form.confirm_password,
      }
      this.$api.POST_METHOD('change_password', requestBody).then((response) => {
        this.loading = false;
        if (response.check) {
          // this.$api.SET_AUTH(response.data.data)
          this.errors.push(response.msg || 'info')
          this.snackbar = true;
          this.$router.push("/login");
        } else {
          if (response.data) {
            Object.keys(response.data).forEach(errorKey => {
              this.errors.push(response.data[errorKey][0])
            });
            this.snackbar = true;
          } else {
            this.errors.push(response.msg || 'error')
            this.snackbar = true
          }
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
