<template>
  <v-container fluid class="">
    <v-row align="stretch">
      <v-col cols="12" md="6" sm="5" order="2" order-sm="1" class="pa-0 d-none d-sm-block">
        <v-img src="@/assets/svg/cementBg.svg" lazy-src="@/assets/svg/cementBg.svg" max-height="80vh"
          class="d-flex align-center justify-center">
          <img src="@/assets/svg/white_logo.svg" class="d-block ma-auto" alt="cement egypt" style="max-height: 80px" />
        </v-img>
      </v-col>
      <v-col cols="12" md="6" sm="7" order="1" order-sm="2" class="white d-flex align-center" style="max-height: 100vh">
        <v-card elevation="0" class="pa-5 white" style="width: 100%">
          <v-form ref="form" v-model="valid" lazy-validation>

            <h1 class="font-weight-black my-8">{{ $t("Activate Account") | capitalize }}</h1>


            <v-row justify="center">

              <v-col cols="11" lg="8" md="10" class="py-5">
                <v-text-field persistent-placeholder name="email" placeholder=" " :label="$i18n.t('email')" type="email"
                  :rules="$store.state.Settings.emailRule" outlined v-model="form.email"></v-text-field>
              </v-col>


              <v-col cols="11" lg="8" md="10" class="py-5">
              <label>{{$t('Enter activation code')}}</label>
                <v-otp-input ref="otpInput" length="5" input-classes="otp-input" separator=" " v-model="otp"
                  :num-inputs="5" :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange"
                  @on-complete="handleOnComplete" />
              </v-col>

              
              <v-col cols="11" lg="8" md="10">
                <v-btn outlined block color="primary" x-large :loading="loading" :disabled="!(otp.length == 5&& form.email)"
                  @click="save">
                  {{ $t("activate") }}
                </v-btn>
              </v-col>


              <v-snackbar multi-line v-model="snackbar" :timeout="2000">
                <div class="body-1" v-for="(error, index) in errors" :key="index">{{ error }}</div>
                <template v-slot:action="{ attrs }">
                  <v-btn color="error" text v-bind="attrs" @click="snackbar = false">
                    {{ $t('hide') }}
                  </v-btn>
                </template>
              </v-snackbar>

            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ActivateAccount",
  components: {},
  data: () => ({
    valid: false,
    show: false,
    loading: false,
    snackbar: false,
    errors: [],
    otp: "",
    form: {
      email: null,
      otp: null,
    },
  }),
  mounted() {
    this.form.email = this.$route.params.email || null;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.$refs.form.validate();

  },
  methods: {
    sendEmail() {
      this.loading = true;
      const requestBody = {
        email: this.form.email,
      }
      this.$api.POST_METHOD('forget_password', requestBody).then((response) => {
        this.loading = false;
        if (response.check) {
          this.activeStep = 2;
        }
      })
    },

    handleOnComplete() {
  
    },
    handleOnChange(value) {
      this.otp = value;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    save() {
      this.loading = true;
      const requestBody = {
        email: this.form.email,
        otp_token: this.otp,
      }
      this.$api.POST_METHOD('user_activation', requestBody).then((response) => {
        this.loading = false;
        if (response.check) {
          this.$api.SET_AUTH(response.data)
          this.$router.push("/subscription-packages");
        }else {
            if (response.data) {
              Object.keys(response.data).forEach(errorKey => {
                this.errors.push(response.data[errorKey][0])
              });
              this.snackbar = true;
            } else {
              this.errors.push(response.msg || 'error')
              this.snackbar = true
            }
          }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
