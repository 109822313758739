<template>
   <v-card elevation="0" class="rounded-lg">
      <v-carousel v-model="model" hide-delimiter-background show-arrows-on-hover :height="carouselHeight" class="carousel" 
         cycle :interval="18000">
         <v-carousel-item v-for="(topic, i) in slider_data" :key="i">
            <a :href="topic.link || 'https://cementegypt.com'">
               <div class="cursor_pointer">
                  <!-- image ratio 3/2 OR  1.5 -->
                  <!-- <v-img
                  :lazy-src="$store.state.endpointURL + topic.photo"
                  :src="$store.state.endpointURL + topic.photo"
                  :aspect-ratio="'auto'"
                  v-bind:alt="topic.title" contain
               >
                  <template v-slot:placeholder>
                     <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                     </v-row>
                  </template>
               </v-img> -->
                  <div class="img-container">
                     <img :src="$store.state.endpointURL + topic.photo" :alt="topic.title" />
                  </div>
                  <div class="carousel-topic-img-filter"></div>
                  <div class="carousel-topic">
                     <div class="text-h6 font-weight-bold mb-16 carousel-topic-title">{{ topic.title }}</div>
                     <!-- <div class="carousel-topic-description">
                     <v-row>
                        <v-col cols="auto" class="d-flex align-center">
                           <v-icon dark small>mdi-clock-time-eight-outline</v-icon>
                           <div class="mx-1 body-2">17 : 35 - Egypt</div>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                           <v-icon dark small>mdi-google-maps</v-icon>
                           <div class="mx-1 body-2">Egypt - Cairo</div>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                           <v-icon dark small>mdi-newspaper-variant-outline</v-icon>
                           <div class="mx-1 body-2">AL Ahram</div>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                           <v-icon dark small>mdi-comment-text-outline</v-icon>
                           <div class="mx-1 body-2">0 Comments</div>
                        </v-col>
                     </v-row>
                  </div> -->
                  </div>
               </div>
            </a>
         </v-carousel-item>
      </v-carousel>
   </v-card>
</template>

<script>

export default {
   name: 'NewsCarousel',
   components: {

   },
   data: () => ({
      model: 0,
   }),
   props: {
      isLoading: { default: false },
      slider_data: { default: [] },
   },
   methods: {
      getImgUrl(img) {
         var images = require.context('../assets/png');
         return images('./' + img)
      }
   },
   computed: {
      carouselHeight() {
         if (this.$vuetify.breakpoint.xl) {
            return 550;
         }

         if (this.$vuetify.breakpoint.lg) {
            return 460;
         }

         if (this.$vuetify.breakpoint.md) {
            return 400;
         }
         if (this.$vuetify.breakpoint.sm) {
            return 310;
         }
         if (this.$vuetify.breakpoint.xs) {
            return 200;
         }

         return 200;
      }
   },
}
</script>

<style lang="scss" scoped>
.carousel {
   .img-container {
      height: 100%;
      width: 100%;
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;

      img {
         object-fit: contain;
         max-width: 100%;
         max-height: 100%;
         width: 100%;
         height: auto;
      }
   }

   .carousel-topic-img-filter {
      background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%);
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      transition: 0.35s;
   }

   &:hover {
      .carousel-topic-img-filter {
         background: linear-gradient(to bottom,
               rgba(0, 0, 0, 0) 0%,
               rgba(0, 0, 0, 0.65) 100%);
      }
   }

   .carousel-topic {
      position: absolute;
      bottom: 0;
      color: #fff;
      padding: 1rem 2.5rem;

      .carousel-topic-title {
         display: -webkit-box;
         overflow: hidden;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
      }

      .carousel-topic-description {
         display: flex;
         margin-top: 2rem;

         @media screen and (max-width: 540px) {
            display: none;
         }
      }

      .carousel-topic-dots {
         display: flex;
         align-items: center;
         justify-content: center;

         div {
            background: #fff;
            opacity: 0.5;
            width: 6.34px;
            height: 6.34px;
            margin: 0 0.3rem;
            border-radius: 25px;
            transition: 0.35s;
            position: relative;

            &:hover {
               opacity: 1;
            }

            &.active {
               width: 14.79px;
               opacity: 1;
            }
         }
      }
   }
}
</style>