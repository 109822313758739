<template>
  <section>
    <v-container class="my-10 py-5">
      <v-row>
        <v-col cols="12" lg="4" md="6">


          <v-card class="mt-2 shadow" tile>
            <v-list>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item active-class="font-weight-bold" color="gray5" :value="1">
                  <v-list-item-icon>
                    <v-icon>mdi-account-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Profile') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item active-class="font-weight-bold" color="gray5" :value="2">
                  <v-list-item-icon>
                    <v-icon>mdi-briefcase-variant-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('subscriptions') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item color="red" @click="$api.LOGOUT(true)" :value="3">
                  <v-list-item-icon>
                    <v-icon color="red">'mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="red--text">{{ $t('Log Out') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" lg="8" md="6">
          <h3 class="gray5--text">{{ $t("Account Setting") }}</h3>
          <!-- Profile -->
          <v-card class="mt-2 shadow pa-5" v-if="selectedItem == 1">
            <v-list-item>
              <v-list-item-avatar size="62">
                <v-img :alt="$store.state.userData.full_name || 'cement egypt'" v-if="$store.state.userData.image"
                  :src="$store.state.endpointURL + $store.state.userData.image"></v-img>
                <v-img :alt="$store.state.userData.full_name || 'cement egypt'" v-else src="@/assets/svg/user.svg">
                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-black">
                  {{ $store.state.userData.full_name || '-' }}
                </v-list-item-title>
                <p class="gray--text"> {{ $store.state.userData.email || '-' }}</p>
                <p class="gray--text"> {{ $store.state.userData.mobile || '-' }}</p>
              </v-list-item-content>

              <v-list-item-icon v-if="editIsOn == false" @click="editIsOn = true">
                <img src="@/assets/svg/editAccount.svg" height="40" alt="cement egypt" />
              </v-list-item-icon>
            </v-list-item>

            <div v-if="editIsOn == true">
              <v-divider class="mx-1" style="opacity: 0.7"></v-divider>
              <v-form ref="form" v-model="valid" class="col-lg-8 col-md-9 col-sm-10 pa-5">
                <v-row align="center">
                  <v-col cols="4">
                    <label class="gray5--text font-weight-bold">{{ $t("full name") }}</label>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field type="text" :rules="$store.state.Settings.requiredRule" v-model="form.name"
                      hide-details dense outlined></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <label class="gray5--text font-weight-bold">{{ $t("Email") }}</label>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field type="email" :rules="$store.state.Settings.emailRule" v-model="form.email"
                      hide-details dense outlined></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <label class="gray5--text font-weight-bold">
                      {{
                        $t("Password")
                      }}
                    </label>
                  </v-col>
                  <v-col cols="8" v-if="changePasswordOn == false">
                    <v-btn text color="primary" class="text-decoration-underline" @click="changePasswordOn = true">{{
                      $t("Change password")
                    }}</v-btn>
                  </v-col>

                  <v-col cols="8" v-if="changePasswordOn == true">
                    <v-text-field hide-details="auto" class="my-0" outlined required color="primary"
                      :rules="passwordRule" v-model="form.old_password" :label="$i18n.t('current password')" dense
                      :append-icon="
                        showCrrPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      " :type="showCrrPass ? 'text' : 'password'" @click:append="showCrrPass = !showCrrPass">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="changePasswordOn == true"></v-col>
                  <v-col cols="8" v-if="changePasswordOn == true">
                    <v-text-field hide-details="auto" class="my-0" outlined required color="primary"
                      :rules="passwordRule" v-model="form.new_password" dense :label="$i18n.t('new password')"
                      :append-icon="
                        showNewPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      " :type="showNewPass ? 'text' : 'password'" @click:append="showNewPass = !showNewPass">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="changePasswordOn == true"></v-col>
                  <v-col cols="8" v-if="changePasswordOn == true">
                    <v-text-field hide-details="auto" class="my-0" outlined :label="$i18n.t('confirm password')"
                      required color="primary" :rules="confirmPasswordRule" v-model="form.confirm_password" dense
                      :append-icon="
                        showConfPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      " :type="showConfPass ? 'text' : 'password'" @click:append="showConfPass = !showConfPass">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-col cols="12" class="d-flex justify-end">
                <v-btn depressed color="error" outlined class="mx-2" @click="
  editIsOn = false;
changePasswordOn = false;
                " large>{{ $t("Cancel") }}</v-btn>
                <v-btn depressed color="success" class="mx-2" large :loading="isSaveLoading" :disabled="!(valid)"
                  @click="save">
                  {{
                    $t("Save")
                  }}
                </v-btn>
              </v-col>
            </div>
          </v-card>
          <!-- Subscriptions -->
          <v-card class="mt-2 shadow pa-5" v-if="selectedItem == 2">


            <v-row v-if="!isLoading">

              <v-col cols="12" class="mb-5" v-for="(package_data, i) in packages" :key="i">
                <h1 class="primary--text  font-weight-bold mt-0 ma-5">
                  {{ package_data.subscription_type_title || '-' | capitalize }} :
                </h1>
                <v-card class="pricingCard pa-5">
                  <v-col cols="12" class="pa-0">
                    <h3 class="darkBlue--text text-center font-weight-black">
                      {{ package_data.subscription_title || '-' | capitalize }}
                    </h3>

                    <h2 class="primary--text text-center font-weight-thin mt-3">
                      <span class="font-weight-black">{{ package_data.price }}</span>
                      <small>{{ $t("le") }}</small>
                      <small>/{{ package_data.duration || 0 }}{{ $t("month") }}</small>
                    </h2>
                    <v-tooltip open-delay="750" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mt-2 pricingDetails  body-1 darkBlue--text text-center">
                          {{ package_data.subscription_description || '-' | capitalize }}
                        </div>
                      </template>
                      <span> {{ package_data.subscription_description || '-' | capitalize }}</span>
                    </v-tooltip>
                    <div class="mt-2 pricingDetails  body-1 darkBlue--text ">
                      <span class="font-weight-bold">
                        <span class="gray--text">{{ $t('end date') | capitalize }} </span>
                        : {{ package_data.end_date || '-' | capitalize }}</span>
                    </div>
                    <div class="mt-2 pricingDetails  body-1 darkBlue--text " v-if="package_data.number_of_emails > 0">
                      <span class="font-weight-bold">
                        <span class="gray--text">{{ $t('number of emails') | capitalize }} </span>
                        : {{ package_data.number_of_emails || 0 }}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pa-0" v-if="package_data.number_of_emails > 0">
                    <v-btn block class="mt-3 primary--text px-8 shadow rounded-lg" @click="selectPackage(package_data)"
                      color="#E6ECFF" large>
                      {{ $t("manage emails") }}
                    </v-btn>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="12" class="my-15" v-if="isLoading">
              <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary"
                indeterminate>
              </v-progress-circular>
            </v-col>

          </v-card>
        </v-col>
      </v-row>



      <!-- Dialog -->

      <v-dialog v-model="emailsDialog" overlay-opacity="0.75" persistent :max-width="500">
        <v-card relative class="pa-7 py-10">
          <v-form v-model="validEmails" lazy-validation>

            <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
              color="grey lighten-1" text @click="emailsDialog = false">
              <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-row align="center" class="mt-2" justify="center">

              <v-col cols="12">
                <div class="gray5--text font-weight-bold text-h6">
                  {{ $t('manage emails') || capitalize }}
                </div>
              </v-col>
              <v-col cols="12" class="mb-10">


                <v-combobox v-model="emails" :no-data-text="$i18n.t('no emails')" :items="emails"
                  :search-input.sync="email_search" hide-selected :label="$i18n.t('add emails')" multiple
                  persistent-hint chips>
                  <template v-slot:no-data>
                    <v-list-item v-if="email_search">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("no results matching") | capitalize }} "<strong>{{ email_search }}</strong>".
                          {{ $t("press") | capitalize}}
                          <kbd>enter</kbd>
                          {{ $t("to add an email") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="data">
                    <v-chip :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected"
                      :disabled="data.disabled" close @click:close="data.parent.selectItem(data.item)">
                      <v-avatar class="accent white--text" left>{{ data.item.slice(0, 1).toUpperCase() }}</v-avatar>
                      <span class="mx-2">{{ data.item }}</span>
                    </v-chip>
                  </template>
                </v-combobox>

                <v-alert type="info" outlined v-if="!validNoOfEmails" class="mt-3">
                  {{ $t('max number of emails is') }} <span class="font-weight-bold">
                    {{ selectedPackage.number_of_emails || 0 }}
                  </span>
                </v-alert>

              </v-col>
              <v-col cols="auto" class="d-flex justify-center">
                <v-btn class="mx-2" text depressed color="red4" @click="closeDialog" :height="37" :width="120">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn class="mx-2 white--text" :disabled="!(validNoOfEmails && validEmails)" :loading="isSaveLoading"
                  @click="saveEmails" depressed color="primary" :height="37" :width="120">
                  {{ $t('ok') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>

    </v-container>
  </section>
</template>

<script>

export default {
  name: "AccountSettingsPage",
  components: {},
  data: () => ({
    isSaveLoading: false,
    selectedItem: 1,
    selectedPackage: {
      number_of_emails: 0,
      emails: [],
    },
    editIsOn: false,
    valid: false,
    changePasswordOn: false,
    isLoading: false,
    emailsDialog: false,
    email_search: null,
    passwordRule: [],
    confirmPasswordRule: [],
    packages: [],
    subscriptions_types: [],
    form: {
      name: null,
      email: null,
      mobile: null,
      old_password: null,
      new_password: null,
      confirm_password: null,
    },
    showCrrPass: false,
    emails: [],
    showNewPass: false,
    showConfPass: false,
    show: false,
    validEmails: false,
  }),
  watch: {
    '$store.state.userData'() {
      this.form = {
        name: this.$store.state.userData.full_name,
        full_name_en: this.$store.state.userData.full_name,
        full_name_ar: this.$store.state.userData.full_name,
        username: this.$store.state.userData.username,
        image: this.$store.state.userData.image,
        email: this.$store.state.userData.email,
        mobile: this.$store.state.userData.mobile,
        old_password: null,
        new_password: null,
        confirm_password: null,
      }
    },
    selectedPackage() {
      this.emails = this.selectedPackage.emails;
    },
    model(val) {
      if (val.length > this.selectedPackage.number_of_emails) {
        this.$nextTick(() => this.model.pop())
      }
    },
  },
  computed: {
    validNoOfEmails() {
      if (this.emails.length > this.selectedPackage.number_of_emails) {
        return false
      }
      else {
        return true
      }
    }
  },
  mounted() {
    if (this.$store.state.isAuth == false) {
      this.$router.push('/login')
    } else {
      this.getData();
      this.form = {
        name: this.$store.state.userData.full_name || '-',
        full_name_en: this.$store.state.userData.full_name || '-',
        full_name_ar: this.$store.state.userData.full_name || '-',
        username: this.$store.state.userData.username || '-',
        image: this.$store.state.userData.image || '',
        email: this.$store.state.userData.email || '-',
        mobile: this.$store.state.userData.mobile || '-',
        old_password: null,
        new_password: null,
        confirm_password: null,
      }
    }

    this.passwordRule = [
      (v) => !!v || this.$t("Password is required"),
      (v) =>
        (v && v.length >= 5) || this.$t("Password must be greater than 5 characters"),
    ];
    this.confirmPasswordRule = [
      (v) => !!v || this.$t("Password is required"),
      (v) => (v && v == this.form.new_password) || this.$t("Password not match"),
    ];
  },
  methods: {
    getData() {
      this.isLoading = true;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$api.GET_METHOD(`get_user_subscriptions`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          console.log(response);
          this.subscriptions_types = response.data.subscriptions_types;
          response.data.subscriptions.forEach(packageObj => {
            packageObj.emails = packageObj.emails ? packageObj.emails.split(',').map(i => i) : [];
          });
          this.packages = response.data.subscriptions;
        }
      })
    },
    save() {
      this.isSaveLoading = true;
      this.form.full_name_en = this.form.name;
      this.form.full_name_ar = this.form.name;
      this.$api.POST_METHOD(`update_profile`, this.form).then((response) => {
        this.isSaveLoading = false;
        if (response.check) {

          this.$store.dispatch("getUserData").then(
            // this.$global.ShowAlert(
            //   "success",
            //   this.$i18n.t("Account Settings updated"),
            //   false,
            //   "",
            //   3000
            // )
          );
        }
      })
    },
    selectPackage(selectedPackage) {
      this.selectedPackage = selectedPackage;
      this.emailsDialog = true
    },
    saveEmails() {
      this.isSaveLoading = true;
      const requestBody = {
        subscription_id: this.selectedPackage.subscription_id,
        emails: this.emails
      }
      this.$api.POST_METHOD(`add_emails_to_subscription`, requestBody).then((response) => {
        this.isSaveLoading = false;
        if (response.check) {
          this.$global.ShowAlert(
            "success",
            this.$i18n.t("Account Settings updated"),
            false,
            "",
            3000
          );
          this.emailsDialog = false;
          this.selectedPackage = {
            number_of_emails: 0,
            emails: [],
          },
            this.getData();
        }
      })
    },
    closeDialog() {
      this.emails = [];
      this.emailsDialog = false;
    },

  },
};
</script>
<style lang="scss" scoped>
.pricingCard {
  border-radius: 0;
  transition: 0.3s;
  box-shadow: 0px 0.6px 15px rgba(0, 0, 0, 0.06) !important;
  overflow: hidden;
  border-radius: 15px;
  display: grid;
  align-content: space-between;
  justify-content: center;
  height: auto;
  width: 400px !important;
  margin: auto;

  .pricingDetails {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 290px;
  }

  &:hover {
    box-shadow: 0px 4px 12px rgba(204, 95, 255, 0.25) !important;
    background-color: #FDFDFD;

  }
}
</style>
