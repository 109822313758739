<template>

    <router-view></router-view>

</template>


<script>
export default {
  name: "PartnersModule",
  components: {},
  data: () => ({}),
};
</script>
