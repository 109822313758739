<template>
  <v-card elevation="0" class="rounded-lg pa-4 mt-4">
    <div class="d-flex align-center justify-space-between">
      <div class="subtitle-1 font-weight-bold">{{ $t('material comparison') | capitalize }}</div>
    </div>
    <div class="d-flex align-center justify-space-between">
      <div class="subtitle-1 tableGray--text font-weight-medium">{{ comparisonItems.from_date + ' - ' +
          comparisonItems.to_date
      }}</div>
    </div>


    <div class="col-12 px-0 py-1 tableCol">
      <table class="materials-prices-table relative overflow-hidden" v-if="data.length > 0">
        <thead>
          <!-- header -->
          <tr>
            <th rowspan="1" class="lightBlue text-center font-weight-bold"> </th>
            <th colspan="2" class="light" scope="colgroup" v-if="data[0]['item1']">{{ data[0]['item1']['name'] }} <small
                class="tableGray--text"> ({{ comparisonItems.items[0].gov_name }}) </small>
              <v-btn color="error" small text style="z-index: 555555;" class="position-relative" @click="removeProduct(comparisonItems.items[0], 0)">{{$t('delete')}}</v-btn>
              </th>
            <th colspan="2" class="lightBlue" scope="colgroup" v-if="data[0]['item2']">{{ data[0]['item2']['name'] }}
              <small class="tableGray--text"> ({{ comparisonItems.items[1].gov_name }}) </small>
              <v-btn color="error" small text style="z-index: 555555;" class="position-relative" @click="removeProduct(comparisonItems.items[1], 1)">{{$t('delete')}}</v-btn>
            </th>
            <th colspan="2" class="light" scope="colgroup" v-if="data[0]['item3']">{{ data[0]['item3']['name'] }} <small
                class="tableGray--text"> ({{ comparisonItems.items[2].gov_name }}) </small>
                <v-btn color="error" small text style="z-index: 555555;" class="position-relative" @click="removeProduct(comparisonItems.items[2], 2)">{{$t('delete')}}</v-btn>
              </th>
            <th colspan="2" class="lightBlue" scope="colgroup" v-if="data[0]['item4']">{{ data[0]['item4']['name'] }}
              <small class="tableGray--text"> ({{ comparisonItems.items[3].gov_name }}) </small>
              <v-btn color="error" small text style="z-index: 555555;" class="position-relative" @click="removeProduct(comparisonItems.items[3], 3)">{{$t('delete')}}</v-btn>
            </th>
          </tr>
          <tr class="text-center">
            <th class="lightBlue">{{ $t("date") | capitalize }}</th>

            <th class="light font-weight-bold" v-if="data[0]['item1']">{{ $t("Lowest Price") | capitalize }}</th>
            <th class="light font-weight-bold" v-if="data[0]['item1']">{{ $t("Highest Price") | capitalize }}</th>
            <!-- <th class="light font-weight-bold" v-if="data[0]['item1']">{{ $t("Change") | capitalize }}</th> -->

            <th class="lightBlue font-weight-bold" v-if="data[0]['item2']">{{ $t("Lowest Price") | capitalize }}</th>
            <th class="lightBlue font-weight-bold" v-if="data[0]['item2']">{{ $t("Highest Price") | capitalize }}</th>
            <!-- <th class="lightBlue font-weight-bold"  v-if="data[0]['item2']">{{ $t("Change") | capitalize }}</th> -->

            <th class="light font-weight-bold" v-if="data[0]['item3']">{{ $t("Lowest Price") | capitalize }}</th>
            <th class="light font-weight-bold" v-if="data[0]['item3']">{{ $t("Highest Price") | capitalize }}</th>
            <!-- <th class="light font-weight-bold"  v-if="data[0]['item3']">{{ $t("Change") | capitalize }}</th> -->

            <th class="lightBlue font-weight-bold" v-if="data[0]['item4']">{{ $t("Lowest Price") | capitalize }}</th>
            <th class="lightBlue font-weight-bold" v-if="data[0]['item4']">{{ $t("Highest Price") | capitalize }}</th>
            <!-- <th class="lightBlue font-weight-bold"  v-if="data[0]['item4']">{{ $t("Change") | capitalize }}</th> -->
          </tr>
        </thead>
        <tbody>
          <!-- row -->
          <tr class="text-center" v-for="(row, index) in data" :key="index"
            style="border-bottom: 1px solid #d5e4f4;">
            <td class="lightBlue">{{ row['date'] || '-' }}</td>


            <td class="light" v-if="row['item1']">{{ row['item1']['min'] || $t('not available')  }}</td>
            <td class="light" v-if="row['item1']">{{ row['item1']['max'] || $t('not available')  }}</td>
            <!-- <td class="light" v-if="row['item1']">{{ row['item1']['mid'] || $t('not available')  }}</td> -->


            <td class="lightBlue" v-if="row['item2']">{{ row['item2']['min'] || $t('not available')  }}</td>
            <td class="lightBlue" v-if="row['item2']">{{ row['item2']['max'] || $t('not available')  }}</td>
            <!-- <td class="lightBlue" v-if="row['item2']">{{ row['item2']['mid'] || $t('not available')  }}</td> -->

            <td class="light" v-if="row['item3']">{{ row['item3']['min'] || $t('not available')  }}</td>
            <td class="light" v-if="row['item3']">{{ row['item3']['max'] || $t('not available')  }}</td>
            <!-- <td class="light" v-if="row['item3']">{{ row['item3']['mid'] || $t('not available')  }}</td> -->

            <td class="lightBlue" v-if="row['item4']">{{ row['item4']['min'] || $t('not available')  }}</td>
            <td class="lightBlue" v-if="row['item4']">{{ row['item4']['max'] || $t('not available')  }}</td>
            <!-- <td class="lightBlue" v-if="row['item4']">{{ row['item4']['mid'] || $t('not available')  }}</td> -->

          </tr>
        </tbody>
        <div class="water-mark"></div>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "MaterialComparisonTable",
  props: {
    removeProduct: { type: Function },
    isLoading: { default: false },
    comparison: { default: false },
    data: { default: [] },
    title: { default: "" },
    objectKey: { default: "prices" },
    headerTitle: { default: "Materials prices" },
    index: { default: 0 },
    hideViewMore: { default: false },
    showGovernorate: { default: false },
    showType: { default: false },
    comparisonItems: {
      default: {
        from_date: null,
        to_date: null,
        items: []
      }
    },
  },
  components: {},
  data: () => ({

  }),
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tableCol {
  @media screen and (max-width: 720px) {
    overflow-x: auto;
  }
}

.materials-prices-table {
  border-collapse: collapse;
  margin-top: 1rem;
  width: 100%;

  @media screen and (max-width: 720px) {
    width: 1000px;
  }

  border: 1px solid #d5e4f4;

  thead {
    th {
      border: 0;
      line-height: 3rem;
    }
  }

  tbody {
    tr {
      border: 0;
      border-color: transparent;

      &:nth-of-type(even) {
        background: #fff;
      }

      position: relative;

      &:hover {
        td {
          background-color: #D3E4F4 !important;

        }
      }

      &:nth-of-type(odd) {
        background: rgba(0, 0, 0, 0.05);
      }

      td {
        border: 0;
        line-height: 3rem;


      }
    }
  }
}

.water-mark {
  position: absolute;
  width: 100%;
  height: 320%;
  top: 0;
  left: 0;
  z-index: 2;
  background-image: url("../assets/logo.png");
  background-repeat: repeat;
  transform: rotate(45deg) scale(1.5, 1.5);
  opacity: 0.07;
}
</style>
