<template>
  <v-expansion-panels :readonly="isDialog" class="mt-4 rounded-lg overflow-hidden" v-model="active" v-if="($store.state.savedSearches.length > 0)" :class="{ 'isDialog': isDialog }">
    <v-expansion-panel>
      <v-expansion-panel-header @click.self="closeDialog">
        <div class="text-h6 font-weight-bold primary--text">{{ $t('saved results') | capitalize }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-for="(search, i) in $store.state.savedSearches" :key="i">

        <!-- {{search}} -->
        <v-row align="center">
          <v-col cols="6" v-if="search.product_id">
            {{ $t('product') | capitalize }} :
            <span class="text--disabled font-weight-bold">
              {{ filterArray(products, 'id', +search.product_id, 'name') || $t('not selected') | capitalize
              }}</span>
          </v-col>
          <v-col cols="6" v-if="search.product_type_id">
            {{ $t('product type') | capitalize }} :
            <span class="text--disabled font-weight-bold">
              {{ filterArray(products_types, 'id', +search.product_type_id, 'name') || $t('not selected') |
                  capitalize
              }}</span>
          </v-col>
          <v-col cols="6" v-if="search.producer_id">
            {{ $t('Producer') | capitalize }} :
            <span class="text--disabled font-weight-bold">
              {{ filterArray(all_producers, 'id', +search.producer_id, 'name') || $t('not selected') | capitalize
              }}</span>
          </v-col>
          <v-col cols="6" v-if="search.item_id">
            {{ $t('item') | capitalize }} :
            <span class="text--disabled font-weight-bold">
              {{ filterArray(items, 'id', +search.item_id, 'name') || $t('not selected') | capitalize
              }}</span>
          </v-col>
          <v-col cols="6" v-if="search.gov_id">
            {{ $t('governorate') | capitalize }} :
            <span class="text--disabled font-weight-bold">
              {{ filterArray(governorates, 'id', +search.gov_id, 'name') || $t('not selected') | capitalize
              }}</span>
          </v-col>
          <v-col cols="6" v-if="search.from_date">
            {{ $t('from') | capitalize }} :
            <span class="text--disabled font-weight-bold">
              {{ search.from_date || $t('-') | capitalize }}</span>
          </v-col>
          <v-col cols="6" v-if="search.to_date">
            {{ $t('to') | capitalize }} :
            <span class="text--disabled font-weight-bold">
              {{ search.to_date || $t('-') | capitalize }}</span>
          </v-col>
          <v-col cols="12" class="d-flex justify-end align-center">
            <v-btn color="error" text class="mx-2" depressed width="120" :loading="isLoading"
              @click="removeSearch(search)">
              {{ $t('delete') }}
            </v-btn>
            <v-btn color="primary" outlined width="120" :loading="isLoading" @click="getData(search.param)">
              {{ $t('view') }}
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="($store.state.savedSearches.length !== i + 1)">
            <v-divider></v-divider>
          </v-col>
        </v-row>


      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  
</template>




<style lang="scss" scoped>
.isDialog {
   position: absolute !important;
   bottom: 0;
   right: 0;
   left: 0;
   overflow: auto !important;
   max-height: 90vh;
}
</style>



<script>
export default {
  name: "SavedFilterResults",
  props: {
    backValueMethod: { type: Function },
    getData: { type: Function },
    isLoading: { default: false },
    closeDialog: { type: Function },
    isDialog: { default: false },
    all_producers: { default: [] },
    products: { default: [] },
    products_types: { default: [] },
    items: { default: [] },
    governorates: { default: [] },
  },
  computed: {
    filterArray() {
      return (ARRAY, SearchIn, filterBy, ReturnElement) => {
        return this.$global.FilterArrayOfObjectElement(ARRAY, SearchIn, filterBy, ReturnElement);
      }
    },
    active(){
      return 0
    }
  },
  data: () => ({

  }),
  methods: {
    removeSearch(searchParam) {
      if(this.$store.state.savedSearches.length ==1){
        this.closeDialog()
      }
      this.$store.dispatch("removeSearch", searchParam).then();
    },
  }
};
</script>
