<template>
   <v-container fluid style="min-height: 100vh;">
      <Breadcrumb :data="breadcrumb" />
      <v-row class="mt-3" v-if="!isLoading">
         <!-- Producers List -->

         <v-col cols="12" xl="3" lg="4" sm="6" v-for="(producer, index) in producers" :key="index">
            <div class="producer cursor_pointer white shadow"
               @click="openProducerLink( producer.id , producer.name , producer.website )">
               <v-img :src="$store.state.endpointURL + producer.photo" lazy-src="@/assets/icon.png"
                  :alt="producer.name" aspect-ratio="auto" height="100" contain />
               <div class="subtitle-1 font-weight-black text-center">{{ $t(producer.name) }}</div>
            </div>
         </v-col>

         <!-- --------------------------------------------------------------------------------------------- -->
      </v-row>

      <v-row justify="center" v-if="isLoading">
         <v-col cols="auto" class="my-15">
            <v-progress-circular :size="70" :width="5" style="margin: auto" class="d-block" color="primary" indeterminate>
            </v-progress-circular>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
   name: 'ProducersList',
   components: {
      Breadcrumb,
   },
   mounted() {
      this.$global.DELETE_META();
      window.scrollTo({
         top: 0,
         behavior: "smooth",
      });
      this.getData();
   },
   watch: {
      $route() {
         this.$global.DELETE_META();
         this.page = this.$route.params.page;
         this.$global.ADD_META(this.$route.params.meta_name, 'title');
         this.getData();
      }
   },
   data: () => ({
      breadcrumb: [],
      producers: [],
      isLoading: false

   }),
   computed: {

   },
   methods: {
      openProducerLink(id , name , website){
         if(website.length > 0 ){
            window.open("https://"+website, "_blank");
            // window.open(website, "_blank" , 'noreferrer');
         }else{
            this.$router.push('/producer/' + this.$route.params.id + '/'+ this.$route.params.meta_name + '/' + id + '/' + name.split(/\s+/).join('-'))
         }
      },
      getData() {
         this.isLoading = true
         this.breadcrumb = [
            {
               text: this.$i18n.t('cement egypt'),
               disabled: false,
               to: '/',
            },
            {
               text: this.$route.params.meta_name,
               disabled: false,
               to: `/producers/${this.$route.params.id}/${this.$route.params.name}`,
            },
         ];
         this.$api.GET_METHOD(`producer?id=${this.$route.params.id}`).then((response) => {
            this.isLoading = false
            if (response.check) {
               console.log(response);
               this.producers = response.data.items || [];
               this.producers.forEach(item => {
                  this.$global.ADD_META(item.name_ar, 'title');
                  this.$global.ADD_META(item.description_en, 'description_body');
                  this.$global.ADD_META(item.description_ar);
                  this.$global.ADD_META(this.$store.state.endpointURL + item.photo, 'image');
               });
               // response.data.producer_ads.forEach(ad => {
               //  this.$global.ADD_META(this.$store.state.endpointURL + ad.photo, 'image');
               //    this.$global.ADD_META(ad.title, 'keywords');
               //    this.$global.ADD_META(ad.link, 'url');
               // });
               // this.firstAd = this.$global.FilterArrayOfObject(response.data.home_ads, 'ads_code', '01-007')[0];
            } else {
               this.producers = [];
            }
         })
      },
      getImgUrl(img) {
         var images = require.context("../../assets/png");
         console.log(images("./" + img));
         return images("./" + img);
      },
   }
}
</script>

<style lang="scss" scoped>
.producer {
   border: 0.704431px solid #c4c4c4;
   border-radius: 12.6798px;
   // width: 92.98px;
   padding: 1rem;
   text-transform: capitalize;
   text-align: center;

   img {
      height: 61.99px;
      // transform: scale(1.4, 1.4);
   }
}
</style>