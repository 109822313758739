<template>
  <v-container fluid>
    <v-row align="stretch">
      <v-col cols="12" lg="6" md="5" sm="4" order="2" order-sm="1" class="pa-0 d-none d-md-block">
        <v-img src="@/assets/svg/cementBg.svg" lazy-src="@/assets/svg/cementBg.svg" height="102vh"
          class="d-flex align-center justify-center">
          <img src="@/assets/svg/white_logo.svg" class="d-block ma-auto" alt="cement egypt" style="max-height: 80px" />
        </v-img>
      </v-col>
      <v-col cols="12" lg="6" md="7" sm="12" order="1" order-sm="2" class="white d-flex align-center"
        style="min-height: 100vh">
        <v-card elevation="0" class="pa-5 white" style="width: 100%">
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- <img src="@/assets/logo.png" class="d-block d-sm-none ma-auto" alt="cement egypt"
              style="max-height: 60px" /> -->
            <h1 class="font-weight-black text-center text-md-start my-8">
              {{ $t("Register") }}
            </h1>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field persistent-placeholder placeholder=" " :label="$i18n.t('full name')" type="text"
                  :rules="$store.state.Settings.requiredRule" name="fullName" v-model="form.name" outlined>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field persistent-placeholder placeholder=" " :label="$i18n.t('username')" type="text"
                  :rules="$store.state.Settings.usernameRule" name="username" v-model="form.username" outlined>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field persistent-placeholder placeholder=" " :label="$i18n.t('mobile')" type="tel"
                  :rules="$store.state.Settings.requiredRule" name="mobile" v-model="form.mobile" outlined>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field persistent-placeholder name="email" placeholder=" " :label="$i18n.t('email')" type="email"
                  :rules="$store.state.Settings.emailRule" outlined v-model="form.email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field persistent-placeholder placeholder=" " name="password" :label="$i18n.t('password')"
                  outlined required :rules="$store.state.Settings.passwordRule" v-model="form.password"
                  :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
                  @click:append="show = !show"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <vue-recaptcha :sitekey="sitekey" ref="recaptcha" :loadRecaptchaScript="true" @verify="verifyMethod"
                  @expired="expiredMethod" @render="renderMethod" @error="errorMethod">
                </vue-recaptcha>
              </v-col>

              <v-col cols="12" sm="8" class="py-0">
                <v-btn outlined block color="primary" class="d-block ma-auto" x-large @click="register"
                  :loading="loading" :disabled="!(valid && recaptchaValid)">
                  {{ $t("Create") }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="ma-0 py-0">
                <v-btn text :disabled="loading" class="d-block ma-auto text-decoration-underline"
                  @click="$router.push('/login')" color="primary">
                  {{ $t("I Have an account") }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="relative">
                <v-divider class="ma-5"></v-divider>
                <p class="or white">
                  {{ $t("or") }}
                </p>
              </v-col>

              <v-snackbar multi-line v-model="snackbar" :timeout="2000">
                <div class="body-1" v-for="(error, index) in errors" :key="index">{{ error }}</div>
                <template v-slot:action="{ attrs }">
                  <v-btn color="error" text v-bind="attrs" @click="snackbar = false">
                    {{ $t('hide') }}
                  </v-btn>
                </template>
              </v-snackbar>

              <v-col cols="12">
                <v-btn text class="google rounded-pill d-block ma-auto" x-large @click="snackbar = !snackbar">
                  <img src="@/assets/svg/Google.svg" class="mx-2" alt="cement egypt" />
                  {{ $t("Register by Google") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {

  name: "RegisterPage",
  components: { VueRecaptcha },
  data: () => ({
    show: false,
    valid: false,
    recaptchaValid: false,
    loading: false,
    snackbar: false,
    errors: [],
    recaptchaResponse: null,
    sitekey: '6LejEqEhAAAAAM0ZYGA15ujy-9vUDU4UtcPnZeMn',
    form: {
      name: null,
      username: null,
      email: null,
      mobile: null,
      password: null,
      title_id: 2,
      recaptcha_token: null,
    },
  }),
  mounted() {
    this.$refs.form.validate();
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  methods: {
    register() {
      this.errors = [];
      this.snackbar = false;
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true) {

        this.$api.POST_METHOD('register', this.form, false).then((response) => {
          this.loading = false;
          if (response.check == true) {
            // this.$api.SET_AUTH(response.data)
            this.errors.push(response.msg || 'info')
            this.snackbar = true
            // this.$router.push("/activate-account");
            this.$router.push({
              name: "ActivateAccount",
              params: { email: this.form.email },
            });

            // this.$router.push("/subscription-packages");
          } else {
            if (response.data) {
              Object.keys(response.data).forEach(errorKey => {
                this.errors.push(response.data[errorKey][0])
              });
              this.snackbar = true;
            } else {
              this.errors.push(response.msg || 'error')
              this.snackbar = true
            }
          }
        })
      }
      else {
        this.loading = false;
      }

    },
    errorMethod() { this.recaptchaValid = false },
    renderMethod() { this.recaptchaValid = false },
    expiredMethod() { this.recaptchaValid = false },
    verifyMethod(res) { this.recaptchaValid = true, this.form.recaptcha_token = res },
  },
};
</script>

<style lang="scss" scoped>
.or {
  position: absolute;
  left: 50%;
  right: 50%;
  top: 1.2rem;
  width: 40px;
  text-align: center;
  font-weight: bold;
}

.google {
  border: 1px solid #d2d1d4;
  text-transform: initial;

  img {
    height: 30px;
  }
}
</style>
