import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import i18n from "../plugins/i18n";
// import router from "../router";
import axios from "axios";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    // APP Settings
    app_version: process.env.VUE_APP_VERSION, //last app update Year.Month.Day.UploadNumber
    splashScreen: false,
    offline: false,
    sidebarIsOpen: false,
    topAppBar: false,
    endpointURL: "https://api.cementegypt.com", // Live
    // endpointURL: "https://cement-api.arabdivs.com/", // Testing
    apiAccess:
      "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    //
    email: null,
    header_ads: [],
    footer_ads: [],
    contact_info: [],
    types: [],
    profile_types: [],
    product_types: [],
    producer_types:[],
    website_basic_data: {
      about_ar: null,
      about_en: null,
      disclaimer_ar: null,
      disclaimer_en: null,
      mission_ar: null,
      mission_en: null,
      vision_ar: null,
      vision_en: null,
      goal_ar: null,
      goal_en: null,
      phone: null,
      email: null,
      whatsapp: null,
      facebook_link: null,
      linkedin_link: null,
      twitter_link: null,
      youtube_link: null,
    },
    isAuth:
      localStorage.getItem("access-token") &&
      JSON.parse(localStorage.getItem("permissions")).length !== 0
        ? true
        : false,
    userData: {},
    darkTheme: localStorage.getItem("darkTheme") == "true" ? true : false,
    country: localStorage.getItem("country")
      ? localStorage.getItem("country")
      : "egypt",

    savedSearches: localStorage.getItem("savedSearches")
      ? JSON.parse(localStorage.getItem("savedSearches"))
      : [],
  },
  getters: {
    getUser(state) {
      return state.userData;
    },
  },
  mutations: {
    SET_DEFAULTS_DATA(state, data) {
      // ADS
      state.header_ads = data.header_ads;
      state.footer_ads = data.footer_ads;
      state.contact_info = data.contact_info;
      state.types = [...data.product_types];
      // Profiles
      data.profile_types.forEach((type) => {
        type.module = "partners";
        type.link = `partners/${type.id}/${type.name.split(/\s+/).join("-")}`;
      });
      // data.profile_types.unshift({
      //   module: "partners",
      //   name: "Industry Partners",
      //   link: `partners/${i18n
      //     .t("Industry Partners")
      //     .split(/\s+/)
      //     .join("-")}/${0}`,
      // });
      state.profile_types = data.profile_types || [];
      data.producer_types = data.producer_types || [];
      data.product_types  = data.product_types || [];

      // producer Types
      data.producer_types.forEach((type) => {
        type.module = "producers";
        let meta_name = i18n.t("producers")+" "+type.name;
        type.link = `producers/${type.id}/${meta_name.split(/\s+/).join("-")}`;
      });
      state.producer_types = data.producer_types;

      // Product Types
      data.product_types.forEach((type) => {
        type.module = "materials";
        type.link = `materials/${type.name.split(/\s+/).join("-")}/${type.id}`;
      });
      // data.product_types.unshift({
      //   module: "materials",
      //   name: "all material prices",
      //   link: `materials/${i18n
      //     .t("all material prices")
      //     .split(/\s+/)
      //     .join("-")}/ `,
      // });
      data.product_types.push({
        module: "materials",
        name: "prices comparison",
        link: `comparison/${i18n
          .t("prices comparison")
          .split(/\s+/)
          .join("-")}`,
      });
      state.product_types = data.product_types;
      // Social Links & About Data
      state.website_basic_data = data.website_basic_data[0] || {
        about_ar: null,
        about_en: null,
        disclaimer_ar: null,
        disclaimer_en: null,
        mission_ar: null,
        mission_en: null,
        vision_ar: null,
        vision_en: null,
        goal_ar: null,
        goal_en: null,
        phone: null,
        email: null,
        whatsapp: null,
        facebook_link: null,
        linkedin_link: null,
        twitter_link: null,
        youtube_link: null,
      };
      //
    },
    UPDATE_SAVED_SEARCHES(state, savedSearches) {
      state.savedSearches = savedSearches;
      setTimeout(() => {
        localStorage.setItem(
          "savedSearches",
          JSON.stringify(state.savedSearches)
        );
      }, 10);
    },
  },
  actions: {
    checkAppVersion() {
      localStorage.setItem("app_version", process.env.VUE_APP_VERSION);
    },
    checkAuth({ state }) {
      state.isAuth = localStorage.getItem(
        Vue.prototype.$global.CRYPT("cement", "access-token")
      )
        ? true
        : false;
      if (!state.isAuth) {
        console.log("not auth");
        // router.push("/login").catch(() => {});
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT(
          "cloud",
          localStorage.getItem(
            Vue.prototype.$global.CRYPT("cloud", "access-token")
          )
        );
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + state.accessToken;
      }
    },
    getUserData({ state }) {
      state.splashScreen = true;
      Vue.prototype.$api.GET_METHOD(`get_my_profile`).then((response) => {
        state.splashScreen = false;
        if (response.check) {
          Vue.prototype.$api.SET_AUTH(response.data);
        }
      });
    },
    getDefaultData({ state, commit }) {
      state.splashScreen = true;
      Vue.prototype.$api.GET_METHOD(`default_website_data`).then((response) => {
        state.splashScreen = false;
        if (response.check) {
          return commit("SET_DEFAULTS_DATA", response.data);
        }
      });
    },
    addSearch({ commit, state }, new_search) {
      if (!state.savedSearches.some((search) => search.param === new_search.param)) {
        if(state.savedSearches.length >= 10){
          state.savedSearches.splice(0,10 - (state.savedSearches.length) + 1) //max 10  && if greater than 10 removes more
          return commit(
            "UPDATE_SAVED_SEARCHES",
            state.savedSearches.concat(new_search)
          );
        }
        return commit(
          "UPDATE_SAVED_SEARCHES",
          state.savedSearches.concat(new_search)
        );
      }else{
        const removeIndex = state.savedSearches.findIndex(
          (search) => search.param === new_search.param
        );
        state.savedSearches.splice(removeIndex, 1);
        setTimeout(() => {
          return commit("UPDATE_SAVED_SEARCHES", state.savedSearches.concat(new_search));
        }, 10);
      }
    },
    removeSearch({ commit, state }, new_search) {
      if (state.savedSearches.some((search) => search.param === new_search.param)) {
        const removeIndex = state.savedSearches.findIndex(
          (search) => search.param === new_search.param
        );
        state.savedSearches.splice(removeIndex, 1);
        return commit("UPDATE_SAVED_SEARCHES", state.savedSearches);
      }
    },
  },
  modules: { Settings },
});
