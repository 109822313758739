<template>
   <v-footer padless color="#1d1929">
      <v-container>
         <v-card flat tile class="lighten-1 white--text py-5" color="#1d1929">
            <v-row align="center" justify="space-between">
               <v-col cols="7" lg="7" class="d-none d-lg-block">
                  <v-row>
                     <v-col cols="4">
                        <div class="subtitle-1 font-weight-bold">{{ $t('Materials prices') | capitalize}}</div>
                       <router-link v-for="(route, index) in $store.state.product_types" :key="index" :to="`/`+route.link">
                           <div class="subtitle-2 white--text font-weight-medium">
                              {{$t(route.name) | capitalize}}
                           </div>
                        </router-link>
                     </v-col>
                     <v-col cols="4">
                        <div class="subtitle-1 font-weight-bold">{{ $t('News') | capitalize}}</div>
                        <router-link :to="`/news/1/1/${this.$i18n.t('general news').split(/\s+/).join('-')}`">
                           <div class="subtitle-2 white--text font-weight-medium">
                              {{ $t('general news') | capitalize}}
                           </div>
                        </router-link>
                        <router-link :to="`/news/2/1/${this.$i18n.t('cement news').split(/\s+/).join('-')}`">
                           <div class="subtitle-2 white--text font-weight-medium">
                              {{ $t('cement news') | capitalize}}
                           </div>
                        </router-link>
                        <router-link :to="`/news/3/1/${this.$i18n.t('cement news around world').split(/\s+/).join('-')}`">
                           <div class="subtitle-2 white--text font-weight-medium">
                              {{ $t('cement news around world') | capitalize}}
                           </div>
                        </router-link>
                     </v-col>
                     <!-- <v-col cols="4">
                        <div class="subtitle-1 font-weight-bold">{{ $t('Producers & Buy ') }}</div>
                        <div class="subtitle-2 font-weight-medium">{{ $t('list list') }}</div>
                        <div class="subtitle-2 font-weight-medium">{{ $t('list list') }}</div>
                        <div class="subtitle-2 font-weight-medium">{{ $t('list list') }}</div>
                        <div class="subtitle-2 font-weight-medium">{{ $t('list list') }}</div>
                     </v-col> -->
                     <v-col cols="4">
                        <div class="subtitle-1 font-weight-bold">{{ $t('Industry Partners') | capitalize}}</div>
                       <router-link v-for="(route, index) in $store.state.profile_types" :key="index" :to="`/`+route.link">
                           <div class="subtitle-2 white--text font-weight-medium">
                              {{$t(route.name) | capitalize}}
                           </div>
                        </router-link>
                     </v-col>
                     <v-col cols="4">
                        <div class="subtitle-1 font-weight-bold">{{ $t('Cement Egypt Society') | capitalize}}</div>
                        <router-link :to="`/society/jobs/1/${this.$i18n.t('Cement Egypt Jobs').split(/\s+/).join('-')}`">
                           <div class="subtitle-2 white--text font-weight-medium">
                              {{ $t('Cement Egypt Jobs') | capitalize}}
                           </div>
                        </router-link>
                        <router-link :to="`/society/deals/1/${this.$i18n.t('Cement Egypt Deals').split(/\s+/).join('-')}`">
                           <div class="subtitle-2 white--text font-weight-medium">
                              {{ $t('Cement Egypt Deals') | capitalize}}
                           </div>
                        </router-link>
                     </v-col>
                  </v-row>
               </v-col>
               <v-col cols="12" lg="5" sm="6">
                  <v-card class="pa-5" elevation="0">
                     <v-form ref="form" v-model="valid" class="row">
                        <v-row>
                           <v-col cols="12">
                              <v-text-field :label="$i18n.t('name') | capitalize" :rules="$store.state.Settings.requiredRule"
                                 v-model="form.name" hide-details dense outlined></v-text-field>
                           </v-col>
                           <v-col cols="12">
                              <v-text-field :label="$i18n.t('email') | capitalize" :rules="$store.state.Settings.emailRule"
                                 type="email" hide-details dense outlined v-model="form.email"></v-text-field>
                           </v-col>
                           <v-col cols="4">
                              <v-combobox :items="['+20', '+96', '+95']" :rules="$store.state.Settings.requiredRule"
                                 :label="$i18n.t('country code') | capitalize" hide-details dense outlined v-model="form.code">
                              </v-combobox>
                           </v-col>
                           <v-col cols="8">
                              <v-text-field :label="$i18n.t('phone') | capitalize" :rules="$store.state.Settings.mobileRule"
                                 type="number" hide-details dense outlined v-model="form.phoneWithoutCode">
                              </v-text-field>
                           </v-col>
                           <v-col cols="12">
                              <v-textarea :label="$i18n.t('message') | capitalize" :rules="$store.state.Settings.requiredRule"
                                 v-model="form.message" hide-details dense outlined></v-textarea>
                           </v-col>
                           <v-col cols="12" lg="4" md="6">
                              <v-btn depressed block color="primary" :disabled="!valid" :loading="isLoading" large
                                 @click="send">{{ $t('Send') }}</v-btn>
                           </v-col>
                        </v-row>
                     </v-form>
                  </v-card>
               </v-col>
               <v-col cols="12" lg="12" sm="6"
                  v-if="$store.state.website_basic_data.phone && $store.state.website_basic_data.whatsapp && $store.state.website_basic_data.email">
                  <v-row class="align-center justify-center">
                     <v-col cols="auto">
                        <div class="subtitle-1 font-weight-bold">{{ $t('CONTACT') }}</div>
                     </v-col>
                     <v-col cols="auto" v-if="$store.state.website_basic_data.phone">
                        <a class="subtitle-2 white--text font-weight-medium" target="_blank" :href="'tel:'+$store.state.website_basic_data.phone">
                           <v-icon class="mx-2" color="primary">mdi-phone</v-icon>
                           {{ $store.state.website_basic_data.phone }}
                        </a>
                     </v-col>
                     <v-col cols="auto" v-if="$store.state.website_basic_data.whatsapp">
                        <a class="subtitle-2 white--text font-weight-medium" target="_blank" :href="'https://api.whatsapp.com/send/?phone='+$store.state.website_basic_data.whatsapp">
                           <v-icon class="mx-2" color="primary">mdi-whatsapp</v-icon>
                           {{ $store.state.website_basic_data.whatsapp }}
                        </a>
                     </v-col>
                     <v-col cols="auto" v-if="$store.state.website_basic_data.email" >
                        <a class="subtitle-2 white--text font-weight-medium" :href="'mailto:'+$store.state.website_basic_data.email">
                           <v-icon class="mx-2" color="primary">mdi-email-outline</v-icon>
                           {{ $store.state.website_basic_data.email }}
                        </a>
                     </v-col>
                  </v-row>
               </v-col>
               <v-col cols="12">
                  <v-row class="align-center justify-center">
                     <v-col cols="12" lg="6">
                        <img src="../assets/logo.png" class="d-block ma-auto" alt="cement egypt" />
                        <div class="d-flex justify-center mt-2 align-center">
                           <a :href="$store.state.website_basic_data.facebook_link"
                              v-if="$store.state.website_basic_data.facebook_link">
                              <img src="../assets/svg/facebook-white.svg" class="mx-2" alt="cement egypt" />
                           </a>
                           <a :href="$store.state.website_basic_data.twitter_link"
                              v-if="$store.state.website_basic_data.twitter_link">
                              <img src="../assets/svg/twitter-white.svg" class="mx-2" alt="cement egypt" />
                           </a>
                           <a :href="$store.state.website_basic_data.youtube_link"
                              v-if="$store.state.website_basic_data.youtube_link">
                              <img src="../assets/svg/youtube-white.svg" class="mx-2" alt="cement egypt" />
                           </a>
                           <a :href="$store.state.website_basic_data.linkedin_link"
                              v-if="$store.state.website_basic_data.linkedin_link">
                              <img src="../assets/svg/linkedin-white.svg" class="mx-2" alt="cement egypt" />
                           </a>
                        </div>
                     </v-col>
                     <v-col cols="12" lg="6">
                        <div class="body-2 font-weight-medium">
                           {{$t("Cement Egypt 'is the first website' specialized in the cement industry and the various economic industries related to it and seeks to support and this industry and all parties dealing with it")}}</div>
                     </v-col>
                  </v-row>
               </v-col>
            </v-row>
            <v-divider class="my-5"></v-divider>
            <v-card-text class="white--text text-center">
               <strong>© {{ new Date().getFullYear() }} {{ $t('Cement Egypt | All Rights Reserved') | capitalize}}</strong>
            </v-card-text>
         </v-card>
      </v-container>
   </v-footer>
</template>

<script>

export default {
   name: 'PageFooter',
   components: {

   },
   data: () => ({
      icons: [
         'mdi-facebook',
         'mdi-twitter',
         'mdi-linkedin',
         'mdi-instagram',
      ],
      valid: false,
      isLoading: false,
      form: {
         name: null,
         email: null,
         code: null,
         phoneWithoutCode: null,
         phone: null,
         message: null,
      }
   }),
   watch: {
      'form.phoneWithoutCode'() {
         this.form.phone = String(this.form.code) + String(this.form.phoneWithoutCode)
      }
   },
   computed: {

   },
   methods: {
      send() {
         this.isLoading = true;
         delete this.form.phoneWithoutCode;
         this.$api.POST_METHOD(`contact_us`, this.form).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.$global.ShowAlert(
                  "success",
                  this.$i18n.t("thank you for contact us"),
                  false,
                  "",
                  3000
               );
               this.form = {
                  name: null,
                  email: null,
                  code: null,
                  phoneWithoutCode: null,
                  phone: null,
                  message: null,
               };
            }
         })
      },
   }
}
</script>

<style lang="scss" scoped>
</style>